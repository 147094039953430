/**
 * @file 评教评学 menus
 */

import { atom } from 'jotai'

import {
  hasEnabledPluginAtom,
  ModuleEnum,
} from 'packages/feature-utils/plugins'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

export const adminEvaluationMenu = atom(get => {
  if (!get(hasEnabledPluginAtom(ModuleEnum.Evaluation))) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.教师发展],
      name: SchoolModulesEnum.Evaluation,
      path: '/admin/evaluations',
      label: $t('评教评学管理'),
      icon: 'Report' as const,
      shortcutIcon: 'TeachingSatisfactionSolid+15B8B1',
      type: MenuType.AdminApps,
      sort: 700,
      permission: [PermissionNameEnum.CoreEvaluationWrite],
      subMenus: [
        {
          path: '/admin/evaluations',
          label: $t('全部评教评学'),
          icon: 'Report',
        },
      ],
    },
  ]
})
