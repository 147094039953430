import { AppLayout, Button, Space } from '@seiue/ui'
import React, { FC } from 'react'
import { useIsFetching } from 'react-query'
import { matchPath } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorBoundary } from 'packages/components/ErrorBoundary'
import { getSchoolIdFromShareURL } from 'packages/feature-utils/schools/utils'
import { $t } from 'packages/locale'
import { passport } from 'packages/passport'
import { routes, modalRoutes } from 'packages/route'
import { NestedRoute } from 'packages/route/components'

import { ReflectionAvatar } from '@/App/Layout/ReflectionAvatar'
import { SiderLogo } from '@/App/Layout/SiderLogo'
import { useLocation } from '@/router'

interface LayoutProps {
  sessionCreated: boolean
  navbarBackgroundColor: string
}

/**
 * 公开分享页的应用框架
 *
 * @param param0 - 参数
 * @param param0.sessionCreated - 是否已登录
 * @param param0.navbarBackgroundColor - 顶部导航背景色
 * @returns 公开分享页的应用框架
 */
export const Layout: FC<LayoutProps> = ({
  sessionCreated,
  navbarBackgroundColor,
}) => {
  const schoolId = getSchoolIdFromShareURL()
  const gotoLogin = () => {
    passport.gotoLoginPage({
      schoolId: schoolId || undefined,
      setRedirect: true,
    })
  }

  const location = useLocation()

  const matchResult = routes.find(route => matchPath(location.pathname, route))

  const shouldAnonymous = !!matchResult?.shareConfig?.anonymous

  return (
    <AppLayout
      showLoadingBar={!!useIsFetching()}
      logoSlot={
        <SiderLogo
          presentedSchoolId={schoolId}
          onClick={() => {
            if (sessionCreated) {
              // Go to homepage
              window.location.href = window.location.origin
            } else {
              gotoLogin()
            }
          }}
        />
      }
      headerRight={
        !shouldAnonymous ? (
          <Space size={28}>
            {sessionCreated ? (
              <ReflectionAvatar />
            ) : (
              <Button type="dark" onClick={gotoLogin}>
                {$t('登录')}
              </Button>
            )}
          </Space>
        ) : null
      }
      navbarBackgroundColor={navbarBackgroundColor}
    >
      <ContentContainer>
        <ErrorBoundary>
          <NestedRoute routes={routes} modalRoutes={modalRoutes} />
        </ErrorBoundary>
      </ContentContainer>
    </AppLayout>
  )
}

const ContentContainer = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
`
