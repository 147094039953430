import { lazy } from 'react'

import { ModuleEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import { getRouteSlots } from '@/plugins'

import { getAdminClassGradeRoutes } from './grade'

/**
 * 行政班路由配置 - 管理员
 *
 * @returns 路由配置
 */
export const getAdminRoutes = (): RouteConfig[] => [
  {
    path: 'admin/admin-classes',
    name: 'AdminAdminClasses',
    requirePermissions: [PermissionNameEnum.CoreAdminClassRead],
    requireModules: [ModuleEnum.AdminClass],
    module: ['admin-class'],
    getTitle: () => $t('行政班管理'),
    component: lazy(() =>
      import('../pages/Management/Classes/List').then(m => ({
        default: m.Index,
      })),
    ),
    subRoutes: [
      {
        name: 'Normal',
        path: 'normal',
        getTitle: () => $t('行政班列表'),
        component: lazy(() =>
          import('../pages/Management/Classes/List/Normal').then(m => ({
            default: m.NormalList,
          })),
        ),
      },
      {
        path: 'archived',
        getTitle: () => $t('已归档行政班'),
        component: lazy(() =>
          import('../pages/Management/Classes/List/Archived').then(m => ({
            default: m.ArchivedList,
          })),
        ),
      },
      {
        name: 'switch',
        path: 'switch',
        getTitle: () => $t('学生换班'),
        component: lazy(() =>
          import('../pages/Management/Classes/List/Switch').then(m => ({
            default: m.SwitchList,
          })),
        ),
      },
      {
        path: 'settings',
        getTitle: () => $t('设置'),
        component: lazy(() =>
          import('../pages/Management/Classes/List/Settings').then(m => ({
            default: m.Settings,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/admin-classes/new',
    getTitle: () => $t('新增行政班'),
    requirePermissions: [PermissionNameEnum.CoreAdminClassWrite],
    requireModules: [ModuleEnum.AdminClass],
    component: lazy(() =>
      import('../pages/Management/Classes/New').then(m => ({
        default: m.AdminClassNew,
      })),
    ),
  },
  {
    path: 'admin/admin-classes/fields',
    getTitle: () => $t('字段管理'),
    requirePermissions: [PermissionNameEnum.CoreAdminClassWrite],
    requireModules: [ModuleEnum.AdminClass],
    component: lazy(() =>
      import('../pages/Management/FieldManagement').then(m => ({
        default: m.FieldManagement,
      })),
    ),
  },
  {
    path: 'admin/admin-classes/batch-edit',
    getTitle: () => $t('批量编辑行政班'),
    requirePermissions: [PermissionNameEnum.CoreAdminClassWrite],
    requireModules: [ModuleEnum.AdminClass],
    component: lazy(() =>
      import('../pages/Management/Classes/BatchEdit').then(m => ({
        default: m.AdminClassBatchEdit,
      })),
    ),
  },
  {
    path: 'admin/admin-classes/:adminClassId/view',
    name: 'AdminAdminClass',
    getTitle: () => $t('行政班'),
    requirePermissions: [PermissionNameEnum.CoreAdminClassRead],
    requireModules: [ModuleEnum.AdminClass],
    component: lazy(() =>
      import('../pages/Management/Classes/View').then(m => ({
        default: m.Index,
      })),
    ),
    subRoutes: [
      {
        name: 'Detail',
        path: 'detail',
        getTitle: () => $t('行政班信息'),
        component: lazy(() =>
          import('../pages/Management/Classes/View/View').then(m => ({
            default: m.AdminClassView,
          })),
        ),
      },
      {
        path: 'courses',
        getTitle: () => $t('课程管理'),
        component: lazy(() =>
          import('../pages/Management/Classes/View/Courses').then(m => ({
            default: m.AdminClassCourses,
          })),
        ),
      },
      {
        path: 'members',
        getTitle: () => $t('人员管理'),
        component: lazy(() =>
          import('../pages/Management/Classes/View/Members').then(m => ({
            default: m.AdminClassMembers,
          })),
        ),
      },
      ...getAdminClassGradeRoutes(),
      ...getRouteSlots('adminClassDefaultSubRoutes'),
    ],
  },
  {
    path: 'admin/admin-classes/:adminClassId/edit',
    getTitle: () => $t('编辑行政班'),
    requirePermissions: [PermissionNameEnum.CoreAdminClassWrite],
    requireModules: [ModuleEnum.AdminClass],
    component: lazy(() =>
      import('../pages/Management/Classes/Edit').then(m => ({
        default: m.AdminClassEdit,
      })),
    ),
  },
  ...attendancesRoutes,
]

const attendancesRoutes: RouteConfig[] = [
  {
    path: 'admin/admin-classes/attendances',
    getTitle: () => $t('考勤管理'),
    name: 'AdminAdminClassAttendance',
    module: ['admin-class'],
    requirePermissions: [PermissionNameEnum.CoreAdminClassRead],
    requireModules: [ModuleEnum.AdminClassAttendance],
    component: lazy(() =>
      import('../pages/Management/Attendance').then(m => ({
        default: m.AdminAdminClassAttendances,
      })),
    ),
    subRoutes: [
      {
        path: 'records',
        name: 'Records',
        getTitle: () => $t('考勤记录'),
        component: lazy(() =>
          import('../pages/Management/Attendance/Records').then(m => ({
            default: m.AdminAdminClassAttendancesRecords,
          })),
        ),
      },
      {
        path: 'stats',
        name: 'Stats',
        getTitle: () => $t('行政班考勤统计'),
        component: lazy(() =>
          import('../pages/Management/Attendance/Stats').then(m => ({
            default: m.AdminAdminClassAttendancesStats,
          })),
        ),
      },
      {
        name: 'StudentStats',
        path: 'student-stats',
        getTitle: () => $t('学生考勤统计'),
        component: lazy(() =>
          import('../pages/Management/Attendance/StudentStats').then(m => ({
            default: m.AdminAdminClassAttendancesStudentStats,
          })),
        ),
      },
      {
        path: 'settings',
        name: 'Settings',
        getTitle: () => $t('考勤设置'),
        component: lazy(() =>
          import('../pages/Management/Attendance/Settings').then(m => ({
            default: m.AdminAdminClassAttendancesSettings,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/admin-classes/attendances/class/:classId',
    name: 'AdminAdminClassAttendanceClass',
    requireModules: [ModuleEnum.AdminClassAttendance],
    getTitle: () => $t('行政班考勤详情'),
    module: ['admin-class'],
    component: lazy(() =>
      import('../pages/Management/Attendance/AdminClass').then(m => ({
        default: m.AdminAdminClassAttendanceClass,
      })),
    ),
    subRoutes: [
      {
        path: 'stats',
        name: 'Stats',
        getTitle: () => $t('考勤详情'),
        component: lazy(() =>
          import('../pages/Management/Attendance/AdminClass/Stats').then(m => ({
            default: m.AdminAdminClassAttendanceClassStats,
          })),
        ),
      },
      {
        path: 'student-stats',
        name: 'StudentStats',
        getTitle: () => $t('学生考勤统计'),
        component: lazy(() =>
          import('../pages/Management/Attendance/AdminClass/StudentStats').then(
            m => ({
              default: m.AdminAdminClassAttendanceClassStats,
            }),
          ),
        ),
      },
    ],
  },
]
