/**
 * @file 空间管理 - 菜单注册
 */

import { atom } from 'jotai'

import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const adminSpaceMenu = atom(() => [
  {
    prefix: ['system'],
    label: $t('空间管理'),
    name: 'places',
    path: '/admin/places',
    icon: 'Space' as const,
    shortcutIcon: 'SpaceSolid+F7585B',
    type: MenuType.Admin,
    sort: 702,
    permission: [PermissionNameEnum.CorePlaceRead],
  },
])
