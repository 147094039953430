import { InitFeatureParams } from '@/features/types'

import { calendarEvents } from './calendar-events'
import { homeMenuProvider } from './menus'
import { routes } from './routes'
import { widgets } from './widgets'

/**
 * 初始化桌面端首页
 *
 * @param params - init feature params
 */
export const initHome = (params: InitFeatureParams) => {
  const {
    addRoutes,
    addMenuProvider,
    registerWidgets,
    registerCalendarEvents,
  } = params

  addRoutes(routes)
  addMenuProvider(homeMenuProvider)
  registerWidgets(widgets)
  registerCalendarEvents(calendarEvents)
}
