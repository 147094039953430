export enum SubjectAnalysisTabKeyEnum {
  SubjectOverview = 'subject-overview',
  Distribution = 'people-distribution',
  TeacherComparison = 'teacher-comparison',
  CourseComparison = 'course-comparison',
  QuestionOverview = 'question-overview',
  QuestionCondition = 'question-condition',
  QuestionDistribution = 'question-distribution',
  KnowledgeScoreRate = 'knowledge-score-rate',
  StudentScoreTable = 'student-score-table',
}

export type ExamSubjectAnalysisTabItemType = {
  label: string
  key: SubjectAnalysisTabKeyEnum | string
  isSub?: boolean
  isDivider?: boolean
}
