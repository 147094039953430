/**
 * @file 未读消息列表成员
 */

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { moment } from '@seiue/moment'
import { Icon, Toast } from '@seiue/ui'
import { isFunction } from '@seiue/util'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { htmlDecodeByRegExp } from 'packages/components/Article'
import {
  WebNavigatorRes,
  getHijackMessageClickFn,
  getMessageNavigatorResult,
} from 'packages/features/messages/register'
import { useCurrentReflection } from 'packages/features/sessions'
import { $t } from 'packages/locale'
import { Message } from 'packages/sdks-next/chalk'

import { useReadMessage } from '@/features/messages/apis'
import { Dot } from '@/features/notices/components/Dot'
import { RouteModalParams, useRouteModalHistory } from '@/router'

import { MessageLabel } from './MessageLabel'

interface Props {
  message: Message & {
    attributes?: {
      actionType?: 'admin_class'
      adminClassId?: number
      // message 携带 url，那么点击消息时直接跳转到 url
      url?: string
    }
  }
}

/**
 * 得到一个获取「消息导航方法」的方法
 *
 * @returns 获取「消息导航方法」的方法
 */
export const useGetMessageNavigate = () => {
  const currentReflection = useCurrentReflection()

  const modalHistory = useRouteModalHistory()
  const history = useHistory()

  const readMessage = useReadMessage()

  return useCallback(
    (message: Props['message']) => {
      const hijackClick = getHijackMessageClickFn(message.type)

      if (hijackClick) {
        return () => hijackClick(message)
      }

      const { url, actionType, adminClassId } = message.attributes || {}

      if (url) {
        return async () => {
          await readMessage(message)

          window.open(url)
        }
      }

      if (actionType === 'admin_class') {
        return async () => {
          await readMessage(message)

          history.push(`/admin-classes/${adminClassId}/schedule`)
        }
      }

      const navigateResult: WebNavigatorRes = getMessageNavigatorResult(
        message,
        currentReflection,
      )

      if (!navigateResult) {
        return undefined
      }

      const navigate = (routeParams: WebNavigatorRes) => {
        const {
          path,
          pathInNewWindow = false,
          modalName,
          options,
        } = routeParams

        if (path) {
          return async () => {
            await readMessage(message)

            return pathInNewWindow
              ? window.open(path)
              : history.push(path, options)
          }
        }

        return async () => {
          await readMessage(message)

          modalHistory.push(modalName as keyof RouteModalParams, options)
        }
      }

      if (isFunction(navigateResult)) {
        return async () => {
          readMessage(message)

          const routeParams = await navigateResult()
          if (!routeParams) {
            Toast.warning($t('此消息无更多内容'))
            return undefined
          }

          return navigate(routeParams)()
        }
      }

      return navigate(navigateResult)
    },
    [currentReflection, history, modalHistory, readMessage],
  )
}

/**
 * 系统消息列表 Item
 *
 * @param param - assignment object
 * @param param.message - 消息
 * @returns React.ReactElement
 */
export const MessageItem: React.FC<Props> = ({ message }) => {
  const getNavigate = useGetMessageNavigate()

  const navigate = getNavigate(message)

  return (
    <Wrapper onClick={navigate} clickable={!!navigate}>
      <Inner>
        <Align>
          <MessageLabel message={message} />
          <Title>{message.title || $t('无标题')}</Title>
          {!message.readed && <Dot />}
          {!!navigate && (
            <More>
              {$t('详情')}
              <Icon icon={faChevronRight} />
            </More>
          )}
        </Align>
        <Content>
          {message.type !== 'message'
            ? message.content
            : htmlDecodeByRegExp(message.content).replace(/<[^>]*>?/gm, '')}
        </Content>
        <Date>{moment(message.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Date>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs((props: { clickable: boolean }) => ({
  clickable: props.clickable,
}))`
  ${props =>
    props.clickable &&
    `
    &{
      cursor:pointer;
      &:hover {
        background-color: #f9fafc;
      }
    }
  `}
  background-color: white;
  padding: 0 24px;
  border-bottom: 1px solid ${props => props.theme.divider._2};

  &:last-child {
    border-bottom: none;
  }
`

const Inner = styled.div`
  padding: 24px 0;
`

const Align = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Title = styled.div`
  margin-left: 8px;
  color: ${props => props.theme.text._1};
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  margin-right: 8px;
`

const Content = styled.div`
  margin: 12px 0;
  color: ${props => props.theme.text._2};
  font-size: 13px;
  line-height: 22px;
  word-break: break-word;
`

const Date = styled.div`
  color: ${props => props.theme.text._3};
  font-size: 13px;
  line-height: 1;
`

const More = styled.div`
  margin-left: auto;
  color: ${props => props.theme.text._3};
  line-height: 1;

  i {
    margin-left: 6px;
  }
`
