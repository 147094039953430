/**
 * @file 通知 routes
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const routes = [
  {
    path: 'notices/:tab',
    name: 'NoticeList',
    getTitle: () => $t('通知列表'),
    component: lazy(() =>
      import('./pages/List').then(m => ({ default: m.Notices })),
    ),
  },
  {
    path: 'admin/notices',
    name: 'AdminNoticeList',
    getTitle: () => $t('通知管理'),
    requirePermissions: [PermissionNameEnum.CoreNotificationRead],
    component: lazy(() =>
      import('./pages/Manager/List').then(m => ({
        default: m.ManagerNoticeList,
      })),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'InboxNoticeModal',
    getTitle: () => $t('通知详情'),
    component: lazy(() =>
      import('./pages/InboxNoticeModal').then(m => ({
        default: m.InboxNoticeModal,
      })),
    ),
  },
  {
    name: 'OutboxNoticeModal',
    getTitle: () => $t('通知详情'),
    component: lazy(() =>
      import('./pages/OutboxNoticeModal').then(m => ({
        default: m.OutboxNoticeModal,
      })),
    ),
  },
  {
    name: 'SendNoticeModal',
    getTitle: () => $t('发送通知'),
    component: lazy(() =>
      import('./pages/SendNoticeModal').then(m => ({
        default: m.SendNoticeModal,
      })),
    ),
  },
]
