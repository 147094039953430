import { InitFeatureParams } from '@/features/types'

import { adminDirectionMenus } from './menus'
import { getRoutes, modalRoutes } from './routes'
import { todos } from './todos'

/**
 * 选科
 *
 * @param param0 - 参数
 */
export const initSubjectSelection = (param0: InitFeatureParams) => {
  const { addRoutes, addMenuProvider, registerTodos } = param0

  registerTodos(todos)
  addMenuProvider(adminDirectionMenus)
  addRoutes(getRoutes(), modalRoutes)
}
