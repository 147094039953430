/**
 * @file 评价项选择器语法糖
 *
 * 语法糖提供两个方法
 * pickAssessmentItem - 选择 item，如果你想要重置 pickAssessmentItem 接收一个 reset 参数。
 * destoryRootAssessmentItemPicker - 主动释放 destoryRootAssessmentItemPicker
 */
import { State } from '@seiue/ui'
import { noop } from '@seiue/util'
import React, { lazy, Suspense } from 'react'

import { Modal, registerGlobalPicker } from 'packages/components/Modal'
import { ZIndex } from 'packages/themed'

import type { Props as TreeProps } from './index'

const ItemTree = lazy(() =>
  import('./index').then(m => ({ default: m.ItemTree })),
)

type Options = Omit<
  TreeProps,
  'editable' | 'sorting' | 'onChange' | 'selectable' | 'onSelected'
> & {
  title: string
  header?: React.ReactNode
}

type OnSelectParameters = Parameters<Required<TreeProps>['onSelected']>
type PickReturnType = OnSelectParameters[0]

type PickOptions = Options & {
  reset?: boolean
}

const { getPicker, getDestory, setPicker, setDestory } = registerGlobalPicker<
  (options: PickOptions) => Promise<PickReturnType>
>('rootAssessmentItemPicker')

export const pickAssessmentItems: (
  options: PickOptions,
) => Promise<PickReturnType> | undefined = options => getPicker()?.(options)

export const destoryRootAssessmentItemPicker = () => {
  getDestory()?.()
}

type PickerWrapperProps = Options & {
  onSubmit: (result?: PickReturnType) => void
  onCancel: () => void
}

type PickerWrapperRef = {
  hide: () => void
  show: () => void
}

const PickerWrapper = React.forwardRef<PickerWrapperRef, PickerWrapperProps>(
  ({ onSubmit, onCancel, title, selectedItems, header, ...props }, ref) => {
    const [visible, setVisible] = React.useState(true)
    const [selected, setSelected] = React.useState(selectedItems)

    const innerSubmit = () => {
      onSubmit(selected)
      setVisible(false)
    }

    const innerCancel = () => {
      onCancel()
      setVisible(false)
    }

    React.useImperativeHandle(ref, () => ({
      show: () => {
        setVisible(true)
      },
      hide: () => {
        setVisible(false)
      },
    }))

    return (
      <Modal
        zIndex={ZIndex.Prompt}
        title={title}
        visible={visible}
        onCancel={innerCancel}
        onOk={() => {
          innerSubmit()
        }}
        okButtonProps={{
          disabled: !selected?.length,
        }}
        width={960}
      >
        {header}
        <Suspense fallback={<State.Loading />}>
          <ItemTree
            {...props}
            selectedItems={selected}
            editable={false}
            sorting={false}
            selectable={true}
            onSelected={setSelected}
          />
        </Suspense>
      </Modal>
    )
  },
)

// 放在 App 组件根部的组件选择器，以供方法调用。
export const RootAssessmentItemPicker: React.FC = () => {
  const [inited, setInited] = React.useState(false)
  const [options, setOptions] = React.useState<Options | undefined>()
  const pickerRef = React.useRef<any>()
  const submit = React.useRef<(items?: PickReturnType) => void>(noop)
  const cancel = React.useRef<() => void>(noop)

  React.useEffect(() => {
    setPicker(async nextOptions => {
      const { reset, ...newOptions } = nextOptions

      if (!inited) setInited(true)
      else if (reset) {
        setInited(false)
        // 将 setInited 放在下一帧执行，保证 inited 先被设置为 false
        requestAnimationFrame(() => {
          setInited(true)
        })
      }

      setOptions({
        ...(options || {}),
        ...newOptions,
      })

      return new Promise(resolve => {
        submit.current = items => {
          // @ts-ignore
          resolve(items)
        }

        cancel.current = () => {
          // @ts-ignore
          resolve(undefined)
        }

        /*
         * 刚初始化时，PickerWrapper 还没渲染完毕，pickerRef 会处于无值状态
         * 别担心，PickerWrapper 第一次初始化默认为显示状态
         */
        pickerRef.current?.show()
      })
    })

    setDestory(async () => {
      pickerRef.current?.hide()
      setInited(false)
    })
  }, [inited, options])

  if (!inited || !options) return null

  return (
    <PickerWrapper
      ref={pickerRef}
      {...options}
      onSubmit={submit.current}
      onCancel={cancel.current}
    />
  )
}
