/**
 * @file 任务待办注册
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.TaskSubmitTodo,
    Render: lazy(() =>
      import('./TaskSubmitCard').then(m => ({ default: m.TaskSubmitCard })),
    ),
  },
  {
    type: TodoTypeEnum.TaskApproveTodo,
    Render: lazy(() =>
      import('./TaskApproveCard').then(m => ({ default: m.TaskApproveCard })),
    ),
  },
]
