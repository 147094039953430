import { InitFeatureParams } from '../types'

import { getRoutes, modalRoutes } from './Route'
import { adminGoalMenu } from './menus'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initProfiles = ({
  addRoutes,
  addMenuProvider,
}: InitFeatureParams) => {
  addRoutes(getRoutes(), modalRoutes)
  addMenuProvider(adminGoalMenu)
}
