/**
 * @file plugins route
 */

import React, { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const routes: RouteConfig[] = [
  {
    path: 'admin/plugins/applications',
    name: 'AdminPluginsApplications',
    getTitle: () => $t('应用管理'),
    module: ['plugin'],
    requirePermissions: [
      PermissionNameEnum.CorePluginRead,
      PermissionNameEnum.CorePluginWrite,
    ],
    component: lazy(() =>
      import('./pages/admin/applications').then(m => ({
        default: m.ApplicationsManage,
      })),
    ),
  },
  {
    path: 'admin/plugins/extensions',
    name: 'AdminPluginsExtensions',
    module: ['plugin'],
    getTitle: () => $t('扩展管理'),
    requirePermissions: [
      PermissionNameEnum.CorePluginRead,
      PermissionNameEnum.CorePluginWrite,
    ],
    component: lazy(() =>
      import('./pages/admin/extensions').then(m => ({
        default: m.Extensions,
      })),
    ),
    subRoutes: [
      {
        name: 'Installed',
        path: 'installed',
        getTitle: () => $t('已安装'),
        component: lazy(() =>
          import('./pages/admin/extensions/Installed').then(m => ({
            default: m.Installed,
          })),
        ),
      },
      {
        name: 'All',
        path: 'all',
        getTitle: () => $t('全部扩展'),
        component: lazy(() =>
          import('./pages/admin/extensions/All').then(m => ({
            default: m.All,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugins/logs',
    name: 'AdminPluginsLogs',
    module: ['plugin'],
    getTitle: () => $t('操作日志'),
    requirePermissions: [
      PermissionNameEnum.CorePluginRead,
      PermissionNameEnum.CorePluginWrite,
    ],
    component: lazy(() =>
      import('./pages/admin/logs').then(m => ({
        default: m.Logs,
      })),
    ),
  },
  {
    path: 'user/applications-center',
    name: 'ApplicationsCenter',
    getTitle: () => $t('应用中心'),
    component: lazy(() =>
      import('./pages/user/applications').then(m => ({
        default: m.ApplicationsCenter,
      })),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'SinglePluginSetting',
    getTitle: () => $t('插件设置'),
    requirePermissions: [PermissionNameEnum.CorePluginWrite],
    component: React.lazy(() =>
      import('./components/SinglePlugin').then(mod => ({
        default: mod.SinglePluginSetting,
      })),
    ),
  },
  {
    name: 'EditPlugin',
    getTitle: () => $t('自建插件'),
    requirePermissions: [PermissionNameEnum.CorePluginWrite],
    component: React.lazy(() =>
      import('./components/EditSelfPlugin').then(mod => ({
        default: mod.EditPlugin,
      })),
    ),
  },
  {
    name: 'SubPluginSetting',
    getTitle: () => $t('插件设置'),
    component: React.lazy(() =>
      import('./components/ExtendablePlugin/Setting').then(mod => ({
        default: mod.Plugin,
      })),
    ),
    subRoutes: [],
  },
  {
    name: 'PluginDetail',
    getTitle: () => $t('插件详情'),
    component: React.lazy(() =>
      import('./components/DetailModal/ModalRoute').then(mod => ({
        default: mod.DetailModal,
      })),
    ),
  },
]
