import { atom } from 'jotai'

import {
  hasEnabledPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PermissionNameEnum, RoleEnum } from 'packages/sdks-next/chalk'
import { MenuType } from 'packages/web-layout/types'

import { getStoreForAtom } from '@/store'

export const userClassTaskMenu = atom(get => {
  const classTaskEnabled = get(hasEnabledPluginAtom(PluginNameEnum.ClassTasks))

  if (!classTaskEnabled) return []

  const store = getStoreForAtom(get)
  const me = store.session.currentReflection

  const subMenus = [
    {
      label: $t('我管理的'),
      path: '/class-tasks/managed',
      icon: 'PersonCheck' as const,
    },
    {
      label: $t('我参与的'),
      path: '/class-tasks/participated',
      icon: 'PersonPlus' as const,
    },
  ]

  if (me?.role !== RoleEnum.Teacher) {
    subMenus.reverse()
  }

  return [
    {
      label: $t('课业任务'),
      name: PluginEnum.ClassTasks,
      sort: 206,
      path: '/class-tasks',
      type: MenuType.Apps,
      subMenus,
    },
  ]
})

export const adminClassTaskMenu = atom(get => {
  const classTaskEnabled = get(hasEnabledPluginAtom(PluginNameEnum.ClassTasks))

  if (!classTaskEnabled) return []

  return [
    {
      prefix: ['courses'],
      label: $t('任务管理'),
      name: 'class-tasks',
      sort: 206,
      icon: 'ListCheckSolid' as const,
      shortcutIcon: 'TaskSolid+EA7B25',
      permission: [PermissionNameEnum.CoreClassRead],
      path: '/admin/class-tasks/management',
    },
  ]
})
