import { registerTodos } from 'packages/features/todos/register'

import { InitFeatureParams } from '@/features/types'

import { adminEvaluationMenu } from './menus'
import { routes, modalRoutes } from './routes'
import { todos } from './todos'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initEvaluations = ({
  addRoutes,
  addMenuProvider,
}: InitFeatureParams) => {
  registerTodos(todos)
  addRoutes(routes, modalRoutes)
  addMenuProvider(adminEvaluationMenu)
}
