/**
 * @file 注册审批待办
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos'

const FlowTodoCard = lazy(() =>
  import('./FlowTodoCard').then(m => ({
    default: m.FlowTodoCardForWorkflow,
  })),
)

export const todos: TodoExecution[] = [
  {
    // 审批人待办
    type: TodoTypeEnum.WorkflowApprover,
    Render: FlowTodoCard,
  },
  {
    // 办理人待办
    type: TodoTypeEnum.WorkflowHandler,
    Render: FlowTodoCard,
  },
  {
    // 发起人待办
    type: TodoTypeEnum.WorkflowInitiator,
    Render: FlowTodoCard,
  },
]
