import { InitFeatureParams } from '@/features/types'

import { netDiskShareRoutes, routes } from './routes'

/**
 * 初始化分享页
 *
 * @param param0 - 参数
 */
export const initShares = (param0: InitFeatureParams) => {
  const { addRoutes } = param0
  addRoutes([...routes, ...netDiskShareRoutes])
}
