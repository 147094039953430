/**
 * @file 学期管理 routes
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const routes: RouteConfig[] = [
  {
    name: 'AdminSemesters',
    path: 'admin/semesters/manager',
    module: ['system'],
    getTitle: () => $t('学期管理'),
    requirePermissions: [PermissionNameEnum.CoreSemesterWrite],
    component: lazy(() =>
      import('./pages/List').then(m => ({ default: m.List })),
    ),
  },
  {
    path: 'admin/semesters/school-calendar/settings',
    getTitle: () => $t('设置'),
    module: ['system'],
    component: lazy(() =>
      import('./pages/Calendar/Setting').then(m => ({
        default: m.CalendarSetting,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreSemesterWrite],
  },
  {
    name: 'AdminSchoolCalendar',
    path: 'admin/semesters/school-calendar/:id',
    getTitle: () => $t('校历管理'),
    module: ['system'],
    component: lazy(() =>
      import('./pages/Calendar').then(m => ({
        default: m.CalendarManager,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreSemesterWrite],
  },
  {
    name: 'NewSemester',
    path: 'admin/semesters/new',
    module: ['system'],
    getTitle: () => $t('新建学期'),
    component: lazy(() =>
      import('./pages/New/index').then(m => ({ default: m.SemestersNew })),
    ),
    requirePermissions: [PermissionNameEnum.CoreSemesterWrite],
  },
  {
    name: 'EditSemester',
    path: 'admin/semesters/:id/edit',
    module: ['system'],
    getTitle: () => $t('编辑学期'),
    component: lazy(() =>
      import('./pages/New/index').then(m => ({ default: m.SemestersNew })),
    ),
    requirePermissions: [PermissionNameEnum.CoreSemesterWrite],
  },
]
