import { lazy } from 'react'

import { ModuleEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { getRouteSlots } from '@/plugins'

import { getAdminClassGradeRoutes } from './grade'

/**
 * 行政班路由配置 - 用户
 *
 * @returns routes
 */
const getDefaultSubRoutes = (): RouteConfig[] => [
  {
    name: 'AdminClassSchedule',
    path: 'schedule',
    getTitle: () => $t('日程'),
    component: lazy(() =>
      import('@/features/admin-classes/pages/user/Schedule').then(m => ({
        default: m.Schedule,
      })),
    ),
  },
  {
    name: 'AdminClassAttendance',
    path: 'attendance',
    getTitle: () => $t('考勤'),
    component: lazy(() =>
      import('@/features/admin-classes/pages/user/Attendance').then(m => ({
        default: m.AdminClassAttendance,
      })),
    ),
    requireModules: [ModuleEnum.AdminClassAttendance],
    subRoutes: [
      {
        name: 'Entry',
        path: 'entry',
        getTitle: () => $t('每日考勤'),
        component: lazy(() =>
          import('@/features/admin-classes/pages/user/Attendance/Entry').then(
            m => ({
              default: m.AdminClassTeacherAttendanceEntry,
            }),
          ),
        ),
      },
      {
        name: 'StudentStat',
        path: 'student-stat',
        getTitle: () => $t('班级考勤统计'),
        component: lazy(() =>
          import(
            '@/features/admin-classes/pages/user/Attendance/StudentStat'
          ).then(m => ({
            default: m.AdminClassAttendanceStudentStat,
          })),
        ),
      },
      {
        name: 'ClassStat',
        path: 'class-stat',
        getTitle: () => $t('课程考勤统计'),
        component: lazy(() =>
          import(
            '@/features/admin-classes/pages/user/Attendance/ClassStat'
          ).then(m => ({
            default: m.AdminClassAttendanceClassStat,
          })),
        ),
      },
    ],
  },
  {
    name: 'AdminClassMembers',
    path: 'members',
    getTitle: () => $t('成员'),
    component: lazy(() =>
      import('@/features/admin-classes/pages/user/Members').then(m => ({
        default: m.Members,
      })),
    ),
  },
  ...getAdminClassGradeRoutes(),
  ...getRouteSlots('adminClassDefaultSubRoutes'),
]

/**
 * 行政班路由配置 - 用户
 *
 * @returns routes
 */
export const getUserRoutes = (): RouteConfig[] => [
  {
    name: 'ShowAdminClass',
    path: 'admin-classes/:classId',
    getTitle: () => $t('行政班详情'),
    component: lazy(() =>
      import('@/features/admin-classes/pages/user').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: getDefaultSubRoutes(),
  },
  ...getRouteSlots('adminClassRoutes'),
]
