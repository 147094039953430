/**
 * @file 通知 menus
 */

import { useHasEnabledParentPlugin } from 'packages/feature-utils/plugins'
import { useUnreadNoticeCount } from 'packages/features/messages/hooks'
import { SchoolModulesEnum } from 'packages/features/schools/types'

import { NoticeMenuLabelRender } from './utils/views'

export const useNoticeSiderMenus = () => {
  const noticeEnabled = useHasEnabledParentPlugin(SchoolModulesEnum.Notice)

  const { count: unreadCount } = useUnreadNoticeCount()

  return noticeEnabled
    ? [
        {
          path: '/notices/inbox',
          icon: 'Notice' as const,
          label: <NoticeMenuLabelRender unreadCount={unreadCount} />,
          height: 40,
        },
      ]
    : []
}
