import { Suspense, lazy } from 'react'

import { useImportantNoticeModalSettings } from 'packages/features/notices/hooks/use-important-notice-modal-settings'

import { RootInterrupt } from '@/uikit'

const ImportantNoticeModalLazy = lazy(() =>
  import('./ImportantNoticeModal').then(c => {
    return {
      default: c.ImportantNoticeModal,
    }
  }),
)

export const ImportantNoticeModal: React.FC = () => {
  const { title, next, isDone, currentConversationId, loading, skip } =
    useImportantNoticeModalSettings()

  const canShow = RootInterrupt.useCanShow('importantNotice', isDone)

  if (!canShow || loading) return null

  return (
    <Suspense fallback={null}>
      <ImportantNoticeModalLazy
        title={title}
        currentConversationId={currentConversationId}
        onRecepited={next}
        skipNotice={skip}
      />
    </Suspense>
  )
}
