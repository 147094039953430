/**
 * @file 课业任务 routes
 */

import { lazy } from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const routes: RouteConfig[] = [
  {
    name: 'PluginClassTasks',
    path: 'class-tasks',
    getTitle: () => $t('课业任务'),
    module: [PluginEnum.ClassTasks],
    requireModules: [PluginEnum.ClassTasks],
    component: lazy(() =>
      import('@/features/class-tasks/pages/List/index').then(m => ({
        default: m.List,
      })),
    ),
    subRoutes: [
      {
        path: 'managed',
        getTitle: () => $t('我管理的'),
        component: lazy(() =>
          import('@/features/class-tasks/pages/List/Managed').then(m => ({
            default: m.Managed,
          })),
        ),
      },
      {
        path: 'participated',
        getTitle: () => $t('我参与的'),
        component: lazy(() =>
          import('@/features/class-tasks/pages/List/Participated').then(m => ({
            default: m.Participated,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/class-tasks/management/classes/:classId/tasks',
    name: 'ClassTasksManagementClassTasks',
    getTitle: () => $t('课程任务列表'),
    component: lazy(() =>
      import('@/features/class-tasks/pages/Management/Klass/TaskList').then(
        m => ({
          default: m.KlassTaskList,
        }),
      ),
    ),
    requirePermissions: [PermissionNameEnum.CoreClassRead],
    requireModules: [PluginEnum.ClassTasks],
  },
  {
    path: 'admin/class-tasks/management/teachers/:teacherId/tasks',
    name: 'ClassTasksManagementTeachersTasks',
    getTitle: () => $t('教师任务列表'),
    component: lazy(() =>
      import('@/features/class-tasks/pages/Management/Teacher/TaskList').then(
        m => ({
          default: m.TeacherTaskList,
        }),
      ),
    ),
    requirePermissions: [PermissionNameEnum.CoreClassRead],
    requireModules: [PluginEnum.ClassTasks],
  },
  {
    path: 'admin/class-tasks/management',
    module: ['courses'],
    getTitle: () => $t('任务管理'),
    component: lazy(() =>
      import('@/features/class-tasks/pages/Management').then(m => ({
        default: m.ClassTaskManagement,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreClassRead],
    requireModules: [PluginEnum.ClassTasks],
    subRoutes: [
      {
        path: 'classes',
        getTitle: () => $t('课程班任务统计'),
        component: lazy(() =>
          import('@/features/class-tasks/pages/Management/Klass').then(m => ({
            default: m.KlassStates,
          })),
        ),
      },
      {
        path: 'students',
        getTitle: () => $t('学生任务统计'),
        component: lazy(() =>
          import('@/features/class-tasks/pages/Management/Student').then(m => ({
            default: m.StudentStates,
          })),
        ),
      },
      {
        path: 'teachers',
        getTitle: () => $t('教师任务统计'),
        component: lazy(() =>
          import('@/features/class-tasks/pages/Management/Teacher').then(m => ({
            default: m.TeacherStates,
          })),
        ),
      },
      {
        path: 'templates',
        getTitle: () => $t('模板'),
        component: lazy(() =>
          import('@/features/class-tasks/pages/Management/Templates').then(
            m => ({
              default: m.TaskTemplates,
            }),
          ),
        ),
      },
      {
        path: 'setting',
        getTitle: () => $t('任务设置'),
        component: lazy(() =>
          import('@/features/class-tasks/pages/admin/Setting').then(m => ({
            default: m.Setting,
          })),
        ),
      },
    ],
  },
]

export const modalRoutes = [
  {
    name: 'ClassTasksNewModal',
    getTitle: () => $t('创建任务'),
    requireModules: [PluginEnum.ClassTasks],
    component: lazy(() =>
      import('@/features/class-tasks/pages/New/index').then(m => ({
        default: m.NewTaskModal,
      })),
    ),
  },
  {
    name: 'TaskAnswerSheetDownloadModal',
    getTitle: () => $t('查看并下载答题卡'),
    requireModules: [PluginEnum.ClassTasks],
    component: lazy(() =>
      import('@/features/class-tasks/pages/AnswerSheetDownloadModal').then(
        m => ({
          default: m.TaskAnswerSheetDownloadModal,
        }),
      ),
    ),
  },
  {
    name: 'ClassTasksViewModal',
    getTitle: () => $t('查看任务'),
    requireModules: [PluginEnum.ClassTasks],
    component: lazy(() =>
      import('@/components/Tasks/Show').then(m => ({
        default: m.TasksShowModal,
      })),
    ),
  },
  {
    name: 'TaskQuestTemplateEditModal',
    getTitle: () => $t('创建模板'),
    requireModules: [PluginEnum.ClassTasks],
    component: lazy(() =>
      import('@/features/class-tasks/pages/admin/TaskQuestModal').then(m => ({
        default: m.TaskQuestTemplateEditModal,
      })),
    ),
  },
  {
    name: 'ClassQuestionnaireTasksModal',
    getTitle: () => $t('新建问卷'),
    requireModules: [PluginEnum.ClassTasks],
    component: lazy(() =>
      import(
        '@/features/class-tasks/components/QuestionnaireTaskModal/index'
      ).then(m => ({ default: m.QuestionnaireTaskModal })),
    ),
  },
  {
    name: 'SubmitQuestionnaireTaskModal',
    getTitle: () => $t('提交问卷'),
    requireModules: [PluginEnum.ClassTasks],
    component: lazy(() =>
      import('@/components/Questionnaire/SubmitRouteModal').then(m => ({
        default: m.SubmitQuestionnaireRouteModal,
      })),
    ),
  },
  {
    name: 'SubmitQuizTaskModal',
    getTitle: () => $t('提交测验'),
    requireModules: [PluginEnum.ClassTasks],
    component: lazy(() =>
      import('@/components/Tasks/Show/Student/SubmitQuizTask').then(m => ({
        default: m.SubmitQuizTaskRouteModal,
      })),
    ),
  },
  {
    name: 'DiscussionTaskPeronalStatistis',
    getTitle: () => $t('个人统计'),
    requireModules: [PluginEnum.ClassTasks],
    component: lazy(() =>
      import(
        '@/components/Tasks/Show/DiscussionTask/PersonalDiscussionStats'
      ).then(m => ({
        default: m.Statistics,
      })),
    ),
  },
]
