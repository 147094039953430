/**
 * @file 绑定手机 modal
 */

import { FC, lazy, Suspense } from 'react'

import { useForceBindPhone } from 'packages/features/reflections/utils/setting'
import {
  useCurrentReflection,
  useCurrentUser,
} from 'packages/features/sessions/utils'

import { RootInterrupt } from '@/uikit'

const RootBindPhoneModalOrigin = lazy(() =>
  import('./Component').then(m => ({ default: m.RootBindPhoneModal })),
)

export const RootBindPhoneModal: FC = () => {
  const user = useCurrentUser()
  const currentReflection = useCurrentReflection()

  const { data: forceBind } = useForceBindPhone({
    role: currentReflection.role,
  })

  const gotPhone = !!user?.phone

  const done = gotPhone || !forceBind

  const canShow = RootInterrupt.useCanShow('bindPhone', done)

  if (!canShow) return null

  return (
    <Suspense fallback={null}>
      <RootBindPhoneModalOrigin currentReflection={currentReflection} />
    </Suspense>
  )
}
