import React, { lazy } from 'react'

import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const routes: RouteConfig[] = [
  {
    path: 'admin/semester-review',
    name: 'AdminSemesterReview',
    getTitle: () => $t('学期评语'),
    module: [PluginCategoryEnum.德育管理, SchoolModulesEnum.SemesterReview],
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    requirePermissions: [PermissionNameEnum.CoreSemesterReviewRead],
    subRoutes: [
      {
        path: 'list',
        name: 'List',
        getTitle: () => $t('全部学期评语'),
        component: React.lazy(() =>
          import('@/features/semester-review/pages/admin/List').then(m => ({
            default: m.List,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/semester-review/:id/detail',
    name: 'AdminSemesterReviewDetail',
    getTitle: () => $t('学期评语详情'),
    requirePermissions: [PermissionNameEnum.CoreSemesterReviewRead],
    component: React.lazy(() =>
      import('@/features/semester-review/pages/admin/Detail').then(m => ({
        default: m.AdminSemesterReviewDetail,
      })),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'AdminSemesterReview.TaskEditModal',
    getTitle: () => $t('新增学期评语'),
    component: lazy(() =>
      import('@/features/semester-review/pages/admin/Edit').then(m => ({
        default: m.EditModal,
      })),
    ),
  },
  {
    name: 'AdminSemesterReview.ReviewEntryModal',
    getTitle: () => $t('学期评语录入'),
    component: lazy(() =>
      import('@/features/semester-review/pages/admin/Entry').then(m => ({
        default: m.ReviewEntry,
      })),
    ),
  },
  {
    name: 'SemesterReview.ReviewEntryModal',
    getTitle: () => $t('学期评语录入'),
    component: lazy(() =>
      import('@/features/semester-review/pages/user/Entry').then(m => ({
        default: m.ReviewEntry,
      })),
    ),
  },
]
