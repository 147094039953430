import type { IconSource } from '@seiue/ui'
import { partition } from '@seiue/util'
import { lazy } from 'react'

import type { NoCodeApp } from 'packages/no-code/desktop'
import { RouteConfig } from 'packages/route'
import { LayoutPageIdentityEnum, LayoutTab } from 'packages/sdks-next/chalk'
import {
  Menu,
  MenuPlacementEnum,
  ProfileMenuRoleEnum,
} from 'packages/sdks-next/nuwa'
import { MenuItemUnion } from 'packages/web-layout/types'

/**
 * 将菜单数据转换为菜单项
 *
 * @param root0 - 参数
 * @param root0.appName - 应用名称
 * @param root0.prefix - 路径前缀
 * @param root0.menus - 菜单数据
 * @param root0.filterFun - 过滤函数
 * @returns 转换后的菜单项
 */
const convertMenus = ({
  appName,
  prefix,
  menus,
  filterFun,
}: {
  appName: string
  prefix: string
  menus: Menu[]
  filterFun?: (menu: Menu) => boolean
}): MenuItemUnion[] => {
  return (filterFun ? menus.filter(filterFun) : menus).reduce(
    (result, menu) => {
      if (Menu.isMenuGroup(menu)) {
        const subMenus = convertMenus({
          appName,
          prefix,
          menus: menu.items || [],
          filterFun,
        })

        // 如果没有子菜单，不显示
        if (!subMenus.length) return result

        return [
          ...result,
          {
            name: menu.name,
            label: menu.label,
            icon: menu.icon as IconSource,
            path: `${prefix}/nocode/${appName}`,
            subMenus,
          },
        ]
      }

      if (Menu.isSubMenu(menu)) {
        const subMenus = filterFun
          ? (menu.items || []).filter(filterFun)
          : menu.items

        // 如果视图为空，不显示
        if (!subMenus?.length) {
          return result
        }

        // 这里用原始的 menu.items 判断, 如果有多个则始终显示 Tab
        const path =
          menu.items?.length && menu.items.length > 1
            ? `${prefix}/nocode/${appName}/views/${menu.name}`
            : `${prefix}/nocode/${appName}/pages/${menu.name}`

        return [
          ...result,
          {
            name: menu.name,
            label: menu.label,
            icon: menu.icon as IconSource,
            path,
          },
        ]
      }

      return result
    },
    [] as MenuItemUnion[],
  )
}

/**
 * 构建菜单项
 *
 * @param param - 参数
 * @param param.app - NoCodeApp
 * @param param.menus - 菜单列表
 * @param param.placement - 菜单位置
 * @param param.filterFun - 过滤函数
 * @returns MenuItemUnion[]
 */
export const buildMenus = ({
  app,
  menus,
  placement,
  filterFun,
}: {
  app: NoCodeApp
  menus: Menu[]
  placement: MenuPlacementEnum
  filterFun?: (menu: Menu) => boolean
}): MenuItemUnion[] => {
  const menu = menus.find(m => m.placement === placement)
  if (!menu) {
    return []
  }

  const prefix = placement === MenuPlacementEnum.Admin ? '/admin' : ''

  return convertMenus({
    appName: app.name,
    prefix,
    menus: menu.items || [],
    filterFun,
  })
}

/**
 * 生成无代码档案菜单权限
 *
 * @param tab - 无代码档案菜单
 * @returns 权限
 */
export const genNoCodeProfileTabPermission = (tab: LayoutTab) => {
  return `nocode_profile.${tab.providedBy}.${tab.name}`
}

/**
 * 生成无代码档案菜单路由
 *
 * @param param - 参数
 * @param param.tab - 无代码档案菜单
 * @param param.role - 档案菜单分类
 * @param param.pathPrefix - 路由地址前缀
 * @returns 路由
 */
export const genNoCodeProfileTabRoute = ({
  tab,
  role,
  pathPrefix,
}: {
  tab: TreeData
  role: ProfileMenuRoleEnum
  pathPrefix: string
}): RouteConfig => {
  if (!tab.parentName) {
    return {
      path: `${pathPrefix}/${tab.providedBy}`,
      getTitle: () => tab.label,
      component: lazy(() =>
        import('@/features/profiles/components/LevelRouteTabs').then(m => ({
          default: m.Level3RouteTabs,
        })),
      ),
      subRoutes: tab.children.map(child =>
        genNoCodeProfileTabRoute({
          pathPrefix,
          tab: child,
          role,
        }),
      ),
    }
  }

  const routePath = `${pathPrefix}/${tab.providedBy}/${tab.name}`
  const route: RouteConfig = {
    path: routePath,
    getTitle: () => tab.label,
    component: lazy(() =>
      import('@/features/no-code/pages/ProfilePage').then(m => ({
        default: m.ProfilePage,
      })),
    ),
  }

  if (role === ProfileMenuRoleEnum.Student) {
    const groupPermission = genNoCodeProfileTabPermission(tab)
    route.customMeta = {
      // 学生档案权限
      groupPermission,
    }
  }

  return route
}

/**
 * 将档案路由名称分解为其父名称和模块名称
 *
 * @param name - 路由名称
 * @returns 包含父名称和模块名称的对象
 */
export const decomposeProfileRouteName = (name: string) => {
  const [parentName, ...rest] = name.split('.')

  return {
    parentName,
    moduleName: rest.join('.'),
  }
}

/**
 * 档案菜单分类转 LayoutPageIdentityEnum
 *
 * @param role - 档案菜单分类
 * @returns LayoutPageIdentityEnum
 */
export const profileMenuRoleToPageIdentity = (
  role: ProfileMenuRoleEnum,
): LayoutPageIdentityEnum => {
  return role === ProfileMenuRoleEnum.Student
    ? LayoutPageIdentityEnum.StudentProfile
    : LayoutPageIdentityEnum.TeacherProfile
}

type TreeData = LayoutTab & {
  children: TreeData[]
}

/**
 * layout tabs 转 tree
 *
 * @param tabs - layout tabs
 * @param options - 选项
 * @param options.parentName - 父名称
 * @returns TreeData[]
 */
export const layoutTabsToTree = (
  tabs: LayoutTab[],
  options?: {
    parentName?: string
  },
): TreeData[] => {
  if (!tabs.length) return []

  const { parentName = null } = options || {}

  const [children, rest] = partition(tabs, a => a.parentName === parentName)

  return children.map(child => ({
    ...child,
    children: layoutTabsToTree(rest, {
      parentName: child.name,
    }),
  }))
}
