/**
 * @file 全局搜索 Store
 */

import { axios, Canceler } from '@seiue/axios'
import { pick, remove, take } from '@seiue/util'

import { searchApi$buildPermissions } from 'packages/sdks-next/chalk'

import { RootState } from '@/store'

/*
 * 由前端判断的搜索项权限
 * 有些功能的启用判断不仅靠权限或者单一接口查询，而可能是许多接口的集合判断
 * 这种判断通常在前端已有一份逻辑（比如各个接口的 menus），因此由前端自行构建判断该权限
 */
export interface GlobalSearchFePermissions {
  // 发送通知
  sendNotice: boolean

  // 使用调代课
  classAdjustment: boolean

  // 学案库题库管理
  handoutQuestionBankManagement: boolean

  // 学案库初中题库
  handoutMiddleSchoolQuestionBank: boolean

  // 学案库高中题库
  handoutHighSchoolQuestionBank: boolean
}

interface State {
  initTimestamp: number
  initing: boolean
  display: boolean
  currentFunctionTipId: string
  currentSemesterId: number
  history: {
    [rid: number]: string[]
  }

  fePermissions: GlobalSearchFePermissions
}

const state: State = {
  initTimestamp: 0,
  initing: false,
  display: false,
  currentFunctionTipId: '',
  currentSemesterId: 0,
  history: {},
  fePermissions: {
    sendNotice: false,
    classAdjustment: false,
    handoutQuestionBankManagement: false,
    handoutMiddleSchoolQuestionBank: false,
    handoutHighSchoolQuestionBank: false,
  },
}

const reducers = {
  setDisplay(prevState: State, value: boolean) {
    return {
      ...prevState,
      display: value,
    }
  },

  setInitTimestamp(prevState: State, value: number) {
    return {
      ...prevState,
      initTimestamp: value,
    }
  },

  setIniting(prevState: State, value: boolean) {
    return {
      ...prevState,
      initing: value,
    }
  },

  setCurrentFunctionTipId(prevState: State, value: string) {
    return {
      ...prevState,
      currentFunctionTipId: value,
    }
  },

  setCurrentSemesterId(prevState: State, value: string) {
    return {
      ...prevState,
      currentSemesterId: value,
    }
  },

  setSearchHistory(
    prevState: State,
    { rid, value }: { rid: number; value: string[] },
  ) {
    return {
      ...prevState,
      history: {
        ...prevState.history,
        [rid]: take(value, 10),
      },
    }
  },

  pushSearchHistory(
    prevState: State,
    { rid, value }: { rid: number; value: string },
  ) {
    const nextHistory = [...(prevState.history[rid] || [])]

    // 移除掉可能存在的相同历史
    remove(nextHistory, _h => _h === value)

    nextHistory.unshift(value)

    return {
      ...prevState,
      history: {
        ...prevState.history,
        [rid]: take(nextHistory, 10),
      },
    }
  },

  setFePermission(
    prevState: State,
    {
      permissionName,
      value,
    }: { permissionName: keyof GlobalSearchFePermissions; value: boolean },
  ) {
    return {
      ...prevState,
      fePermissions: {
        ...prevState['fePermissions'],
        [permissionName]: value,
      },
    }
  },
}

// 后端的过期时间是 15 分钟，前端在在这里提前 30 秒，以尽可能保证用户搜索时处在初始化完成的状态
export const CacheExpiredTime = 14.5 * 60 * 1000

let currentCancelToken: Canceler | undefined
let currentLoadingSemester: number | undefined

const effects = (dispatch: any) => ({
  async init(semesterId: number, { globalSearch }: RootState) {
    if (!semesterId) return

    // 如果已经在构建目标学期，那么不重复构建
    if (currentLoadingSemester === semesterId) {
      return
    }

    if (
      Date.now() - globalSearch.initTimestamp > CacheExpiredTime ||
      semesterId !== globalSearch.currentSemesterId
    ) {
      if (currentCancelToken) {
        currentCancelToken()
      }

      try {
        currentLoadingSemester = semesterId

        dispatch.globalSearch.setIniting(true)

        await searchApi$buildPermissions.api(
          semesterId,
          {},
          {
            cancelToken: new axios.CancelToken(c => {
              currentCancelToken = c
            }),
          },
        )
      } catch (e) {
        dispatch.globalSearch.setCurrentSemesterId(0)
        dispatch.globalSearch.setInitTimestamp(0)

        throw e
      } finally {
        currentLoadingSemester = undefined
        currentCancelToken = undefined

        dispatch.globalSearch.setIniting(false)
      }

      dispatch.globalSearch.setCurrentSemesterId(semesterId)
      dispatch.globalSearch.setInitTimestamp(Date.now())
    }
  },
})

export const globalSearchStore = {
  state,
  reducers,
  effects,
}

export const globalPersistTransform = {
  in: (persistState: any, key: any) => {
    if (key === 'globalSearch') {
      // 仅缓存 history
      return pick(persistState, ['history'])
    }

    return persistState
  },
  out: (val: any) => val,
}
