/**
 * @file 选科待办
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.SubjectSelectionStartNotification,
    Render: lazy(() =>
      import('./StartReport').then(m => ({ default: m.StartReport })),
    ),
  },
  {
    type: TodoTypeEnum.SubjectSelectionConfirmResult,
    Render: lazy(() =>
      import('./ConfirmResult').then(m => ({ default: m.ConfirmResult })),
    ),
  },
]
