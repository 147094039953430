import { lazy } from 'react'

import { ModuleEnum } from 'packages/feature-utils/plugins'
import { GroupPermissionTypeEnum } from 'packages/features/groups/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { getRouteSlots } from '@/plugins'

/**
 * 行政班路由配置 - 成绩
 *
 * @returns 路由配置
 */
export const getAdminClassGradeRoutes = (): RouteConfig[] => [
  {
    name: 'AdminClassGrade',
    path: 'adminClassGrade',
    getTitle: () => $t('成绩'),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.ScoreScore,
    },
    component: lazy(() =>
      import('@/features/admin-classes/pages/user/AdminClassGrade').then(m => ({
        default: m.AdminClassGrade,
      })),
    ),
    subRoutes: [
      {
        path: 'semesterGrade',
        getTitle: () => $t('学期成绩'),
        component: lazy(() =>
          import(
            '@/features/admin-classes/pages/user/AdminClassGrade/SemesterGrade'
          ).then(m => ({
            default: m.SemesterGrade,
          })),
        ),
      },
      ...getRouteSlots('adminClassGradeSubRoutes'),
      {
        path: 'assesment-item-achievement',
        getTitle: () => $t('评价项成绩'),
        component: lazy(() =>
          import(
            '@/features/admin-classes/pages/user/AdminClassGrade/AssesmentItemAchievement/index'
          ).then(m => ({
            default: m.AssesmentItemAchievement,
          })),
        ),
      },
      {
        path: 'published-exams',
        getTitle: () => $t('考试成绩'),
        component: lazy(() =>
          import('@/features/exams/pages/teacher/PublishedExams').then(m => ({
            default: m.TeacherPublishedExams,
          })),
        ),
        requireModules: [ModuleEnum.Exam],
      },
      ...getRouteSlots('adminClassGradeSubRoutesTail'),
    ],
  },
]
