/**
 * @file 注册课程班审批渲染器
 */
import React from 'react'

import { ChalkRegisterWorkflowRenderType } from '@/features/workflows/register'

export const workflowRenders: ChalkRegisterWorkflowRenderType = {
  // 退课审批，后续可能会将 bizType 扩展为数组，因为一个领域下可能会有不同的审批流业务
  bizType: 'class_members',
  render: {
    FlowCard: React.lazy(() =>
      import('./FlowCard').then(m => ({ default: m.LeaveClassFlowCard })),
    ),
    TodoCard: React.lazy(() =>
      import('./TodoCard').then(m => ({ default: m.LeaveClassTodoCard })),
    ),
    NewFlowModal: React.lazy(() =>
      import('./NewFlowModal').then(m => ({
        default: m.NewLeaveClassFlowModal,
      })),
    ),
    ShowFlowModal: React.lazy(() =>
      import('./ShowFlowModal').then(m => ({
        default: m.ShowLeaveClassFlowModal,
      })),
    ),
    WorkflowAdminTable: React.lazy(() =>
      import('./WorkflowAdminTable').then(m => ({
        default: m.LeaveClassAdminTable,
      })),
    ),
  },
}
