/**
 * @file 用户模块 - 菜单声明
 */

import { atom } from 'jotai'

import { $t } from 'packages/locale'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'
import { MenuPermissionFunc, MenuType } from 'packages/web-layout/types'

const checkPermission: MenuPermissionFunc = func =>
  func({
    permission: PermissionNameEnum.CoreUserRead,
    isManager: true,
  })

export const adminMenuProvider = atom(() => [
  {
    prefix: [],
    module: true,
    type: MenuType.Admin,
    name: 'users',
    label: $t('人员管理'),
    icon: 'User' as const,
    sort: 9,
    subMenus: [
      {
        name: 'accounts',
        icon: 'User' as const,
        shortcutIcon: 'UserSolid+477CFF',
        sort: 0,
        path: '/admin/users',
        label: $t('用户管理'),
        permission: checkPermission,
        subMenus: [
          {
            name: 'students',
            path: '/admin/users/students',
            label: $t('学生账号管理'),
          },
          {
            name: 'teachers',
            path: '/admin/users/teachers',
            label: $t('教师账号管理'),
          },
          {
            name: 'guardians',
            path: '/admin/users/guardians',
            label: $t('家长账号管理'),
          },
          {
            name: 'staffs',
            path: '/admin/users/staffs',
            label: $t('校外人员账号管理'),
          },
        ],
      },
    ],
  },
])
