/**
 * @file 首页 - 课程与群组菜单
 */

import { isNumber, isString, uniqBy } from '@seiue/util'
import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { myAdminClassesAtom } from 'packages/feature-utils/admin-classes'
import {
  getClassGroupDisplayName,
  getI18nCourseName,
} from 'packages/feature-utils/classes/class-name'
import { joinedGroupsAtom } from 'packages/feature-utils/groups'
import { selectedSemesterAtom } from 'packages/feature-utils/semesters'
import { getTranslatedTag } from 'packages/features/classes/utils/translateToEn'
import { $t, isEnLocaleAtom } from 'packages/locale'
import {
  Group,
  GroupType,
  GroupTypeSysTemplateEnum,
  Semester,
} from 'packages/sdks-next/chalk'
import { PersonalAdminClass } from 'packages/sdks-next/scms'
import { MenuItemUnion, MenuType } from 'packages/web-layout/types'

import { useNoticeSiderMenus } from '@/features/notices/menus'
import {
  useStudentProfileMenus,
  useStudentProfileMenusForGuardian,
} from '@/features/profiles/menus'
import { useTeacherProfilesMenu } from '@/features/teacher-profiles/menu'

import {
  classOrder,
  formatGroupForCompare,
  getGradeNumsLevel,
  getGradePriority,
} from './utils'

/**
 * 首页菜单
 */
export const homeMenuProvider = loadable(
  atom(async get => {
    const myAdminClasses = get(myAdminClassesAtom)
    const selectedSemester = get(selectedSemesterAtom)
    const [groups, cgTypes] = get(joinedGroupsAtom)

    const classGroups = groups.filter(
      (group: Group) => group.type === 'seiue.class_group',
    )

    const otherGroups = groups.filter(
      (group: Group) =>
        group.type !== 'seiue.class_group' && group.type !== 'seiue.dorm_group',
    )

    const isEn = get(isEnLocaleAtom)

    const menus = []

    if (classGroups.length && selectedSemester) {
      menus.push(classGroupsToMenu(selectedSemester, classGroups, isEn))
    }

    // 确保群组菜单生成，其他地方才能注入子菜单
    menus.push(
      otherGroupsToMenu({
        groups: otherGroups,
        groupTypes: cgTypes,
        adminClasses: myAdminClasses,
        isEn,
      }),
    )

    return menus
  }),
)

const otherGroupsToMenu = ({
  groups,
  groupTypes,
  adminClasses,
  isEn,
}: {
  groups: Group[]
  groupTypes: GroupType[]
  adminClasses: PersonalAdminClass[]
  isEn: boolean
}) => {
  const adminClassGroups = groups.filter(
    group => group.type === 'seiue.adminclass_group',
  )

  const courseGroups = groups.filter(
    (group: Group) => group.type === 'seiue.course_group',
  )

  const subMenus: MenuItemUnion[] = adminClassGroups.map(group => ({
    path: `/admin-classes/${group.bizId}`,
    label:
      adminClasses.find(adminClass => adminClass.id === +group.bizId)
        ?.fullName || '',
    sort: 21,
    icon: 'AdministrativeClass',
  }))

  if (courseGroups.length) {
    const innerCourseGroups = Object.values(uniqBy(courseGroups, 'bizId'))

    subMenus.push({
      label: $t('课程库'),
      icon: 'CourseLibrary' as const,
      path: `/courses`,
      subMenus: innerCourseGroups.map(courseGroup => ({
        path: `/courses/${courseGroup.bizId}`,
        label:
          getI18nCourseName(courseGroup.extraFields, isEn) || courseGroup.name,
      })),
      type: MenuType.Default,
      sort: 23,
    })
  }

  groupTypes.forEach(groupType => {
    const groupsInType = groups.filter(
      group => group.scope === `custom_group.${groupType.machineName}`,
    )

    if (!groupsInType.length) {
      return
    }

    const isMentorGroup =
      groupType.sysTemplate === GroupTypeSysTemplateEnum.Mentor

    subMenus.push({
      label: groupType.name,
      icon: isMentorGroup ? ('Tutor' as const) : ('GroupUsers' as const),
      subMenus: groupsInType.map(cgGroup => ({
        path: `/plugin/customized-group/${groupType.id}/groups/${cgGroup.id}`,
        label: cgGroup.name,
      })),
      type: MenuType.Default,
      sort: 24,
    })
  })

  return {
    prefix: [],
    name: 'groups',
    label: $t('群组'),
    icon: 'GroupUsers' as const,
    sort: 2,
    flat: true,
    subMenus,
    type: MenuType.Default,
  }
}

// eslint-disable-next-line seiue/missing-formatted-message
const courseName = '课程'

const isClassesOngoing = (semester: Semester, groups: Group[]) =>
  groups.some(group => {
    if (!semester.isCurrent) {
      return false
    }

    const currentWeek = semester.currentWeek || 1 // 默认第 1 周，方便提前看课程
    const startWeek = group.extraFields?.startWeek || 0
    const endWeek = group.extraFields?.endWeek || 0

    return currentWeek >= startWeek && currentWeek <= endWeek
  })

/**
 * 对课程班群组进行排序
 *
 * 排序规则如下：
 * 1. 按照班级分组的开始周和结束周排序，即开始周早的排在前面，开始周相同的按照结束周早的排在前面。
 * 2. 如果两个班级分组的开始周和结束周相同，则按照预定义的科目顺序进行排序。如果两个班级分组的科目都不在预定义的顺序中，则将它们排在最后面。
 *
 * @param groups - 课程班群组
 * @returns 排序后的班级分组数组
 */
const sortClassGroups = (groups: Group[]) => {
  // 确定科目顺序
  const subjectsInOrder = [
    // 无需国际化
    /* eslint-disable seiue/missing-formatted-message */
    '语文',
    '数学',
    '英语',
    '物理',
    '化学',
    '生物',
    '历史',
    '地理',
    '思想政治',
    '政治',
    '体育',
    '技术',
    '艺术',
    /* eslint-enable seiue/missing-formatted-message */
  ]

  // 对班级分组进行排序
  groups.sort((a, b) => {
    const formattedGroupA = formatGroupForCompare(a)
    const formattedGroupB = formatGroupForCompare(b)

    // 获取分组的开始周、结束周和科目名称
    const startWeek = a.extraFields?.startWeek || 0
    const startWeekEnd = b.extraFields?.startWeek || 0
    const endWeek = a.extraFields?.endWeek || 0
    const endWeekEnd = b.extraFields?.endWeek || 0

    // 1.按照开始周和结束周排序
    if (startWeek !== startWeekEnd) {
      return startWeek - startWeekEnd
    }

    if (endWeek !== endWeekEnd) {
      return endWeek - endWeekEnd
    }

    // 2.课程名称比较
    if (formattedGroupA.courseFlag !== formattedGroupB.courseFlag) {
      return formattedGroupA.courseFlag.localeCompare(
        formattedGroupB.courseFlag,
      )
    }

    // 3.按照年级比较
    const gradeA = getGradePriority(formattedGroupA.gradeFlag)
    const gradeB = getGradePriority(formattedGroupB.gradeFlag)

    if (gradeA !== gradeB) return gradeA - gradeB

    // 4.年级类型相同，则按照数字 1-9A-Z 排序
    const gradeLevelA = getGradeNumsLevel(formattedGroupA.gradeFlag)
    const gradeLevelB = getGradeNumsLevel(formattedGroupB.gradeFlag)

    if (
      isNumber(gradeLevelA) &&
      isNumber(gradeLevelB) &&
      gradeLevelA !== gradeLevelB
    ) {
      return gradeLevelA - gradeLevelB
    }

    // 5.按照科目顺序排序
    if (endWeek === endWeekEnd) {
      let subjectOrder1 = subjectsInOrder.indexOf(
        a.extraFields?.subjectName || '',
      )

      let subjectOrder2 = subjectsInOrder.indexOf(
        b.extraFields?.subjectName || '',
      )

      // 5.1.若分组的科目不在规定的顺序中，则将其排在最后面
      subjectOrder1 = subjectOrder1 >= 0 ? subjectOrder1 : 100
      subjectOrder2 = subjectOrder2 >= 0 ? subjectOrder2 : 100

      if (subjectOrder1 !== subjectOrder2) {
        return subjectOrder1 - subjectOrder2
      }
    }

    // 6.班级比较
    const classA = classOrder(formattedGroupA.classFlag)
    const classB = classOrder(formattedGroupB.classFlag)

    if (classA.type !== classB.type) {
      return classA.type === 'number' ? -1 : 1
    }

    if (classA.value !== classB.value) {
      if (isNumber(classA.value) && isNumber(classB.value)) {
        return classA.value - classB.value
      }

      if (isString(classA.value) && isString(classB.value)) {
        return classA.value.localeCompare(classB.value)
      }
    }

    return 1
  })

  // 返回排序后的班级分组数组
  return groups
}

const classGroupsToMenu = (
  semester: Semester,
  groups: Group[],
  isEn: boolean,
) => {
  const groupsByTag = new Map<string, Group[]>()
  groups.forEach(group => {
    const tagName = group.extraFields?.tag || courseName
    if (!groupsByTag.has(tagName)) {
      groupsByTag.set(tagName, [])
    }

    groupsByTag.get(tagName)?.push(group)
  })

  const normalGroups = groupsByTag.get(courseName) || []

  const sortedClassGroups = sortClassGroups(normalGroups)

  let subMenus: MenuItemUnion[] = sortedClassGroups.map(group => {
    const ongoing = isClassesOngoing(semester, [group])
    return {
      path: `/classes/${group.bizId}`,
      hidden: !ongoing,
      label: getClassGroupDisplayName(group, isEn) || group.name,
      iconProps: {
        tooltipTitle: ongoing ? $t('进行中课程') : $t('未进行课程'),
      },
      icon: ongoing ? 'CourseInProgress' : 'CourseNotStarted',
    }
  })

  let classCount = subMenus.length

  subMenus.sort((a, b) => {
    if (a.hidden === b.hidden) {
      return 0
    }

    if (!a.hidden && b.hidden) {
      return -1
    }

    return 1
  })

  groupsByTag.delete(courseName)

  const taggedGroups: { tagName: string; groups: Group[] }[] = []
  groupsByTag.forEach((groupsInTag, tagName) => {
    taggedGroups.push({ tagName, groups: sortClassGroups(groupsInTag) })
  })

  taggedGroups.sort((a, b) => {
    const weight1 = isClassesOngoing(semester, a.groups) ? 0 : 1
    const weight2 = isClassesOngoing(semester, b.groups) ? 0 : 1

    if (weight1 < weight2) {
      return -1
    }

    if (weight1 === weight2) {
      return 0
    }

    return 1
  })

  taggedGroups.forEach(({ tagName, groups: groupsInTag }) => {
    const tagMenu = {
      label: getTranslatedTag(tagName),
      icon: 'CourseLibrary' as const,
      // iconProps: tag === getActivityClassTag() ? {size: 18} : {},
      subMenus: groupsInTag.map(group => ({
        path: `/classes/${group.bizId}`,
        label: getClassGroupDisplayName(group, isEn) || group.name,
      })),
      hidden: !isClassesOngoing(semester, groupsInTag),
      type: MenuType.Default,
    }

    subMenus.push(tagMenu)
    classCount += groupsInTag.length
  })

  if (subMenus.every(menu => menu.hidden)) {
    subMenus = subMenus.map((subMenu, index) => ({
      ...subMenu,
      hidden: index >= 8,
    }))
  }

  if (!normalGroups.length) {
    // 如果都是分组，则无需隐藏
    subMenus = subMenus.map(subMenu => ({
      ...subMenu,
      hidden: false,
    }))
  }

  return {
    prefix: [],
    name: 'classes',
    label: classCount
      ? $t('课程（{count}）', { count: classCount })
      : $t('课程'),
    flat: true,
    icon: 'Course' as const,
    sort: 1,
    subMenus,
    type: MenuType.Default,
  }
}

/**
 * 侧边栏头部的菜单定义
 *
 * @returns 菜单定义
 */
export const useSiderHeaderMenus = () => [
  {
    path: '/',
    icon: 'Home' as const,
    label: $t('首页'),
    height: 40,
    type: MenuType.Default,
  },
  ...useNoticeSiderMenus(),
  ...useTeacherProfilesMenu(),
  ...useStudentProfileMenus(),
  ...useStudentProfileMenusForGuardian(),
]
