import { InitFeatureParams } from '@/features/types'

import { adminSystemSettingMenu } from './menus'
import { getRoutes } from './routes'

/**
 * 初始化系统管理
 *
 * @param params - init feature params
 */
export const initSystemSettings = (params: InitFeatureParams) => {
  const { addRoutes, addMenuProvider } = params

  addRoutes(getRoutes())
  addMenuProvider(adminSystemSettingMenu)
}
