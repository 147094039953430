/**
 * @file 行政班菜单组
 */

import { compact } from '@seiue/util'
import { atom } from 'jotai'

import {
  hasEnabledPluginAtom,
  ModuleEnum,
} from 'packages/feature-utils/plugins'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const adminAdminClassMenuProvider = atom(get => {
  if (!get(hasEnabledPluginAtom(ModuleEnum.AdminClass))) {
    return []
  }

  const enableAttendance = get(
    hasEnabledPluginAtom(ModuleEnum.AdminClassAttendance),
  )

  return [
    {
      prefix: [],
      module: true,
      type: MenuType.Admin,
      name: 'admin-class',
      label: $t('行政班管理'),
      icon: 'AdministrativeClass' as const,
      sort: 10,
      subMenus: compact([
        {
          name: 'admin-class',
          path: '/admin/admin-classes',
          plugin: SchoolModulesEnum.AdminClass,
          icon: 'AdministrativeClass' as const,
          shortcutIcon: 'AdministrativeClassSolid+E99D0A',
          permission: [PermissionNameEnum.CoreAdminClassRead],
          label: $t('行政班管理'),
          sort: 0,
        },
        enableAttendance
          ? {
              name: 'admin-class-attendance',
              path: '/admin/admin-classes/attendances',
              plugin: SchoolModulesEnum.AdminClass,
              icon: 'CheckWorkAttendance' as const,
              shortcutIcon: 'CheckWorkAttendance+E99D0A',
              permission: [PermissionNameEnum.CoreAdminClassRead],
              label: $t('行政班考勤管理'),
              sort: 1,
            }
          : undefined,
      ]),
    },
  ]
})
