/**
 * @file 无代码应用 menus
 */

import { atom } from 'jotai'

import {
  Evaluator,
  type PermissionChecker,
} from 'packages/expression/evaluator'
import {
  EnabledPlugin,
  getPluginIcon,
  usablePluginsAtom,
  manageablePluginsAtom,
  isPluginForPlatform,
} from 'packages/feature-utils/plugins'
import type { NoCodeApp } from 'packages/no-code/basic/app'
import { currentAppAtom } from 'packages/no-code/basic/atoms'
import { getPermissionChecker } from 'packages/no-code/basic/utils/permission'
import { $ct, $t } from 'packages/locale'
import {
  PluginSourceEnum,
  Reflection,
  RoleEnum,
} from 'packages/sdks-next/chalk'
import { Menu, MenuPlacementEnum } from 'packages/sdks-next/nuwa'
import { MenuItemUnion, MenuType } from 'packages/web-layout/types'

import { getStoreForAtom } from '@/store'

import { buildMenus } from './utils/utils'

const isNoCodeAppPlugin = (plugin: EnabledPlugin) => {
  return !plugin.parentId && plugin.plugin.source === PluginSourceEnum.NoCode
}

/**
 * 获取插件入口地址
 *
 * @param plugin - 插件
 * @param isAdmin - 是否为后台地址
 * @returns 入口地址
 */
const getEntryPath = (plugin: EnabledPlugin, isAdmin?: boolean) => {
  const { pluginEntryUrls, pluginSettingUrl } = plugin
  if (isAdmin) {
    return pluginSettingUrl || `/admin/nocode/${plugin.pluginName}`
  }

  return (pluginEntryUrls as any)?.web || `/nocode/${plugin.pluginName}`
}

const getSubMenus = ({
  app,
  p,
  placement,
  me,
  hasPermission,
}: {
  app?: NoCodeApp
  p: EnabledPlugin
  placement: MenuPlacementEnum
  hasPermission: PermissionChecker
  me?: Reflection
}) => {
  if (!app || app.versionId !== p.versionId || !me) {
    return []
  }

  const executor = new Evaluator(hasPermission)

  const filterFun = (menu: Menu) => {
    if (!menu.visible) return true
    return !!executor.evaluate(menu.visible, {
      me,
    })
  }

  return buildMenus({
    app,
    menus: app.menus,
    placement,
    filterFun,
  })
}

export const nocodeAppMenu = atom(get => {
  const menus = [] as MenuItemUnion[]

  const currentApp = get(currentAppAtom)
  const store = getStoreForAtom(get)
  const me = store.session.currentReflection

  const manageableApps = get(manageablePluginsAtom).filter(
    plugin => isNoCodeAppPlugin(plugin) && isPluginForPlatform(plugin, 'web'),
  )

  const usableApps = get(usablePluginsAtom).filter(
    plugin => isNoCodeAppPlugin(plugin) && isPluginForPlatform(plugin, 'web'),
  )

  const getSubMenusDefaultParams = {
    app: currentApp,
    me,
  }

  menus.push(
    ...manageableApps.map(p => ({
      label: $t('{name}管理', { name: $ct(p.pluginLabel, 'plugin') }),
      name: p.pluginName,
      prefix: [p.pluginCategory],
      type: MenuType.AdminApps,
      sort: 901,
      shortcutIcon: p ? getPluginIcon(p) : 'Other+477cff',
      path: getEntryPath(p, true),
      lazy: true,
      subMenus: getSubMenus({
        ...getSubMenusDefaultParams,
        p,
        placement: MenuPlacementEnum.Admin,
        hasPermission: getPermissionChecker(
          p.pluginName,
          store.session.currentPermissions,
          get(manageablePluginsAtom),
          me?.role === RoleEnum.Shadow,
        ),
      }),
    })),
  )

  menus.push(
    ...usableApps.map(p => ({
      label: p.pluginLabel,
      name: p.pluginName,
      type: MenuType.Apps,
      shortcutIcon: p ? getPluginIcon(p) : 'Other+477cff',
      path: getEntryPath(p),
      lazy: true,
      subMenus: getSubMenus({
        ...getSubMenusDefaultParams,
        p,
        placement: MenuPlacementEnum.App,
        hasPermission: getPermissionChecker(
          p.pluginName,
          store.session.currentPermissions,
          get(manageablePluginsAtom),
          me?.role === RoleEnum.Shadow,
        ),
      }),
    })),
  )

  return menus
})
