import { lazy } from 'react'

import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/evaluations',
    name: 'AdminEvaluations',
    getTitle: () => $t('评教评学'),
    module: [PluginCategoryEnum.教师发展, SchoolModulesEnum.Evaluation],
    requirePermissions: [PermissionNameEnum.CoreEvaluationWrite],
    component: lazy(() =>
      import('./pages/admin/List').then(m => ({ default: m.AdminEvaluations })),
    ),
  },
  {
    name: 'EvaluationView',
    path: 'admin/evaluations/:id/view',
    getTitle: () => $t('评教详情'),
    component: lazy(() =>
      import('./pages/admin/View').then(m => ({
        default: m.AdminEvaluationView,
      })),
    ),
    subRoutes: [
      {
        name: 'EvaluationDetails',
        path: 'details',
        getTitle: () => $t('评教详情'),
        requirePermissions: [PermissionNameEnum.CoreEvaluationWrite],
        component: lazy(() =>
          import('./pages/admin/Details').then(m => ({
            default: m.AdminEvaluationsDetails,
          })),
        ),
      },
      {
        name: 'ByScore',
        path: 'statistics/by-score',
        getTitle: () => $t('分数统计结果'),
        requirePermissions: [PermissionNameEnum.CoreEvaluationWrite],
        component: lazy(() =>
          import('./pages/admin/Statistics/StatisticsByScore').then(m => ({
            default: m.EvaluationsStatisticsByScore,
          })),
        ),
      },
      {
        name: 'EvaluationStatisticsByQuestions',
        path: 'statistics/by-questions',
        getTitle: () => $t('分题统计结果'),
        requirePermissions: [PermissionNameEnum.CoreEvaluationWrite],
        component: lazy(() =>
          import('./pages/admin/Statistics/StatisticsByQuestions').then(m => ({
            default: m.EvaluationsStatisticsByQuestions,
          })),
        ),
      },
      {
        path: 'setting',
        getTitle: () => $t('评教设置'),
        requirePermissions: [PermissionNameEnum.CoreEvaluationWrite],
        component: lazy(() =>
          import('./pages/admin/Setting').then(m => ({
            default: m.AdminEvaluationsSetting,
          })),
        ),
      },
    ],
  },
]

const teacherRoutes: RouteConfig[] = [
  {
    name: 'EvaluationResults',
    path: 'teacher/evaluations/:id/results',
    getTitle: () => $t('评教结果'),
    component: lazy(() =>
      import('./pages/teacher/Results').then(m => ({
        default: m.EvaluationResults,
      })),
    ),
  },
]

export const routes = [...adminRoutes, ...teacherRoutes]

export const modalRoutes = [
  {
    name: 'EditEvaluationModal',
    getTitle: () => $t('编辑评教'),
    component: lazy(() =>
      import('./pages/admin/Edit/index').then(m => ({
        default: m.EditEvaluationModal,
      })),
    ),
  },
  {
    name: 'SubmitEvaluationModal',
    getTitle: () => $t('提交评教'),
    component: lazy(() =>
      import('./pages/student/pages/SubmitRouteModal').then(m => ({
        default: m.SubmitEvaluationRouteModal,
      })),
    ),
  },
  {
    name: 'EvaluationUncompletedModal',
    getTitle: () => $t('未评教名单'),
    component: lazy(() =>
      import('./pages/teacher/UncompletedStudents').then(m => ({
        default: m.UncompletedStudents,
      })),
    ),
  },
]
