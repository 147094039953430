import { useState, FC, lazy, Suspense } from 'react'

import { useForceResetInitPassword } from 'packages/features/reflections/utils'
import { useIsNoLogged } from 'packages/features/sessions/hooks'
import { useCurrentReflection } from 'packages/features/sessions/utils'

import { useSelector } from '@/store'
import { RootInterrupt } from '@/uikit'

const ResetInitPasswordModalOrigin = lazy(() =>
  import('./Component').then(m => ({ default: m.ResetInitPasswordModal })),
)

/**
 * 重设初始化密码弹窗
 *
 * @returns 组件
 */
export const ResetInitPasswordModal: FC = () => {
  const { passwordChangeNeeded, role } = useCurrentReflection()

  const isInitPassword = passwordChangeNeeded

  const skipResetPassword = useSelector(
    state => state.session.skipResetPassword,
  )

  const isLogout = useIsNoLogged()

  const { data: forceResetInit } = useForceResetInitPassword({ role })

  const [resetSucceed, setResetSucceed] = useState(false)

  const done =
    (!isInitPassword && !resetSucceed) ||
    (!forceResetInit && skipResetPassword) ||
    isLogout

  const canShow = RootInterrupt.useCanShow('resetInitPassword', done)

  if (!canShow) return null

  return (
    <Suspense fallback={null}>
      <ResetInitPasswordModalOrigin
        resetSucceed={resetSucceed}
        setResetSucceed={setResetSucceed}
      />
    </Suspense>
  )
}
