import { InitFeatureParams } from '../types'

import { adminSpaceMenu } from './menus'
import { routes, modalRoutes } from './routes'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initPlaces = ({
  addRoutes,
  addMenuProvider,
}: InitFeatureParams) => {
  addRoutes(routes, modalRoutes)
  addMenuProvider(adminSpaceMenu)
}
