/**
 * @file logo 的数据存储，由于配置时可能会需要实时展示修改的内容，所以需要存一个 store 来进行跨组件通信
 */
import type { LogoInfo as LogoInfoBase } from 'packages/features/system-settings/types'

import { DEFAULT_LOGO_HEIGHT } from './type'

export type LogoInfo = LogoInfoBase

type LogoInfoState = {
  // 是否开启实时预览
  preview?: boolean
  logoInfo?: LogoInfo
}

const state: LogoInfoState = {
  preview: false,
  logoInfo: {
    name: '',
    ename: '',
    logo: '',
    foldedLogo: '',
    logoHeight: DEFAULT_LOGO_HEIGHT,
    foldedLogoHeight: DEFAULT_LOGO_HEIGHT,
    loginLogo: '',
    isDiffLogo: true,
    isDiffLoginLogo: true,
    schoolName: '',
    schoolEName: '',
    showNavSystemName: false,
    showNavSchoolName: false,
  },
}

const initStateData = { ...state }

const reducers = {
  setSystemInfo(prevState: LogoInfoState, obj: Partial<LogoInfoState>) {
    const { preview, logoInfo } = prevState
    return {
      preview: obj.preview === undefined ? preview : obj.preview,
      logoInfo: { ...logoInfo, ...obj.logoInfo },
    }
  },
  resetState() {
    return { ...initStateData }
  },
}

export const systemSettings = {
  state,
  reducers,
}
