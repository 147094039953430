import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.GoalNoRecordRemind,
    Render: lazy(() =>
      import('./GoalTodoCard').then(m => ({ default: m.GoalTodoCard })),
    ),
  },
]
