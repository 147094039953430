import { InitFeatureParams } from '../types'

import { routes, modalRoutes } from './routes'
import { todos } from './todos'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initGoals = ({ addRoutes, registerTodos }: InitFeatureParams) => {
  registerTodos(todos)
  addRoutes(routes, modalRoutes)
}
