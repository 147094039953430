/**
 * @file chalk 审批渲染器注册
 */
import React from 'react'

import {
  getWorkflowRender,
  registerWorkflowRenders,
  RegisterWorkflowRenderType,
  WorkflowRender,
} from 'packages/features/workflows/register'
import { WorkflowBizType } from 'packages/features/workflows/types'

import { WorkflowAdminTable } from '@/features/workflows/types'

type ChalkWorkflowRender = WorkflowRender<{
  WorkflowAdminTable?: React.LazyExoticComponent<WorkflowAdminTable>
}>

export type ChalkRegisterWorkflowRenderType =
  RegisterWorkflowRenderType<ChalkWorkflowRender>

/**
 * 注册 chalk 审批渲染器
 */
export const registerChalkWorkflowRenders = (
  renders: ChalkRegisterWorkflowRenderType | ChalkRegisterWorkflowRenderType[],
) => registerWorkflowRenders(renders)

/**
 * 获取 chalk 审批渲染器
 */
export const getChalkWorkflowRender = (
  bizType: WorkflowBizType,
): ChalkWorkflowRender | undefined => getWorkflowRender(bizType)
