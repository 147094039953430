import { lazy } from 'react'

import { registerTodos } from 'packages/features/todos/register'
import { $t } from 'packages/locale'
import { PLUGIN_ACADEMIC_ACHIEVEMENT } from 'packages/no-code/apps/academic-achievement/types/types'
import { PLUGIN_ACADEMIC_CREDIT } from 'packages/no-code/apps/gz-academic-credit/types/app'

import { gzAcademicCreditTodos } from '@/features/no-code/apps/gz-academic-credit/todo'
import { szHomeworkSurveyTodos } from '@/features/no-code/apps/sz-homework-survey/todo'
import { InitFeatureParams } from '@/features/types'
import { registerToSlot } from '@/plugins'

import { nocodeAppMenu } from './menus'
import { modalRoutes, routes } from './routes'
import { workflowRenders } from './todos'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initNoCode = ({
  addRoutes,
  addMenuProvider,
  registerWorkflowRenders,
  hasEnabledPlugin,
}: InitFeatureParams) => {
  addRoutes(routes, modalRoutes)
  registerTodos([...gzAcademicCreditTodos, ...szHomeworkSurveyTodos])

  addMenuProvider(nocodeAppMenu)
  registerWorkflowRenders?.(workflowRenders)

  registerToSlot('profileModulePermissions', () =>
    import('./slots/permission').then(m => m.getProfileModulePermissionsSlots),
  )

  if (
    hasEnabledPlugin?.(
      plugin => plugin.pluginName === PLUGIN_ACADEMIC_ACHIEVEMENT,
    )
  ) {
    registerToSlot('teacherProfileSubRoutes', [
      {
        path: 'achievement',
        getTitle: () => $t('学术成果'),
        component: lazy(() =>
          import('@/features/no-code/slots/AchievementPage').then(m => ({
            default: m.Page,
          })),
        ),
      },
    ])
  }

  if (
    hasEnabledPlugin?.(plugin => plugin.pluginName === PLUGIN_ACADEMIC_CREDIT)
  ) {
    registerToSlot('teacherProfilesEvaluationSubRoutes', [
      {
        path: 'credit-summary-table',
        getTitle: () => $t('赋分'),
        component: lazy(() =>
          import('@/features/no-code/slots/ProfileTable').then(m => ({
            default: m.Page,
          })),
        ),
      },
    ])
  }
}
