/**
 * @file 首页搜索框
 */

import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { Icon } from '@seiue/ui'
import { FC } from 'react'
import styled from 'styled-components'

import { $t } from 'packages/locale'

import { useGlobalSearch } from '../hooks'
import {
  formatShortcut,
  getShortcutLabel,
  useKeyPress,
} from '../utils/shortcut'

const searchShortcut = 'meta.shift.f'

/**
 * 首页搜索框
 *
 * @returns 首页搜索框
 */
export const HomeSearcher: FC = () => {
  const { open, enabled } = useGlobalSearch()

  useKeyPress(searchShortcut, () => {
    open()
  })

  if (!enabled) return null

  return (
    <Wrapper onClick={open}>
      <Icon icon={faSearch} color="rgba(255, 255, 255, 0.5)" />
      <StyledInput>
        {$t('搜索（{shortcut}）', {
          shortcut: getShortcutLabel(formatShortcut(searchShortcut)),
        })}
      </StyledInput>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 320px;
  height: 32px;
  cursor: pointer;
  border-radius: 100px;
  padding: 0 16px;
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.5);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

const StyledInput = styled.div`
  margin-left: 8px;
`
