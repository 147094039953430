import { lazy } from 'react'

import { ModuleEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'

export const userModalRoutes = [
  {
    name: 'GradesModal.AfterthoughtsEditLogs',
    requireModules: [ModuleEnum.ClassAssessment],
    getTitle: () => $t('编辑记录'),
    component: lazy(() =>
      import(
        '@/features/grades/components/grade-afterthoughts/EditLogsModal'
      ).then(m => ({
        default: m.EditLogsModal,
      })),
    ),
  },
  {
    name: 'GradesModal.AssessmentSelf',
    requireModules: [ModuleEnum.ClassAssessment],
    getTitle: () => $t('填写自我评价'),
    component: lazy(() =>
      import('@/features/grades/components/AssessmentSelf/Modal').then(m => ({
        default: m.AssessmentSelfModal,
      })),
    ),
  },
]
