import { atom } from 'jotai'

import {
  hasEnabledPluginAtom,
  ModuleEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const adminGradeMenu = atom(get => {
  if (!get(hasEnabledPluginAtom(ModuleEnum.ClassAssessment))) {
    return []
  }

  return [
    {
      prefix: ['courses'],
      label: $t('成绩管理'),
      name: 'grades',
      sort: 204,
      icon: 'Performance' as const,
      shortcutIcon: 'PerformanceSolid+17A0DE',
      permission: [PermissionNameEnum.CoreGradeRead],
      path: '/admin/grades',
    },
  ]
})
