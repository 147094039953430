import { lazy } from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/venues',
    name: 'AdminVenues',
    module: [PluginCategoryEnum.后勤管理, SchoolPluginsEnum.Venue],
    getTitle: () => $t('场地预约管理'),
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'list',
        name: 'List',
        getTitle: () => $t('场地列表'),
        component: lazy(() =>
          import('./pages/admin/List').then(m => ({
            default: m.AdminVenueList,
          })),
        ),
      },
      {
        path: 'orders',
        getTitle: () => $t('预约记录'),
        component: lazy(() =>
          import('./pages/admin/Orders').then(m => ({
            default: m.AdminVenueOrders,
          })),
        ),
      },
      {
        path: 'violation-stats',
        getTitle: () => $t('违规统计'),
        component: lazy(() =>
          import('./pages/admin/ViolationStats/List').then(m => ({
            default: m.AdminVenueViolationStats,
          })),
        ),
      },
      {
        path: 'settings',
        name: 'Settings',
        getTitle: () => $t('设置'),
        component: lazy(() =>
          import('./pages/admin/Settings').then(m => ({
            default: m.AdminVenueSettings,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/venues/violation-stats/:id/details',
    name: 'AdminVenueViolationStatsDetails',
    getTitle: () => $t('违规详情'),
    component: lazy(() =>
      import('./pages/admin/ViolationStats/Details').then(m => ({
        default: m.AdminVenueViolationStatsDetails,
      })),
    ),
  },
  {
    path: 'admin/venues/new',
    name: 'AdminVenueNew',
    getTitle: () => $t('新建场地'),
    component: lazy(() =>
      import('./pages/admin/Edit').then(m => ({ default: m.AdminVenueNew })),
    ),
  },
  {
    path: 'admin/venues/:id/edit',
    name: 'AdminVenueEdit',
    getTitle: () => $t('编辑场地'),
    component: lazy(() =>
      import('./pages/admin/Edit').then(m => ({ default: m.AdminVenueEdit })),
    ),
  },
  {
    path: 'admin/venues/:id/view',
    name: 'AdminVenueView',
    getTitle: () => $t('场地详情'),
    component: lazy(() =>
      import('./pages/admin/View').then(m => ({ default: m.AdminVenueView })),
    ),
  },
  {
    path: 'admin/venues/batch-edit',
    name: 'AdminVenuesBatchEdit',
    getTitle: () => $t('批量编辑场地'),
    component: lazy(() =>
      import('./pages/admin/Edit/BatchEdit').then(m => ({
        default: m.AdminVenuesBatchEdit,
      })),
    ),
  },
]

const userRoutes: RouteConfig[] = [
  {
    path: 'venues/management/:id/details',
    name: 'VenueDetails',
    getTitle: () => $t('场地详情'),
    component: lazy(() =>
      import('./pages/user/Management/VenueDetails').then(m => ({
        default: m.VenueDetails,
      })),
    ),
  },
  {
    path: 'venues/management/:id/details/approvals',
    name: 'VenueDetails.AllApprovalList',
    getTitle: () => $t('全部审批'),
    component: lazy(() =>
      import('./pages/user/Management/VenueApprovalList').then(m => ({
        default: m.VenueApprovalList,
      })),
    ),
  },
  {
    path: 'venues/management/:id/edit',
    name: 'VenueEdit',
    getTitle: () => $t('场地编辑'),
    component: lazy(() =>
      import('./pages/user/Management/VenueEdit').then(m => ({
        default: m.VenueEdit,
      })),
    ),
  },
  {
    path: 'venues',
    getTitle: () => $t('场地预约'),
    module: [SchoolPluginsEnum.Venue],
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'venue-list',
        name: 'VenueList',
        getTitle: () => $t('场地列表'),
        component: lazy(() =>
          import('./pages/user/Order/VenueList').then(m => ({
            default: m.VenueList,
          })),
        ),
      },
      {
        path: 'my-orders',
        name: 'MyOrders',
        getTitle: () => $t('我的预约'),
        component: lazy(() =>
          import('./pages/user/Order/MyOrders').then(m => ({
            default: m.MyOrders,
          })),
        ),
      },
      {
        path: 'managed',
        name: 'Managed',
        getTitle: () => $t('我管理的'),
        component: lazy(() =>
          import('./pages/user/Management/VenueList').then(m => ({
            default: m.VenueList,
          })),
        ),
      },
      {
        path: 'my-approvals',
        name: 'ApprovalList',
        getTitle: () => $t('预约审批'),
        component: lazy(() =>
          import('./pages/user/Management/ApprovalList').then(m => ({
            default: m.ApprovalList,
          })),
        ),
      },
    ],
  },
  {
    path: 'venues/order/violation-stats-details',
    name: 'VenueViolationStatsDetails',
    getTitle: () => $t('违规详情'),
    component: lazy(() =>
      import('./pages/user/Order/ViolationStatsDetails').then(m => ({
        default: m.VenueViolationStatsDetails,
      })),
    ),
  },
]

export const routes: RouteConfig[] = [...adminRoutes, ...userRoutes]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'ShowVenueOrderModal',
    getTitle: () => $t('预约记录详情'),
    component: lazy(() =>
      import('./pages/user/Order/Show').then(m => ({
        default: m.ShowVenueOrderModal,
      })),
    ),
  },
  {
    name: 'ConfigVenueOrderFormModal',
    getTitle: () => $t('配置场地预约'),
    component: lazy(() =>
      import('./pages/admin/ConfigOrderForm').then(m => ({
        default: m.ConfigVenueOrderFormModal,
      })),
    ),
  },
  {
    name: 'ApplyVenueOrderModal',
    getTitle: () => $t('场地预约申请'),
    component: lazy(() =>
      import('./pages/user/Order/Apply').then(m => ({
        default: m.ApplyVenueOrderModal,
      })),
    ),
  },
]
