/**
 * @file 插件商店 menu
 */
import { atom } from 'jotai'

import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

export const adminPluginMenu = atom(() => [
  {
    name: 'plugin',
    label: $t('插件管理'),
    icon: 'PlugInUnit' as const,
    sort: 70,
    permission: [PermissionNameEnum.CorePluginRead],
    type: MenuType.Admin,
    subMenus: [
      {
        label: $t('应用管理'),
        path: '/admin/plugins/applications',
        icon: 'Application' as const,
        shortcutIcon: 'ApplicationSolid+477CFF',
        name: 'plugin-applications',
      },
      {
        label: $t('扩展管理'),
        path: '/admin/plugins/extensions',
        icon: 'Extend' as const,
        shortcutIcon: 'ExtendSolid+15B8B1',
        name: 'plugin-extensions',
      },
      {
        label: $t('操作日志'),
        path: '/admin/plugins/logs',
        icon: 'Log' as const,
        shortcut: false,
      },
    ],
  },
])

export const pluginAppRootMenu = atom(() => [
  {
    name: PluginCategoryEnum.教务教学,
    label: $t('教务教学'),
    sort: 10,
    type: MenuType.AdminApps,
    icon: 'Report' as const, // TODO 不是这个图标
    subMenus: [],
  },
  {
    name: PluginCategoryEnum.通用工具,
    label: $t('通用工具'),
    sort: 11,
    type: MenuType.AdminApps,
    icon: 'GeneralTools' as const,
    subMenus: [],
  },
  {
    name: PluginCategoryEnum.学生成长,
    label: $t('学生成长'),
    sort: 12,
    type: MenuType.AdminApps,
    icon: 'Student' as const,
    subMenus: [],
  },
  {
    name: PluginCategoryEnum.教师发展,
    label: $t('教师发展'),
    sort: 13,
    type: MenuType.AdminApps,
    icon: 'Teacher' as const,
    subMenus: [],
  },
  {
    name: PluginCategoryEnum.德育管理,
    label: $t('德育管理'),
    sort: 14,
    type: MenuType.AdminApps,
    icon: 'MoralEducation' as const,
    subMenus: [],
  },
  {
    name: PluginCategoryEnum.后勤管理,
    label: $t('后勤管理'),
    sort: 15,
    icon: 'LogisticsManagement' as const,
    type: MenuType.AdminApps,
    subMenus: [],
  },
  {
    name: PluginCategoryEnum.其他,
    label: $t('其他'),
    sort: 16,
    type: MenuType.AdminApps,
    icon: 'Other' as const,
    subMenus: [],
  },
])
