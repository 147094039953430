/**
 * @file 课程班路由配置
 */

import React, { lazy } from 'react'

import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import { getClassGroupSubRoutes } from './class-group-sub-routes'

/**
 * 课程班页面路由
 *
 * @returns 组件
 */
// eslint-disable-next-line max-lines-per-function
export const getRoutes: () => RouteConfig[] = () => [
  {
    path: 'classes/:classId/for-reflection/:rid',
    name: 'ClassesDetailForReflection',
    getTitle: () => $t('课程详情'),
    component: lazy(() =>
      import('@/features/classes/pages/Group').then(m => ({
        default: m.Show,
      })),
    ),
    subRoutes: getClassGroupSubRoutes(),
  },
  {
    path: 'admin/classes/:classId/view',
    name: 'ClassesDetailAdmin',
    getTitle: () => $t('课程详情'),
    component: lazy(() =>
      import('@/features/classes/pages/Group').then(m => ({
        default: m.Show,
      })),
    ),
    subRoutes: getClassGroupSubRoutes(),
  },
  {
    path: 'admin/classes/:classId/view/exams/:examId/analysis/:subjectId',
    name: 'AdminClassExamSubjectAnalysis',
    getTitle: () => $t('科目详情'),
    component: lazy(() =>
      import('./pages/SubjectAnalysis').then(m => ({
        default: m.SubjectAnalysis,
      })),
    ),
  },
  {
    name: 'AdminKlassList',
    path: 'admin/classes',
    module: ['courses'],
    getTitle: () => $t('课程班管理'),
    component: lazy(() =>
      import('@/features/classes/pages/List').then(m => ({
        default: m.KlassList,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreClassRead],
    subRoutes: [
      {
        name: 'Normal',
        path: 'normal',
        getTitle: () => $t('课程班信息'),
        component: lazy(() =>
          import('@/features/classes/pages/List/Normal').then(m => ({
            default: m.ClassNormalList,
          })),
        ),
      },
      {
        name: 'AdminKlassStudents',
        path: 'students',
        getTitle: () => $t('学生课程'),
        component: lazy(() =>
          import('@/features/classes/pages/List/Students').then(m => ({
            default: m.Students,
          })),
        ),
      },
      {
        name: 'AdminKlassTeachers',
        path: 'teachers',
        getTitle: () => $t('教师课时统计'),
        component: lazy(() =>
          import('@/features/classes/pages/List/Teachers').then(m => ({
            default: m.Teachers,
          })),
        ),
      },
      {
        name: 'Record',
        path: 'record',
        getTitle: () => $t('操作日志'),
        component: lazy(() =>
          import('@/features/classes/pages/List/Record').then(m => ({
            default: m.ClassLogs,
          })),
        ),
      },
      {
        name: 'Settings',
        path: 'settings',
        getTitle: () => $t('设置'),
        component: lazy(() =>
          import('@/features/classes/pages/List/Settings').then(m => ({
            default: m.Settings,
          })),
        ),
      },
    ],
  },
  {
    name: 'AdminKlassUserClasses',
    path: 'admin/classes/students/:userId',
    getTitle: () => $t('加课退课'),
    component: lazy(() =>
      import('@/features/classes/pages/List/UserClasses').then(m => ({
        default: m.UserClassesPage,
      })),
    ),
    subRoutes: [
      {
        name: 'Classes',
        path: 'classes',
        getTitle: () => $t('当前课程'),
        component: lazy(() =>
          import('@/features/classes/pages/List/UserClasses').then(m => ({
            default: m.UserClasses,
          })),
        ),
      },
      {
        name: 'Record',
        path: 'record',
        getTitle: () => $t('退课记录'),
        component: lazy(() =>
          import('@/features/classes/pages/List/UserClasses/Record').then(
            m => ({
              default: m.UserClassesRecord,
            }),
          ),
        ),
      },
    ],
    requirePermissions: [PermissionNameEnum.CoreClassRead],
  },

  {
    name: 'AdminKlassFields',
    path: 'admin/classes/fields',
    getTitle: () => $t('课程班字段管理'),
    component: lazy(() =>
      import('@/features/classes/pages/FieldManagement').then(m => ({
        default: m.FieldManagement,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreClassWrite],
  },
  {
    name: 'AdminKlassNew',
    path: 'admin/classes/new',
    getTitle: () => $t('新增课程班'),
    component: lazy(() =>
      import('@/features/classes/pages/Form/New').then(m => ({
        default: m.NewKlass,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreClassWrite],
  },
  {
    name: 'AdminKlassDetail',
    path: 'admin/classes/:id/detail',
    getTitle: () => $t('课程班详情'),
    component: lazy(() =>
      import('@/features/classes/pages/Detail').then(m => ({
        default: m.AdminKlassDetail,
      })),
    ),
    subRoutes: [
      {
        name: 'AdminKlassDetailInfo',
        path: 'info',
        getTitle: () => $t('课程班信息'),
        component: lazy(() =>
          import('@/features/classes/pages/Detail/Info').then(m => ({
            default: m.AdminKlassInfo,
          })),
        ),
      },
      {
        name: 'AdminKlassDetailMember',
        path: 'member',
        getTitle: () => $t('人员管理'),
        component: lazy(() =>
          import('@/features/classes/pages/Detail/Member').then(m => ({
            default: m.KlassMember,
          })),
        ),
      },
      {
        name: 'AdminKlassDetailLesson',
        path: 'lesson',
        getTitle: () => $t('课节管理'),
        component: lazy(() =>
          import('@/features/classes/pages/Detail/Lesson').then(m => ({
            default: m.AdminKlassLesson,
          })),
        ),
      },
    ],
    requirePermissions: [PermissionNameEnum.CoreClassRead],
  },
  {
    name: 'AdminKlassDetailLessonView',
    path: 'admin/classes/:id/detail/lesson/:lessonId/view',
    getTitle: () => $t('课节详情'),
    component: lazy(() =>
      import('@/features/classes/pages/Detail/Lesson/Form/View').then(m => ({
        default: m.AdminKlassLessonView,
      })),
    ),
  },
  {
    name: 'AdminKlassDetailLessonEdit',
    path: 'admin/classes/:id/detail/lesson/:lessonId/edit',
    getTitle: () => $t('编辑课节'),
    component: lazy(() =>
      import('@/features/classes/pages/Detail/Lesson/Form/Edit').then(m => ({
        default: m.AdminKlassLessonEdit,
      })),
    ),
  },
  {
    name: 'AdminKlassDetailLessonNew',
    path: 'admin/classes/:id/detail/lesson/new',
    getTitle: () => $t('新增课节'),
    component: lazy(() =>
      import('@/features/classes/pages/Detail/Lesson/Form/New').then(m => ({
        default: m.AdminKlassLessonNew,
      })),
    ),
  },
  {
    name: 'AdminKlassDetailLessonBatchEdit',
    path: 'admin/classes/:id/detail/lesson/batch-edit',
    getTitle: () => $t('批量编辑课节'),
    component: lazy(() =>
      import('@/features/classes/pages/Detail/Lesson/Form/BatchEdit').then(
        m => ({
          default: m.AdminKlassLessonBatchEdit,
        }),
      ),
    ),
  },
  {
    name: 'AdminKlassEdit',
    path: 'admin/classes/:id/edit',
    getTitle: () => $t('编辑课程班'),
    component: lazy(() =>
      import('@/features/classes/pages/Form/Edit').then(m => ({
        default: m.EditKlass,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreClassWrite],
  },
  {
    name: 'AdminKlassesBatchEdit',
    path: 'admin/classes/batch-edit',
    getTitle: () => $t('批量编辑课程班'),
    component: lazy(() =>
      import('@/features/classes/pages/Form/BatchEdit').then(m => ({
        default: m.ClassBatchEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreClassWrite],
  },
  {
    path: 'classes/:classId/discussions/for-reflection/:rid/collections',
    name: 'ClassDiscussionCollectionsForReflection',
    getTitle: () => $t('我的收藏'),
    component: lazy(() =>
      import('@/features/discussions/pages/Collections').then(m => ({
        default: m.Collections,
      })),
    ),
  },
  {
    path: 'classes/:classId/discussions/for-reflection/:rid/posts',
    name: 'ClassDiscussionPostsForReflection',
    getTitle: () => $t('我的发布'),
    component: lazy(() =>
      import('@/features/discussions/pages/Posts').then(m => ({
        default: m.Posts,
      })),
    ),
  },
  {
    path: 'classes/:classId',
    name: 'ClassesDetail',
    getTitle: () => $t('课程详情'),
    component: lazy(() =>
      import('@/features/classes/pages/Group').then(m => ({
        default: m.Show,
      })),
    ),
    subRoutes: getClassGroupSubRoutes(),
  },
  {
    path: 'classes/:classId/exams/:examId/analysis/:subjectId',
    name: 'ClassExamSubjectAnalysis',
    getTitle: () => $t('科目详情'),
    component: lazy(() =>
      import('./pages/SubjectAnalysis').then(m => ({
        default: m.SubjectAnalysis,
      })),
    ),
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.ClassMembersSettingsModal',
    getTitle: () => $t('退课审批'),
    component: React.lazy(() =>
      import('./workflow-renders/Settings').then(m => ({
        default: m.ClassWorkflowSettingsRouteModal,
      })),
    ),
  },
  {
    name: 'ClassGradeDailyReportModal',
    getTitle: () => $t('退课审批'),
    component: React.lazy(() =>
      import('./components/DailyGrades').then(m => ({
        default: m.ClassGradeDailyReportRouteModal,
      })),
    ),
  },
]
