/**
 * @file 消息标签
 */

import React from 'react'
import styled from 'styled-components'

import { useEnabledPlugins } from 'packages/feature-utils/plugins'
import {
  LabelStyle,
  calculateLabelStyle,
  getMessageDomain,
} from 'packages/features/messages/register'
import { OpenPlatformDomainPrefix } from 'packages/features/messages/utils/messages'
import { $t } from 'packages/locale'
import { Message } from 'packages/sdks-next/chalk'

/**
 * 消息标签
 *
 * @param param0 - 参数
 * @param param0.message - 消息
 * @returns 组件
 */
export const MessageLabel: React.FC<{ message: Message }> = ({ message }) => {
  let label: string | undefined
  let labelStyle: LabelStyle | undefined

  const [plugins] = useEnabledPlugins()

  // 三方应用消息
  if (message.domain?.startsWith(OpenPlatformDomainPrefix)) {
    const targetPluginName = message.domain?.split('.')[1]

    if (targetPluginName) {
      const targetPlugin = plugins.find(
        plugin => plugin.pluginName === targetPluginName,
      )

      if (targetPlugin) {
        label = targetPlugin.label
      }
    }
  } else {
    const domain = getMessageDomain(message.type)

    label = domain?.getLabel()
    labelStyle = domain?.labelStyle
  }

  return (
    <Wrapper style={labelStyle || calculateLabelStyle('#EDF9E7', '#619348')}>
      {label || $t('消息')}
    </Wrapper>
  )
}

const Wrapper = styled.span`
  padding: 0 7px;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  border-radius: 1000px;
`
