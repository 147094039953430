/**
 * @file 渲染在第一屏的默认身份选择弹窗
 *
 * 懒加载实现
 */

import { FC, lazy, Suspense } from 'react'

import {
  useCurrentUser,
  useCurrentUserReflectionsWithFullData,
} from 'packages/features/sessions/utils'

const OriginDefaultReflectionPicker = lazy(() =>
  import('./Component').then(m => ({ default: m.DefaultReflectionPicker })),
)

export const DefaultReflectionPicker: FC = () => {
  const user = useCurrentUser()
  const reflections = useCurrentUserReflectionsWithFullData()

  if (!user) return null

  // 如果没得选，那就不让选
  if (reflections.length < 2) return null

  if (reflections.find(r => r.isDefault)) return null

  return (
    <Suspense fallback={null}>
      <OriginDefaultReflectionPicker user={user} reflections={reflections} />
    </Suspense>
  )
}
