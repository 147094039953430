import { initGrades } from '@/features/grades'
import { initSubjectSelection } from '@/features/subject-selection'
import { initGradesReport } from '@/features/sz-grades-reports'

import { initAdminClasses } from './admin-classes'
import { initAttendances } from './attendances'
import { initClassTasks } from './class-tasks'
import { initClasses } from './classes'
import { initCourses } from './courses'
import { initDiscussions } from './discussions'
import { initEvaluations } from './evaluations'
import { initExams } from './exams'
import { initFeedbacks } from './feedbacks'
import { initGoals } from './goals'
import { initHome } from './home'
import { initMessage } from './messages'
import { initNoCode } from './no-code'
import { initNotice } from './notices'
import { initPlaces } from './places'
import { initPlugins } from './plugins'
import { initProfiles } from './profiles'
import { initSchedules } from './schedules'
import { initSemesterReview } from './semester-review'
import { initSemesters } from './semesters'
import { initShares } from './shares'
import { initSystemSettings } from './system-settings'
import { initTeacherProfiles } from './teacher-profiles'
import { initTodos } from './todos'
import { InitFeatureParams } from './types'
import { initUsers } from './users'
import { initVenues } from './venues'
import { initWorkflows } from './workflows'

/**
 * 初始化 Chalk Main Features
 *
 * @param params - 各种初始化方法
 */
export const initFeatures = (params: InitFeatureParams) => {
  initWorkflows(params)
  initVenues(params)

  initUsers(params)
  initCourses(params)
  initClasses(params)
  initGrades(params)
  initAdminClasses(params)
  initClassTasks(params)
  initTodos(params)
  initHome(params)
  initMessage(params)
  initNotice(params)

  initAttendances(params)
  initSystemSettings(params)
  initFeedbacks(params)
  initPlaces(params)
  initSemesters(params)
  initSemesterReview(params)
  initProfiles(params)
  initNoCode(params)
  initTeacherProfiles(params)
  initGoals(params)
  initSchedules(params)
  initGradesReport(params)
  initExams(params)
  initEvaluations(params)
  initSubjectSelection(params)
  initPlugins(params)
  initShares(params)
  initDiscussions(params)
}
