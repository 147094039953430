/**
 * @file 评教评学待办注册入口
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.EvaluationAnswer,
    Render: lazy(() =>
      import('./EvaluationAnswerTodo').then(m => ({
        default: m.EvaluationAnswerTodo,
      })),
    ),
  },
]
