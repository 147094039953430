/**
 * @file 课程模块 - 菜单声明
 */

import { atom } from 'jotai'

import {
  hasEnabledPluginAtom,
  ModuleEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'
import { MenuProvider } from 'packages/web-layout/menu-utils'
import { MenuType } from 'packages/web-layout/types'

import { hasCurrentPermissionFnAtom } from '@/utils/permission'

/**
 * 课程管理菜单
 */
export const adminCourseMenuProvider: MenuProvider = atom(get => {
  const enabledCourse = get(hasEnabledPluginAtom(ModuleEnum.Course))

  const hasPermission = hasCurrentPermissionFnAtom(get)

  return [
    {
      prefix: [],
      name: 'courses',
      label: $t('课程管理'),
      icon: 'CourseLibrary' as const,
      sort: 0,
      type: MenuType.Admin,
      subMenus: enabledCourse
        ? [
            {
              name: 'courses',
              path: '/admin/courses',
              label: $t('课程库管理'),
              icon: 'CourseLibrary' as const,
              shortcutIcon: 'CourseLibrarySolid+477CFF',
              permission: () =>
                hasPermission({
                  permission: PermissionNameEnum.CoreCourseRead,
                  isManager: true,
                }),
              sort: 1,
            },
          ]
        : [],
    },
  ]
})
