/**
 * @file 课程班模块 - 菜单声明
 */

import { atom } from 'jotai'

import { $t } from 'packages/locale'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import { hasCurrentPermissionFnAtom } from '@/utils/permission'

/**
 * 课程班管理菜单
 */
export const adminClassMenuProvider = atom(get => {
  const hasPermission = hasCurrentPermissionFnAtom(get)

  return [
    {
      prefix: ['courses'],
      name: 'class',
      path: '/admin/classes',
      label: $t('课程班管理'),
      sort: 100,
      icon: 'CourseClass' as const,
      shortcutIcon: 'CourseClassSolid+8E6BF1',
      permission: () =>
        hasPermission({
          permission: PermissionNameEnum.CoreClassRead,
          isManager: true,
        }),
    },
  ]
})
