import { flatten, isEmpty } from '@seiue/util'
import { useCallback, useMemo } from 'react'
import { useHistory as useHistoryOriginal } from 'react-router-dom'

import {
  PersonalConfigKey,
  usePersonalConfig,
} from 'packages/feature-utils/personal-configs'
import { useHasEnabledParentPlugin } from 'packages/feature-utils/plugins'
import { useCurrentReflection } from 'packages/features/sessions'
import { PluginNameEnum } from 'packages/plugins/types'
import {
  EnabledSchoolPlugin,
  SchoolPlugin,
  Reflection,
} from 'packages/sdks-next/chalk'
import { MenuItemUnion } from 'packages/web-layout/types'

import { getDefaultMenuPath } from '@/App/Layout/utils'
import { useDataSlots } from '@/plugins/slot'
import { useRouteModalHistory } from '@/router'

/**
 * 增加最近访问的 hook
 *
 * @returns 增加最近访问的函数
 */
export const useAddRecentEntry = () => {
  const [recent, setRecent] = usePersonalConfig(
    PersonalConfigKey.HomeRecentEntryConfig,
  )

  const isCopilotEnabled = useHasEnabledParentPlugin(PluginNameEnum.Copilot)

  const maxRecent = isCopilotEnabled ? 6 : 4

  return useCallback(
    (type: 'menu' | 'plugin', value: string) => {
      const nextRecent = [...(recent || [])].filter(v => v.value !== value)

      if (nextRecent.length === maxRecent) {
        nextRecent.pop()
      }

      nextRecent.unshift({
        type,
        value,
      })

      return setRecent(nextRecent)
    },
    [maxRecent, recent, setRecent],
  )
}

export type EntryCallBackParam = {
  me: Reflection
  plugin: EnabledSchoolPlugin
  isFromManage: boolean
}

type EntryParam = {
  /**
   * 用户信息
   */
  me: Reflection
  /**
   * 插件或插件菜单定义
   */
  entry: EnabledSchoolPlugin | MenuItemUnion
  /**
   * history
   */
  historyOrigin: ReturnType<typeof useHistoryOriginal>
  /**
   * 获取插件入口 url 的 slot
   */
  pluginUrlSlots: {
    /**
     * 插件名
     */
    name: string | PluginNameEnum
    /**
     * 获取插件入口 url 的回调
     */
    callback:
      | (({ plugin, isFromManage }: EntryCallBackParam) => void)
      | undefined
  }[]
  /**
   * 是否来自应用管理中心
   */
  isFromManage: boolean
}

type EntryUrls = {
  web?: string
}

function isMenu(item: EntryParam['entry']): item is MenuItemUnion {
  return (item as any).path != null || (item as any).subMenus != null
}

const goEntry = (params: EntryParam) => {
  const { me, entry, historyOrigin, pluginUrlSlots, isFromManage } = params

  const openUrl = (url: string | undefined) => {
    if (!url) {
      return
    }

    if (url.startsWith('http')) {
      window.open(url, '_blank', 'noopener')
    } else {
      historyOrigin.push(url)
    }
  }

  if (isMenu(entry)) {
    const menuPath = getDefaultMenuPath(entry)
    openUrl(menuPath)
    return
  }

  const plugin = entry as unknown as EnabledSchoolPlugin

  let { entryUrls }: { entryUrls: EntryUrls } =
    plugin as unknown as SchoolPlugin

  if (isEmpty(entryUrls)) {
    entryUrls = plugin.plugin?.entryUrls || {}
  }

  let { settingUrl } = plugin as unknown as SchoolPlugin
  if (!settingUrl) {
    settingUrl = plugin.plugin?.settingUrl || ''
  }

  const callSlot = (
    pluginName: string,
    _plugin: EnabledSchoolPlugin,
  ): boolean => {
    const callback = pluginUrlSlots.find(
      slot => slot.name === pluginName,
    )?.callback

    if (!callback) {
      return false
    }

    callback({
      me,
      plugin: _plugin,
      isFromManage,
    })

    return true
  }

  const pluginName = isFromManage
    ? (plugin as unknown as SchoolPlugin)?.plugin?.name || ''
    : (plugin as unknown as EnabledSchoolPlugin).pluginName

  const goAdmin = () => {
    if (settingUrl) {
      openUrl(settingUrl)
      return
    }

    if (callSlot(pluginName, plugin as unknown as EnabledSchoolPlugin)) {
      return
    }

    if (entryUrls.web) {
      openUrl(entryUrls.web)
    }
  }

  const goUser = () => {
    const p = plugin as unknown as EnabledSchoolPlugin

    if (entryUrls.web) {
      openUrl(entryUrls.web)
    } else if (callSlot(pluginName, p)) {
      // noop
    } else if (p.isAdmin && settingUrl) {
      openUrl(settingUrl)
    }
  }

  if (isFromManage) {
    /**
     * 1. 如果是从管理端进入，直接跳转到管理端
     * 2 和 3 的前提是后端在做判断时的逻辑是：用户在应用中心能看见该插件入口，就是说插件是可见的或者是插件的管理员
     */
    goAdmin()
  } else if (plugin.isVisible) {
    /**
     * 2. 如果是从前台进入，且插件可见，直接跳转到前台
     */
    goUser()
  } else {
    /**
     * 3. 如果是从前台进入，且插件不可见，直接跳转到管理端
     */
    goAdmin()
  }
}

type EnterPluginCallBackParam = {
  entry: EntryParam['entry']
  isFromManage: boolean
  recordRecent?: boolean
}

/**
 * 获取进入插件的方法
 * 使用该方法访问插件或者应用后，会将该入口记录到用户的最近访问中
 *
 * @returns 进入插件的方法
 */
export const useToEntry = () => {
  const me = useCurrentReflection()

  const historyOrigin = useHistoryOriginal()
  const modalHistory = useRouteModalHistory()

  const { slots } = useDataSlots('userCommonApplicationsEntry', {
    historyOrigin,
    modalHistory,
  })

  const urlSlots = useMemo(() => flatten(slots), [slots])

  const addRecent = useAddRecentEntry()

  return useCallback(
    ({ entry, isFromManage, recordRecent }: EnterPluginCallBackParam) => {
      if (recordRecent) {
        const entryIsMenu = isMenu(entry)

        addRecent(
          entryIsMenu ? 'menu' : 'plugin',
          entryIsMenu ? entry.name || '' : `${entry.id}`,
        )
      }

      goEntry({
        me,
        entry,
        historyOrigin,
        pluginUrlSlots: urlSlots,
        isFromManage,
      })
    },
    [addRecent, historyOrigin, me, urlSlots],
  )
}
