import { InitFeatureParams } from '../types'

import { adminCourseMenuProvider } from './menus'
import { getRoutes } from './routes'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initCourses = ({
  addRoutes,
  addMenuProvider,
}: InitFeatureParams) => {
  addRoutes(getRoutes())
  addMenuProvider(adminCourseMenuProvider)
}
