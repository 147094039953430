/* eslint-disable seiue/missing-formatted-message */

import { Group } from 'packages/sdks-next/chalk'

const gradeOrder = {
  年级: 1,
  初: 2,
  高: 3,
  其他: 4,
}

const chineseNumbersLevel: { [key: string]: number } = {
  一: 101,
  二: 102,
  三: 103,
  四: 104,
  五: 105,
  六: 106,
  七: 107,
  八: 108,
  九: 109,
  十: 110,
  十一: 111,
  十二: 112,
}

const parseGradeNumToLevel = (num: string) => {
  if (num === '0') {
    return 0
  }

  if (num) {
    return parseInt(num, 10) || chineseNumbersLevel[num] || undefined
  }

  return undefined
}

/**
 * 获取年级数字的排序权重
 *
 * @param grade - 年级
 * @returns number
 */
export const getGradeNumsLevel = (grade: string) => {
  if (grade.includes('年级')) {
    const num = grade.match(/(.+)年级/)?.[1] || ''

    return parseGradeNumToLevel(num)
  }

  if (grade.startsWith('初')) {
    const num = grade.match(/初(.*)/)?.[1] || ''
    return parseGradeNumToLevel(num)
  }

  if (grade.startsWith('高')) {
    const num = grade.match(/高(.*)/)?.[1] || ''

    return parseGradeNumToLevel(num)
  }

  return undefined
}

/**
 * 年级排序
 *
 * @param grade - 年级
 * @returns number
 */
export const getGradePriority = (grade: string) => {
  if (grade.includes('年级')) return gradeOrder['年级']
  if (grade.includes('初')) return gradeOrder['初']
  if (grade.includes('高')) return gradeOrder['高']
  return gradeOrder['其他']
}

/**
 * 班级排序设置
 *
 * @param classStr - 班级名称
 * @returns object
 */
export const classOrder = (classStr = '') => {
  const match = classStr.match(/^(\d+)|([A-Za-z])/)
  if (match) {
    if (match[1]) return { type: 'number', value: parseInt(match[1], 10) }
    if (match[2]) return { type: 'letter', value: match[2].toUpperCase() }
  }

  return { type: 'other', value: classStr }
}

type SortClassType = {
  gradeFlag: string
  classFlag: string
  courseFlag: string
}

/**
 * 比较课程班
 *
 * @param a - 比较参数 1
 * @param b - 比较参数 2
 * @returns Number
 */
export const compareClasses = (a: SortClassType, b: SortClassType) => {
  // 年级比较
  const gradeA = getGradePriority(a.gradeFlag)
  const gradeB = getGradePriority(b.gradeFlag)
  if (gradeA !== gradeB) return gradeA - gradeB

  // 班级比较
  const classA = classOrder(a.classFlag)
  const classB = classOrder(b.classFlag)
  if (classA.type !== classB.type) return classA.type === 'number' ? -1 : 1
  if (classA.value !== classB.value) return classA.value > classB.value ? 1 : -1

  // 课程名称比较
  if (a.courseFlag !== b.courseFlag) return a.courseFlag > b.courseFlag ? 1 : -1

  return 0
}

/**
 * 格式化课程班 group
 *
 * @param group - group
 * @returns object
 */
export const formatGroupForCompare = (group: Group) => {
  const isMultiGrades = (group.extraFields?.gradeIds?.length || 0) > 1

  const { gradeNames, name, className } =
    (group.extraFields as {
      gradeNames?: string
      name?: string
      className?: string
    }) || {}

  return {
    ...group,
    gradeFlag: isMultiGrades ? '' : gradeNames || '',
    courseFlag: name || '',
    classFlag: className || '',
  }
}

/**
 * 对课程班 group 进行排序
 *
 * @param groups - groups
 * @returns sortedGroups
 */
export const sortGroups = (groups: Group[]) => {
  const sortedGroups = groups
    .map(item => {
      const ismultiGrades = (item.extraFields?.gradeIds?.length || 0) > 1

      const gradeNames =
        (item.extraFields as { gradeNames?: string })?.gradeNames || ' '

      const [courseFlag, classFlag] = item.name.split(gradeNames)

      return {
        ...item,
        gradeFlag: ismultiGrades ? '' : gradeNames,
        courseFlag,
        classFlag,
      }
    })
    .sort(compareClasses)

  return sortedGroups
}
