/**
 * @file 成绩报告路由配置
 */
import { lazy } from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

const analysisPermission = [
  PermissionNameEnum.SzNjcjhzReportDownload,
  PermissionNameEnum.SzJbcjhzReportDownload,
  PermissionNameEnum.SzBjcjhzReportDownload,
  PermissionNameEnum.SzKmcjhzReportDownload,
  PermissionNameEnum.SzDkjfdbReportDownload,
  PermissionNameEnum.SzDuokjfdbReportDownload,
  PermissionNameEnum.SzDuokjfdb2ReportDownload,
  PermissionNameEnum.SzDankgffxReportDownload,
  PermissionNameEnum.SzDuokgffxReportDownload,
  PermissionNameEnum.SzDankecjfdfxReportDownload,
  PermissionNameEnum.SzDuokecjfdfxReportDownload,
]

const reportPermission = [
  PermissionNameEnum.SzExamReportRead,
  PermissionNameEnum.SzSemesterReportRead,
  PermissionNameEnum.SzChuguoReportRead,
  PermissionNameEnum.SzGlobalAcademicReportRead,
  PermissionNameEnum.SzResitReportRead,
]

export const routes: RouteConfig[] = [
  {
    path: 'admin/sz-grades-reports/reports',
    getTitle: () => $t('成绩报告'),
    module: [PluginCategoryEnum.其他, PluginEnum.SzGradesReports],
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/index').then(m => ({
        default: m.AdminGradeMain,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
    subRoutes: [
      {
        path: 'normal',
        requirePermissions: reportPermission,
        getTitle: () => $t('在校学生'),
        component: lazy(() =>
          import('@/features/sz-grades-reports/pages/reports/List').then(m => ({
            default: m.List,
          })),
        ),
      },
      {
        path: 'archived',
        requirePermissions: reportPermission,
        getTitle: () => $t('已归档学生'),
        component: lazy(() =>
          import('@/features/sz-grades-reports/pages/reports/List').then(m => ({
            default: m.List,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/sz-grades-reports/analysis',
    getTitle: () => $t('成绩分析'),
    module: [PluginCategoryEnum.其他, 'sz-grades-reports'],
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/analysis').then(m => ({
        default: m.Analysis,
      })),
    ),
    requirePermissions: analysisPermission,
  },
  {
    path: 'admin/sz-grades-reports/status',
    getTitle: () => $t('成绩状态'),
    module: [PluginCategoryEnum.其他, 'sz-grades-reports'],
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/status').then(m => ({
        default: m.Status,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },
  {
    path: 'admin/sz-grades-reports/settings',
    getTitle: () => $t('设置'),
    module: [PluginCategoryEnum.其他, 'sz-grades-reports'],
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/settings').then(m => ({
        default: m.Settings,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },

  {
    path: 'admin/sz-grades-reports/student/:rid',
    getTitle: () => $t('学生报告单'),
    name: 'SZReport',
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/reports/report').then(m => ({
        default: m.Report,
      })),
    ),

    subRoutes: [
      {
        path: 'exam-report',
        name: 'Exam',
        getTitle: () => $t('考试报告单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/ExamReport'
          ).then(m => ({
            default: m.ExamReport,
          })),
        ),
        requirePermissions: [PermissionNameEnum.SzExamReportRead],
      },
      {
        path: 'semester-report',
        name: 'Semester',
        getTitle: () => $t('学期报告单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/SemesterReport'
          ).then(m => ({
            default: m.SemesterReport,
          })),
        ),
        requirePermissions: [PermissionNameEnum.SzSemesterReportRead],
      },
      {
        path: 'global-academic-report',
        name: 'Global',
        getTitle: () => $t('总学业报告单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/SingleReport'
          ).then(m => ({
            default: m.SingleReport,
          })),
        ),
        requirePermissions: [PermissionNameEnum.SzGlobalAcademicReportRead],
      },
      {
        path: 'chuguo-report',
        name: 'Chuguo',
        getTitle: () => $t('出国成绩单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/SingleReport'
          ).then(m => ({
            default: m.SingleReport,
          })),
        ),
        requirePermissions: [PermissionNameEnum.SzChuguoReportRead],
      },
      {
        path: 'resit-report',
        name: 'Resit',
        getTitle: () => $t('补考成绩单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/ResitReport'
          ).then(m => ({
            default: m.ResitReport,
          })),
        ),
        requirePermissions: [PermissionNameEnum.SzResitReportRead],
      },
    ],
  },
  {
    path: 'admin/sz-grades-reports/settings/roles/new',
    getTitle: () => $t('新增成绩分析角色'),
    component: lazy(() =>
      import('./pages/settings/edit-role').then(m => ({
        default: m.EditRole,
      })),
    ),
    // requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },
  {
    path: 'admin/sz-grades-reports/settings/roles/:roleId/edit',
    getTitle: () => $t('编辑成绩分析角色'),
    component: lazy(() =>
      import('./pages/settings/edit-role').then(m => ({
        default: m.EditRole,
      })),
    ),
    // requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },
]
