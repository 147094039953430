/**
 * @file Layout 相关通用样式组件
 */
import styled from 'styled-components'

export const ControlBarIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  padding: 0 8px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.05);

  border-radius: 16px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &&& {
    .anticon {
      cursor: pointer !important;
    }
  }
`
