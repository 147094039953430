/**
 * @file 通知相关的一些展示函数
 */

import { Badge } from '@seiue/ui'
import { FC } from 'react'
import styled from 'styled-components'

import { $t } from 'packages/locale'

/**
 * 通知菜单 Label 的 Render 函数
 */
export const NoticeMenuLabelRender: FC<{ unreadCount: number }> = ({
  unreadCount,
}) => {
  return (
    <NoticeLabelWrapper>
      {$t('通知')}
      <BadgeStyled count={unreadCount} overflowCount={999} />
    </NoticeLabelWrapper>
  )
}

const NoticeLabelWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BadgeStyled = styled(Badge)`
  .ant-badge-count {
    box-shadow: none;
  }
`
