import { registerToSlot } from '@/plugins'

import { InitFeatureParams } from '../types'

import { calendarEvents } from './calendar-events'
import { adminClassTaskMenu, userClassTaskMenu } from './menus'
import { routes, modalRoutes } from './routes'
import { todos } from './todos'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initClassTasks = ({
  addRoutes,
  addMenuProvider,
  registerCalendarEvents,
  registerTodos,
}: InitFeatureParams) => {
  addRoutes(routes, modalRoutes)
  addMenuProvider(adminClassTaskMenu)
  addMenuProvider(userClassTaskMenu)
  registerCalendarEvents(calendarEvents)
  registerTodos(todos)
  registerToSlot('userCommonApplicationsEntry', () =>
    import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
  )
}
