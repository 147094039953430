import { lazy } from 'react'

import { ModuleEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const adminClassAttendancesRoutes: RouteConfig[] = [
  {
    path: 'admin/attendances/classes',
    getTitle: () => $t('考勤管理'),
    name: 'AdminClassAttendance',
    module: ['courses'],
    requirePermissions: [
      PermissionNameEnum.CoreClassRead,
      PermissionNameEnum.CoreClassWrite,
    ],
    requireModules: [ModuleEnum.Attendance],
    component: lazy(() =>
      import('../pages/Management').then(m => ({
        default: m.AttendanceStats,
      })),
    ),
    subRoutes: [
      {
        path: 'stats/record',
        name: 'Record',
        getTitle: () => $t('考勤记录'),
        component: lazy(() =>
          import('../pages/Management/BizListStats/StudentRecord').then(m => ({
            default: m.StudentRecord,
          })),
        ),
      },
      {
        path: 'stats/list',
        name: 'ClassStat',
        getTitle: () => $t('课程班考勤统计'),
        component: lazy(() =>
          import('../pages/Management/BizListStats/ClassListStats').then(m => ({
            default: m.ClassListStats,
          })),
        ),
      },
      {
        name: 'StudentStat',
        path: 'stats/students',
        getTitle: () => $t('学生考勤统计'),
        component: lazy(() =>
          import('../pages/Management/StudentListStats').then(m => ({
            default: m.StudentListStats,
          })),
        ),
      },
      {
        path: 'settings',
        getTitle: () => $t('考勤设置'),
        component: lazy(() =>
          import('../pages/Management/Settings').then(m => ({
            default: m.Setting,
          })),
        ),
      },
    ],
  },
  {
    name: 'AdminStudentClassAttendanceStats',
    path: 'admin/attendances/classes/stats/owner/:rid',
    getTitle: () => $t('考勤详情'),
    requireModules: [ModuleEnum.Attendance],
    component: lazy(() =>
      import('../pages/Management/BizStats/OwnerAttendance').then(m => ({
        default: m.OwnerAttendance,
      })),
    ),
  },
  {
    // 从行政班课程考勤统计/查看进入的【学生课程班考勤统计页】
    name: 'StudentClassAttendanceStats',
    path: 'attendances/classes/stats/owner/:rid',
    getTitle: () => $t('学生课程考勤统计'),
    requireModules: [ModuleEnum.Attendance],
    component: lazy(() =>
      import('../pages/Management/BizStats/OwnerAttendance').then(m => ({
        default: m.OwnerAttendance,
      })),
    ),
  },
  {
    path: 'admin/attendances/stats/classes/:classId',
    getTitle: () => $t('课程详情'),
    requireModules: [ModuleEnum.Attendance],
    module: ['courses'],
    component: lazy(() =>
      import('../pages/Management/BizStats/ClassStats').then(m => ({
        default: m.ClassStats,
      })),
    ),
    subRoutes: [
      {
        name: 'AdminClassAttendanceRecords',
        path: 'records',
        getTitle: () => $t('考勤记录'),
        component: lazy(() =>
          import('../pages/Management/BizListStats/StudentRecord').then(m => ({
            default: m.StudentRecord,
          })),
        ),
      },
      {
        path: 'lessons',
        getTitle: () => $t('考勤详情'),
        component: lazy(() =>
          import('../pages/Management/BizStats/ClassLessonsStats').then(m => ({
            default: m.ClassLessonsStats,
          })),
        ),
      },
      {
        path: 'students',
        getTitle: () => $t('学生考勤统计'),
        component: lazy(() =>
          import('../pages/Management/BizStats/StudentsStats').then(m => ({
            default: m.StudentsStats,
          })),
        ),
      },
    ],
  },
]
