import { IconSource } from '@seiue/ui'
import { LazyExoticComponent } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'
import {
  WidgetTypeEnum as WidgetTypeEnumChalk,
  RoleEnum,
} from 'packages/sdks-next/chalk'

export { WidgetTypeEnumChalk as WidgetTypeEnum }

export type RegisterWidget = {
  /**
   * 组件唯一标识
   */
  key: WidgetKeyEnum
  /**
   * 组件名称
   */
  label: () => string
  /**
   * 组件图标
   */
  icon: IconSource
  /**
   * 组件类型
   */
  type: WidgetTypeEnumChalk
  /**
   * 组件所属插件，type=WidgetTypeEnum.PluginBiz 时必填
   */
  pluginName?: PluginNameEnum
  /**
   * 定义可使用的角色
   */
  enableRoles: RoleEnum[]
  /**
   * 支持的档位（即大小）
   */
  sizes: WidgetLayoutSize[]
  /**
   * 布局属性
   */
  layout: WidgetLayout
  /**
   * 预览组件（拖拽布局时查看）
   */
  PreviewComponent: LazyExoticComponent<WidgetPreviewComponent>
  /**
   * 实际用户查看的组件
   */
  RealComponent: LazyExoticComponent<WidgetComponent>
}

export type WidgetLayout = {
  /**
   * 小组件在网格中的高度（行数）
   */
  rows: number
  /**
   * 是否可拖动
   */
  isDraggable?: boolean
}

export type WidgetComponent = React.FC<{
  /**
   * 组件尺寸
   */
  size: WidgetLayoutSize
  bizAttributes?: object
  onRemove?: () => void
}>

export type WidgetPreviewComponent = React.FC<{
  /**
   * 组件尺寸
   */
  size: WidgetLayoutSize
  /**
   * 以某种角色访问预览组件
   */
  role?: RoleEnum
}>

/**
 * 小组件尺寸，分别为特大（12 格）、大（8 格）、中（6 格）和小（4 格）
 */
export enum WidgetLayoutSize {
  XLarge = 'xLarge',
  Large = 'large',
  Middle = 'middle',
  Small = 'small',
}

/**
 * 小组件占栅格的宽度
 */
export type WidgetLayoutGridWidth = 12 | 8 | 6 | 4

/**
 * 小组件唯一标识符定义
 */
export enum WidgetKeyEnum {
  /**
   * 首页 - 快捷入口
   */
  HomeTools = 'homeTools',
  /**
   * 首页 - 快捷入口 - 最近使用
   */
  HomeToolsRecent = 'homeToolsRecent',
  /**
   * 首页 - 快捷入口 - 我常用的
   */
  HomeToolsFrequent = 'homeToolsFrequent',
  /**
   * 首页 - 待办
   */
  HomeTodos = 'homeTodos',
  /**
   * 首页 - 简洁待办
   */
  HomeTodosConcise = 'homeTodosConcise',
  /**
   * 首页 - 通知
   */
  HomeMessages = 'homeMessages',
  /**
   * 首页 - 通知（大）
   */
  HomeMessagesLarge = 'homeMessagesLarge',
  /**
   * 首页 - 日程
   */
  HomeCalendar = 'homeCalendar',
  /**
   * 首页 - 单日日程
   */
  HomeCalendarDay = 'homeCalendarDay',
  /**
   * 德育评价 - 本周得分卡片
   */
  MoralAssessmentScore = 'moralAssessmentScore',

  /**
   * AI - 图表
   */
  AIChart = 'aiChart',

  /**
   * 进度追踪
   */
  Tracker = 'tracker',
}
