/**
 * @file grades todos
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

const GradeAfterthoughtTodoCard = lazy(() =>
  import('./GradeAfterthoughtTodoCard').then(m => ({
    default: m.GradeAfterthoughtTodoCard,
  })),
)

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.ClassAssessmentAfterthought,
    Render: GradeAfterthoughtTodoCard,
  },
  {
    type: TodoTypeEnum.ClassAssessmentItemAfterthought,
    Render: GradeAfterthoughtTodoCard,
  },
  {
    type: TodoTypeEnum.StageGradeSubmit,
    Render: lazy(() =>
      import('./StageGradeSubmitCard').then(m => ({
        default: m.StageGradeSubmitTodoCard,
      })),
    ),
  },
  {
    type: TodoTypeEnum.ItemStudentMarkStudent,
    Render: lazy(() =>
      import('./AssessmentSelf').then(m => ({
        default: m.AssessmentSelfTodoCard,
      })),
    ),
  },
]
