import { InitFeatureParams } from '@/features/types'
import { registerToSlot } from '@/plugins'

import { routes } from './routes'

/**
 * 深中-定制成绩报告插件
 *
 * @param param - Argument Object
 * @param param.addRoutes - 注册路由方法
 */
export const initGradesReport = ({ addRoutes }: InitFeatureParams) => {
  addRoutes(routes)

  registerToSlot('userCommonApplicationsEntry', () =>
    import('./slots/user-common-applications-entry').then(
      m => m.CommonUseApplicationsEntry,
    ),
  )
}
