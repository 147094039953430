import 'packages/features/messages/register'
import 'packages/features/messages/register/chalk-navigators'

import { InitFeatureParams } from '@/features/types'

import { routes, modalRoutes } from './routes'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initMessage = ({ addRoutes }: InitFeatureParams) => {
  addRoutes(routes, modalRoutes)
}
