import { InitFeatureParams } from '@/features/types'
import { registerToSlot } from '@/plugins'

import { adminMenuProvider } from './menus'
import { modalRoutes, routes } from './routes'
import { getProfileSubRoutes } from './slots/route-slots'
import { todos } from './todos'

/**
 * 学期评语
 *
 * @param param0 - 参数
 */
export const initSemesterReview = (param0: InitFeatureParams) => {
  const { addRoutes, addMenuProvider, registerTodos } = param0

  addRoutes(routes, modalRoutes)
  addMenuProvider(adminMenuProvider)
  registerTodos(todos)
  registerToSlot('profileSubRoutes', getProfileSubRoutes())
  registerToSlot('profileModulePermissions', () =>
    import('./slots/data-slots').then(m => m.profileModulePermissions),
  )
}
