import { flatten } from '@seiue/util'
import { lazy } from 'react'

import { ModuleEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import { getRouteSlots } from '@/plugins'

const getSchoolConfigSubRoutes = () => {
  return [
    ...flatten(getRouteSlots('schoolConfigSubRoutes')),
    {
      path: 'login',
      getTitle: () => $t('登录与导航'),
      component: lazy(() =>
        import('./pages/SchoolConfigs/Login').then(m => ({
          default: m.LoginConfig,
        })),
      ),
    },
    {
      path: 'locale',
      name: 'Locale',
      getTitle: () => $t('多语言'),
      requireModules: [ModuleEnum.I18n],
      component: lazy(() =>
        import('./pages/SchoolConfigs/Locale').then(m => ({
          default: m.LocaleManager,
        })),
      ),
      subRoutes: [
        {
          name: 'System',
          path: 'system',
          getTitle: () => $t('系统文本'),
          component: lazy(() =>
            import('./pages/SchoolConfigs/Locale').then(m => ({
              default: m.SystemLocaleManager,
            })),
          ),
        },

        {
          name: 'Custom',
          path: 'custom',
          getTitle: () => $t('自定义字段'),
          component: lazy(() =>
            import('./pages/SchoolConfigs/Locale/Custom').then(m => ({
              default: m.CustomLocaleManager,
            })),
          ),
        },
      ],
    },
  ]
}

/**
 * 获取系统管理的路由定义
 *
 * @returns 路由定义
 */
export const getRoutes = (): RouteConfig[] => [
  {
    path: 'admin/system-settings/permissions',
    name: 'AdminSystemSettingPermissions',
    module: ['users'],
    getTitle: () => $t('权限管理'),
    component: lazy(() =>
      import('./pages/Permissions').then(m => ({
        default: m.Permissions,
      })),
    ),
    subRoutes: [
      {
        name: 'Core',
        path: 'core',
        getTitle: () => $t('基础权限设置'),
        component: lazy(() =>
          import('./pages/Permissions/Core/List').then(m => ({
            default: m.AuthList,
          })),
        ),
        requirePermissions: [
          PermissionNameEnum.CoreModuleAuthRead,
          PermissionNameEnum.CoreAdminAuthRead,
        ],
      },
      {
        path: 'profile',
        name: 'Profile',
        getTitle: () => $t('档案权限设置'),
        component: lazy(() =>
          import('./pages/Permissions/Profile').then(m => ({
            default: m.ProfilePermissions,
          })),
        ),
        requirePermissions: [PermissionNameEnum.CoreGrowthSettingWrite],
      },
    ],
  },
  {
    path: 'admin/system-settings/permissions/core/admin-roles/new',
    getTitle: () => $t('新增授权管理角色'),
    component: lazy(() =>
      import('./pages/Permissions/Core/AdminEdit').then(m => ({
        default: m.AuthAdminEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreAdminAuthWrite],
  },
  {
    path: 'admin/system-settings/permissions/core/admin-roles/:roleId/edit',
    getTitle: () => $t('编辑授权管理角色'),
    component: lazy(() =>
      import('./pages/Permissions/Core/AdminEdit').then(m => ({
        default: m.AuthAdminEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreAdminAuthWrite],
  },
  {
    path: 'admin/system-settings/permissions/core/basic-roles/new',
    getTitle: () => $t('新增基础权限管理角色'),
    component: lazy(() =>
      import('./pages/Permissions/Core/BasicAuthEdit').then(m => ({
        default: m.AuthBasicAuthEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreModuleAuthWrite],
  },
  {
    path: 'admin/system-settings/permissions/core/basic-roles/:roleId/edit',
    getTitle: () => $t('编辑基础权限管理角色'),
    component: lazy(() =>
      import('./pages/Permissions/Core/BasicAuthEdit').then(m => ({
        default: m.AuthBasicAuthEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreModuleAuthWrite],
  },
  {
    name: 'AdminSystemSettingSchoolConfigs',
    path: 'admin/system-settings/school-configs',
    module: ['system'],
    getTitle: () => $t('个性化设置'),
    component: lazy(() =>
      import('./pages/SchoolConfigs').then(m => ({
        default: m.SchoolConfigs,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreAdminSettingWrite],
    subRoutes: getSchoolConfigSubRoutes(),
  },
]
