/**
 * @file useInitLogoInfo 初始化 logo 相关配置数据数据
 */

import { reduce } from '@seiue/util'
import React from 'react'

import {
  ModuleEnum,
  useHasEnabledParentPlugin,
} from 'packages/feature-utils/plugins'
import { genProfileSystemRoles } from 'packages/feature-utils/profiles'
import { useSchoolLogo } from 'packages/feature-utils/schools'
import { $t } from 'packages/locale'
import {
  ConfigItem,
  MemberTypeEnum,
  customGroupApi$queryCustomGroupRoles,
} from 'packages/sdks-next/chalk'
import { School } from 'packages/sdks-next/platform'
import {
  ServiceEnum,
  SchoolConfigGroupNameEnum,
} from 'packages/shared-stores/configs'

import { RoleItem } from '@/features/system-settings/pages/Permissions/Profile/types'
import { systemSettings } from '@/features/system-settings/store'
import { DEFAULT_LOGO_HEIGHT, ConfigMap } from '@/features/system-settings/type'
import { useDispatch, useSelector } from '@/store'

export const lastSystemInfo: {
  foldedLogo: string
  logo: string
  logoHeight: number
  foldedLogoHeight: number
} = {
  foldedLogo: '',
  logo: '',
  logoHeight: DEFAULT_LOGO_HEIGHT,
  foldedLogoHeight: DEFAULT_LOGO_HEIGHT,
}

/**
 * 将接口数据转化成 form 数据
 *
 * @param args - args
 * @param args.configs - 配置项数组
 * @param args.school - 学校
 * @param args.dispatch - store 的 dispatch 方法
 * @returns form data
 */
export const useSystemSettingsForForm = ({
  configs,
  school,
  dispatch,
}: {
  configs: ConfigItem[] | null
  school?: School | null
  dispatch: ReturnType<typeof useDispatch>
}) =>
  React.useMemo(() => {
    if (!configs || !school) {
      return []
    }

    const configMap = reduce<ConfigItem, ConfigMap>(
      configs,
      (result, config) => {
        const group: SchoolConfigGroupNameEnum =
          config.group as SchoolConfigGroupNameEnum

        return {
          ...result,
          [config.group]: {
            ...(result[group] || {}),
            [config.name]: config,
          },
        }
      },
      {},
    )

    const hideSchoolName =
      configMap[SchoolConfigGroupNameEnum.Login]?.['hide_school_name']?.value

    const hideSystemName =
      configMap[SchoolConfigGroupNameEnum.Login]?.['hide_system_name']?.value

    const hideNavSchoolName =
      configMap[SchoolConfigGroupNameEnum.Global]?.['hide_nav_school_name']
        ?.value

    const hideNavSystemName =
      configMap[SchoolConfigGroupNameEnum.Global]?.['hide_nav_system_name']
        ?.value

    const name = configMap[SchoolConfigGroupNameEnum.Global]?.['name']?.value
    const ename = configMap[SchoolConfigGroupNameEnum.Global]?.['ename']?.value
    const logo = configMap[SchoolConfigGroupNameEnum.Global]?.['logo']?.value
    const foldedLogo =
      configMap[SchoolConfigGroupNameEnum.Global]?.['folded_logo']?.value

    const loginLogo =
      configMap[SchoolConfigGroupNameEnum.Login]?.['logo']?.value

    const links = configMap[SchoolConfigGroupNameEnum.Login]?.['links']?.value

    const logoHeight =
      configMap[SchoolConfigGroupNameEnum.Global]?.['logo_height']?.value

    const foldedLogoHeight =
      configMap[SchoolConfigGroupNameEnum.Global]?.['folded_logo_height']?.value

    const hideSchoolNameField: number[] = []
    if (hideSchoolName === 0) {
      hideSchoolNameField.push(1)
    }

    if (hideSystemName === 0) {
      hideSchoolNameField.push(0)
    }

    const hideNavName: number[] = []
    if (hideNavSchoolName === 0) {
      hideNavName.push(1)
    }

    if (hideNavSystemName === 0) {
      hideNavName.push(0)
    }

    const initSystemInfo = {
      foldedLogo,
      logo,
      logoHeight,
      foldedLogoHeight,
    }

    lastSystemInfo.foldedLogo = foldedLogo
    lastSystemInfo.logo = logo
    lastSystemInfo.logoHeight = logoHeight
    lastSystemInfo.foldedLogoHeight = foldedLogoHeight

    const userMenuDisplay = {
      showAppDownload:
        !!configMap[SchoolConfigGroupNameEnum.UserMenu]?.['show_app_download']
          ?.value,
    }

    dispatch.systemSettings.setSystemInfo({
      logoInfo: {
        schoolName: school?.name,
        schoolEName: school?.ename,
        name,
        ename,
        loginLogo,
        isDiffLogo: logo !== foldedLogo,
        isDiffLoginLogo: logo !== loginLogo,
        logo,
        foldedLogo,
        showNavSystemName: !hideNavSystemName,
        showNavSchoolName: !hideNavSchoolName,
        logoHeight,
        foldedLogoHeight,
      },
    })

    return [
      {
        schoolName: school?.name,
        schoolEname: school?.ename,
        systemName: name,
        systemEname: ename,
        systemLogo: {
          logo,
          foldedLogo,
          logoHeight:
            configMap[SchoolConfigGroupNameEnum.Global]?.['logo_height']?.value,
          foldedLogoHeight:
            configMap[SchoolConfigGroupNameEnum.Global]?.['folded_logo_height']
              ?.value,
          loginLogo,
        },
        links,
        hideSchoolName: hideSchoolNameField,
        hideNavName,
        loginLogo,
        loginNotice:
          configMap[SchoolConfigGroupNameEnum.Login]?.['notice']?.value,
        loginForgotPasswordTips:
          configMap[SchoolConfigGroupNameEnum.Login]?.['forgot_password_tips']
            ?.value,
        loginBackgroundImages:
          configMap[SchoolConfigGroupNameEnum.Login]?.['background_images']
            ?.value,
        preview: true,
        mainColor:
          configMap[SchoolConfigGroupNameEnum.Theme]?.['main_color']?.value,
        navbarBgColor:
          configMap[SchoolConfigGroupNameEnum.Theme]?.['navbar_bg_color']
            ?.value,
        userMenuDisplay,
      },
      initSystemInfo,
      lastSystemInfo,
    ] as const
  }, [configs, school, dispatch.systemSettings])

/**
 * 获取初始化的 logoInfo
 *
 * @param school - 学校
 * @returns 学校 logo 设置
 */
export const useLogoInfo = (school: School | null | undefined) => {
  const storeInfo = useSelector(state => state.systemSettings)

  const logoData = useSchoolLogo({
    school,
    defaultLogoInfo: systemSettings.state.logoInfo,
    extendLogoInfo: storeInfo.preview ? storeInfo.logoInfo : undefined,
  })

  return logoData
}

/**
 * 将 form data 转换为接口数据
 *
 * @param val - form data
 * @param currentSchoolId - 学校 id
 * @returns api data
 */
export const formatFormDataForApi = (val: any, currentSchoolId: any) => ({
  service: ServiceEnum.Chalk,
  scope: `school.${currentSchoolId}`,
  payload: [
    {
      group: SchoolConfigGroupNameEnum.Global,
      name: 'name',
      value: val.systemName,
    },
    {
      group: SchoolConfigGroupNameEnum.Global,
      name: 'ename',
      value: val.systemEname,
    },
    {
      group: SchoolConfigGroupNameEnum.Global,
      name: 'hide_nav_school_name',
      value: val.hideNavName.includes(1) ? 0 : 1,
    },
    {
      group: SchoolConfigGroupNameEnum.Global,
      name: 'hide_nav_system_name',
      value: val.hideNavName.includes(0) ? 0 : 1,
    },
    {
      group: SchoolConfigGroupNameEnum.Global,
      name: 'logo',
      value: val.systemLogo.logo,
    },
    {
      group: SchoolConfigGroupNameEnum.Global,
      name: 'logo_height',
      value: val.systemLogo.logoHeight || DEFAULT_LOGO_HEIGHT,
    },
    {
      group: SchoolConfigGroupNameEnum.Global,
      name: 'folded_logo',
      value: val.systemLogo.foldedLogo,
    },
    {
      group: SchoolConfigGroupNameEnum.Global,
      name: 'folded_logo_height',
      value: val.systemLogo.foldedLogoHeight || DEFAULT_LOGO_HEIGHT,
    },
    {
      group: SchoolConfigGroupNameEnum.Login,
      name: 'hide_school_name',
      value: val.hideSchoolName.includes(1) ? 0 : 1,
    },
    {
      group: SchoolConfigGroupNameEnum.Login,
      name: 'hide_system_name',
      value: val.hideSchoolName.includes(0) ? 0 : 1,
    },

    {
      group: SchoolConfigGroupNameEnum.Login,
      name: 'logo',
      value: val.loginLogo,
    },
    {
      group: SchoolConfigGroupNameEnum.Login,
      name: 'links',
      value: val.links,
    },
    {
      group: SchoolConfigGroupNameEnum.Login,
      name: 'notice',
      value: val.loginNotice,
    },
    {
      group: SchoolConfigGroupNameEnum.Login,
      name: 'forgot_password_tips',
      value: val.loginForgotPasswordTips,
    },
    {
      group: SchoolConfigGroupNameEnum.Login,
      name: 'background_images',
      value: val.loginBackgroundImages,
    },
    /**
     * 主题色
     */
    {
      group: SchoolConfigGroupNameEnum.Theme,
      name: 'main_color',
      value: val.mainColor,
    },
    /**
     * 导航色
     */
    {
      group: SchoolConfigGroupNameEnum.Theme,
      name: 'navbar_bg_color',
      value: val.navbarBgColor,
    },
    {
      group: SchoolConfigGroupNameEnum.UserMenu,
      name: 'show_app_download',
      value: val.userMenuDisplay.showAppDownload ? 1 : 0,
    },
  ],
})

/**
 * 获取档案权限的所有角色
 *
 * @returns 档案权限的所有角色 RoleItem[]
 */
export const useAllProfileRoles = () => {
  const { data: customGroupRoles } =
    customGroupApi$queryCustomGroupRoles.useApi()

  const adminClassEnabled = useHasEnabledParentPlugin(ModuleEnum.AdminClass)
  const dormEnabled = useHasEnabledParentPlugin(ModuleEnum.Dorm)

  const systemRoles = genProfileSystemRoles({
    adminClassEnabled,
    dormEnabled: false,
  })

  const allRoles: RoleItem[] = [
    ...systemRoles,
    ...(customGroupRoles
      ?.filter(v => v.isAdmin)
      .map(v => ({ value: v.machineName, label: v.name })) ?? []),
    // 产品要求放在最后
    ...(dormEnabled
      ? [
          {
            value: MemberTypeEnum.DormManager,
            label: $t('宿舍管理员'),
          },
        ]
      : []),
  ]

  return allRoles
}
