import { lazy } from 'react'

import { ModuleEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import { getRouteSlots } from '@/plugins'

const getAdminGradeListRoutes = () => [
  {
    path: 'admin/grades',
    getTitle: () => $t('成绩管理'),
    module: ['courses'],
    name: 'AdminGrades',
    requireModules: [ModuleEnum.ClassAssessment],
    component: lazy(() =>
      import('@/features/grades/pages/admin/Main').then(m => ({
        default: m.AdminGradeMain,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreGradeRead],
    subRoutes: [
      {
        name: 'Assessments',
        path: 'assessments',
        getTitle: () => $t('成绩信息'),
        component: lazy(() =>
          import('@/features/grades/pages/admin/List').then(m => ({
            default: m.GradeList,
          })),
        ),
        requirePermissions: [PermissionNameEnum.CoreGradeRead],
      },
      {
        name: 'Students',
        path: 'list/students',
        getTitle: () => $t('学生成绩'),
        component: lazy(() =>
          import('@/features/grades/pages/admin/Students').then(m => ({
            default: m.StudentsList,
          })),
        ),
        requirePermissions: [PermissionNameEnum.CoreGradeRead],
        subRoutes: [
          {
            path: 'normal',
            name: 'StudentsNormalList',
            getTitle: () => $t('在校学生'),
            component: lazy(() =>
              import('@/features/grades/pages/admin/Students/Normal').then(
                m => ({
                  default: m.StudentsNormalList,
                }),
              ),
            ),
          },
          {
            path: 'archived',
            name: 'StudentsArchivedList',
            getTitle: () => $t('已归档学生'),
            component: lazy(() =>
              import('@/features/grades/pages/admin/Students/Archived').then(
                m => ({
                  default: m.StudentsArchivedList,
                }),
              ),
            ),
          },
        ],
      },
      {
        path: 'assessment-items',
        name: 'AssessmentItems',
        getTitle: () => $t('评价项管理'),
        requirePermissions: [PermissionNameEnum.CoreGradeWrite],
        component: lazy(() =>
          import('@/features/grades/pages/admin/Item/PublishItemList').then(
            m => ({
              default: m.AdminPublishItemList,
            }),
          ),
        ),
        subRoutes: [
          {
            path: 'needed-review',
            getTitle: () => $t('需审核的'),
            component: lazy(() =>
              import(
                '@/features/grades/pages/admin/Item/PublishItemList/NeededReviewList'
              ).then(m => ({
                default: m.AdminNeededReviewItemList,
              })),
            ),
          },
          {
            path: 'unneeded-review',
            getTitle: () => $t('无需审核的'),
            component: lazy(() =>
              import(
                '@/features/grades/pages/admin/Item/PublishItemList/UnneededReviewList'
              ).then(m => ({
                default: m.AdminUnneededReviewItemList,
              })),
            ),
          },
        ],
      },
      ...getRouteSlots('gradeSubRoutes'),
      {
        path: 'templates',
        getTitle: () => $t('模板'),
        component: lazy(() =>
          import('@/features/grades/pages/admin/Templates/List').then(m => ({
            default: m.TemplateList,
          })),
        ),
        requirePermissions: [PermissionNameEnum.CoreGradeWrite],
      },
      {
        name: 'Settings',
        path: 'settings',
        getTitle: () => $t('设置'),
        component: lazy(() =>
          import('@/features/grades/pages/admin/Settings').then(m => ({
            default: m.Settings,
          })),
        ),
        requirePermissions: [PermissionNameEnum.CoreGradeWrite],
      },
    ],
  },
]

/**
 * 获取成绩管理的后台路由
 *
 * @returns RouteConfig[]
 */
export const getAdminRoutes = (): RouteConfig[] => [
  ...getAdminGradeListRoutes(),
  {
    path: 'admin/grades/students/:id',
    getTitle: () => $t('成绩详情'),
    name: 'GradesDetails',
    requireModules: [ModuleEnum.ClassAssessment],
    component: lazy(() =>
      import('@/features/grades/pages/admin/Students/Show/index').then(m => ({
        default: m.StudentGrades,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreGradeRead],
  },
  {
    path: 'admin/grades/templates/new',
    getTitle: () => $t('新增成绩结构模板'),
    component: lazy(() =>
      import('@/features/grades/pages/admin/Templates/New').then(m => ({
        default: m.TemplatesNew,
      })),
    ),
    requireModules: [ModuleEnum.ClassAssessment],
    requirePermissions: [PermissionNameEnum.CoreGradeWrite],
  },
  {
    path: 'admin/grades/templates/:id/edit',
    getTitle: () => $t('编辑成绩结构模板'),
    component: lazy(() =>
      import('@/features/grades/pages/admin/Templates/New').then(m => ({
        default: m.TemplatesNew,
      })),
    ),
    requireModules: [ModuleEnum.ClassAssessment],
    requirePermissions: [PermissionNameEnum.CoreGradeWrite],
  },
  {
    path: 'admin/grades/create-items',
    getTitle: () => $t('批量新增成绩结构'),
    component: lazy(() =>
      import('@/features/grades/pages/admin/BatchCreate').then(m => ({
        default: m.AdminBatchCreateItems,
      })),
    ),
    requireModules: [ModuleEnum.ClassAssessment],
    requirePermissions: [PermissionNameEnum.CoreGradeWrite],
  },
  {
    path: 'admin/grades/edit-items',
    getTitle: () => $t('编辑成绩结构'),
    component: lazy(() =>
      import('@/features/grades/pages/admin/Item/List').then(m => ({
        default: m.AdminItemList,
      })),
    ),
    requireModules: [ModuleEnum.ClassAssessment],
    requirePermissions: [PermissionNameEnum.CoreGradeWrite],

    subRoutes: [
      {
        path: 'dimensions',
        getTitle: () => $t('维度'),
        component: lazy(() =>
          import('@/features/grades/pages/admin/Item/DimensionList').then(
            m => ({
              default: m.AdminDimensionList,
            }),
          ),
        ),
      },
      {
        path: 'sub-dimensions',
        getTitle: () => $t('子维度'),
        component: lazy(() =>
          import('@/features/grades/pages/admin/Item/SubDimensionList').then(
            m => ({
              default: m.AdminSubDimensionList,
            }),
          ),
        ),
      },
      {
        path: 'items',
        getTitle: () => $t('评价项'),
        component: lazy(() =>
          import('@/features/grades/pages/admin/Item/ItemList').then(m => ({
            default: m.AdminItemList,
          })),
        ),
      },
    ],
  },
]
