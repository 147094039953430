/**
 * @file 学期管理 menus
 */

import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { isMenuOpenedAtom } from 'packages/feature-utils/menus'
import {
  hasEnabledPluginAtom,
  ModuleEnum,
} from 'packages/feature-utils/plugins'
import { schoolCalendarsAtom } from 'packages/feature-utils/school-calendars'
import { $t } from 'packages/locale'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'
import { Schcal } from 'packages/sdks-next/scms'
import { MenuItemUnion } from 'packages/web-layout/types'

export const GLOBAL_SCHOOL_CALENDAR = 'all'

export const adminSemesterMenu = loadable(
  atom(get => {
    const moduleEnabled = get(hasEnabledPluginAtom(ModuleEnum.Semester))

    if (!moduleEnabled) return []

    const menus: MenuItemUnion[] = [
      {
        prefix: ['system'],
        path: '/admin/semesters/manager',
        label: $t('学期管理'),
        name: 'semesters',
        icon: 'Semester' as const,
        shortcutIcon: 'SemesterSolid+769397',
        permission: [PermissionNameEnum.CoreSemesterWrite],
        sort: 700,
      },
    ]

    const menuOpened = get(isMenuOpenedAtom(['system']))

    const schoolCalendars: Schcal[] = menuOpened ? get(schoolCalendarsAtom) : []

    menus.push({
      prefix: ['system'],
      path: '/admin/semesters/school-calendar',
      label: $t('校历管理'),
      name: 'school-calendar',
      icon: 'SchoolCalendar' as const,
      shortcutIcon: 'SchoolCalendarSolid+17A0DE',
      permission: [PermissionNameEnum.CoreSemesterWrite],
      sort: 701,
      subMenus: [
        {
          label: $t('全校校历'),
          path: `/admin/semesters/school-calendar/${GLOBAL_SCHOOL_CALENDAR}`,
        },
        ...schoolCalendars.map(s => ({
          label: s.name,
          path: `/admin/semesters/school-calendar/${s.id}`,
        })),
        {
          label: $t('设置'),
          path: `/admin/semesters/school-calendar/settings`,
        },
      ],
    })

    return menus
  }),
)
