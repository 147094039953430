import { InitFeatureParams } from '@/features/types'

import { adminAdminClassMenuProvider } from './menus'
import { getRoutes, modalRoutes } from './routes'
import { todos } from './todos'

/**
 * 初始化行政班模块
 *
 * @param props - 参数
 */
export const initAdminClasses = (props: InitFeatureParams) => {
  const { addRoutes, addMenuProvider, registerTodos } = props

  addRoutes(getRoutes(), modalRoutes)
  addMenuProvider(adminAdminClassMenuProvider)
  registerTodos(todos)
}
