/**
 * @file 审批服务 menus
 */

import { atom } from 'jotai'

import {
  findManageableChildPluginsAtom,
  PluginEnum,
  hasUsablePluginAtom,
  getPluginLabel,
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
} from 'packages/feature-utils/plugins'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminWorkflowMenu = atom(get => {
  const workflowPlugins = get(
    findManageableChildPluginsAtom(PluginEnum.Workflow),
  )

  const enabledPlugin = get(findEnabledPluginsAtom(PluginEnum.Workflow))?.find(
    plugin => !plugin.parentId,
  )

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  if (!workflowPlugins?.length) return []

  return [
    {
      prefix: [PluginCategoryEnum.通用工具],
      name: PluginEnum.Workflow,
      label: $t('审批管理'),
      sort: 710,
      type: MenuType.AdminApps,
      icon: 'Approval' as const,
      shortcutIcon,
      subMenus: workflowPlugins.map(plugin => ({
        label: getPluginLabel(plugin),
        path: `/admin/workflows/${plugin.id}`,
        icon: 'Approval' as const,
      })),
    },
  ]
})

export const userWorkflowMenu = atom(get => {
  const hasManageablePermission = get(
    hasManageableParentPluginAtom(PluginEnum.WorkflowCenter),
  )

  const hasUsablePermission = get(
    hasUsablePluginAtom(PluginEnum.WorkflowCenter),
  )

  if (!hasManageablePermission && !hasUsablePermission) {
    return []
  }

  return [
    {
      name: SchoolPluginsEnum.WorkflowCenter,
      label: $t('审批中心'),
      path: '/workflows/center',
      type: MenuType.Apps,
      subMenus: [
        {
          label: $t('全部审批'),
          path: '/workflows/center/all',
          icon: 'Approval' as const,
        },
        {
          label: $t('我发起的'),
          path: '/workflows/center/initiated',
          icon: 'PersonPlus' as const,
        },
        {
          label: $t('我处理的'),
          path: '/workflows/center/to-approve',
          icon: 'PersonCheck' as const,
        },
        {
          label: $t('抄送我的'),
          path: '/workflows/center/copied',
          icon: 'CopyFor' as const,
        },
      ],
    },
  ]
})
