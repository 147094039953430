/**
 * @file 课程选择器语法糖
 *
 * 语法糖提供两个方法
 * pickCourses - 选择 course，接收 Course 的大部分参数，返回选中的 ids 与 selectedRows，如果你想要重置 pickCourse 接收一个 reset 参数。
 * destoryRootCoursePicker - 主动释放 RootCoursePicker
 */
import { noop, OptionalProps } from '@seiue/util'
import React, { lazy, Suspense } from 'react'

import { registerGlobalPicker } from 'packages/components/Modal'
import { Course } from 'packages/sdks-next/scms'

import type { Props } from '@/components/Roles/Pickers/common'

const CoursePicker = lazy(() =>
  import('./index').then(m => ({ default: m.CoursePicker })),
)

type CursorProps = Omit<Props<Course>, 'onSubmit'> & {
  onSubmit: (ids: number[], rows?: Course[]) => boolean
}

type Options = Omit<
  OptionalProps<CursorProps, 'onSubmit'>,
  'visible' | 'onCancel'
>

type OnSelectParameters = Parameters<CursorProps['onSubmit']>
type PickReturnType = {
  flatIds?: number[]
  ids: OnSelectParameters[0]
  rows: OnSelectParameters[1]
}

type PickOptions = Options & {
  /**
   * 选择器标题
   */
  title?: string
  reset?: boolean
}

const { getPicker, getDestory, setPicker, setDestory } =
  registerGlobalPicker<
    (options?: PickOptions) => Promise<PickReturnType | false>
  >('rootCoursePicker')

/**
 * 选择课程
 *
 * @param options - 选择器参数
 * @returns Promise<PickReturnType | false> | undefined
 */
export const pickCourses: (
  options?: PickOptions,
) => Promise<PickReturnType | false> | undefined = options =>
  getPicker()?.(options)

/**
 * 主动释放 RootCoursePicker
 */
export const destoryRootCoursePicker = () => {
  getDestory()?.()
}

type PickerWrapperProps = Omit<CursorProps, 'visible'>
type PickerWrapperRef = {
  hide: () => void
}

/**
 * 课程选择器
 *
 * @param param - Argument Object
 * @param param.onSubmit - 选择完成回调
 * @param param.onCancel - 取消回调
 * @param param.props - 其他参数
 * @returns React.ReactNode
 */
const PickerWrapper = React.forwardRef<PickerWrapperRef, PickerWrapperProps>(
  ({ onSubmit, onCancel, ...props }, ref) => {
    const [visible, setVisible] = React.useState(true)

    const innerSubmit = (
      ids: OnSelectParameters[0],
      rows: OnSelectParameters[1],
    ) => {
      const result = onSubmit(ids, rows)
      if (!result) return
      setVisible(false)
    }

    const innerCancel = () => {
      onCancel()
      setVisible(false)
    }

    React.useImperativeHandle(ref, () => ({
      show: () => {
        setVisible(true)
      },
      hide: () => {
        setVisible(false)
      },
    }))

    return (
      <Suspense fallback={null}>
        <CoursePicker
          {...props}
          visible={visible}
          onSubmit={innerSubmit}
          onCancel={innerCancel}
        />
      </Suspense>
    )
  },
)

const defaultSubmit = () => true

/**
 * 放在 App 组件根部的组件选择器，以供方法调用。
 *
 * @constructor
 */
export const RootCoursePicker: React.FC = () => {
  const [inited, setInited] = React.useState(false)
  const [options, setOptions] = React.useState<Options | undefined>()
  const pickerRef = React.useRef<any>()
  const submit =
    React.useRef<
      (ids: OnSelectParameters[0], rows: OnSelectParameters[1]) => boolean
    >(defaultSubmit)

  const cancel = React.useRef<() => void>(noop)

  React.useEffect(() => {
    setPicker(async nextOptions => {
      const { reset, ...newOptions } = nextOptions || {}
      if (!inited) setInited(true)
      else if (reset) {
        setInited(false)
        // 将 setInited 放在下一帧执行，保证 inited 先被设置为 false
        requestAnimationFrame(() => {
          setInited(true)
        })
      }

      setOptions({
        ...options,
        ...newOptions,
      })

      const { onSubmit } = nextOptions || {}
      return new Promise(resolve => {
        submit.current = (ids, rows) => {
          const result = onSubmit ? onSubmit(ids, rows) : true

          if (!result) return false

          resolve({
            ids,
            rows,
          })

          return result
        }

        cancel.current = () => {
          resolve(false)
        }

        /*
         * 刚初始化时，PickerWrapper 还没渲染完毕，pickerRef 会处于无值状态
         * 别担心，PickerWrapper 第一次初始化默认为显示状态
         */
        pickerRef.current?.show()
      })
    })

    setDestory(async () => {
      pickerRef.current?.hide()
      setInited(false)
    })
  }, [inited, options])

  if (!inited) return null

  return (
    <PickerWrapper
      ref={pickerRef}
      {...options}
      onSubmit={submit.current}
      onCancel={cancel.current}
    />
  )
}
