/**
 * @file 四种角色的菜单路由
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const menuRoutes: RouteConfig[] = [
  {
    path: 'admin/users',
    name: 'AdminUsers',
    getTitle: () => $t('用户管理'),
    module: ['users'],
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'students',
        name: 'Students',
        getTitle: () => $t('学生账号管理'),
        module: ['users'],
        component: lazy(() =>
          import('../pages/students/List').then(m => ({
            default: m.StudentList,
          })),
        ),
        requirePermissions: [PermissionNameEnum.CoreUserRead],
        subRoutes: [
          {
            path: 'normal',
            name: 'Normal',
            getTitle: () => $t('在校学生'),
            component: lazy(() =>
              import('../pages/students/List/Normal').then(m => ({
                default: m.NormalList,
              })),
            ),
          },
          {
            path: 'archived',
            name: 'Archived',
            getTitle: () => $t('已归档学生'),
            component: lazy(() =>
              import('../pages/students/List/Archived').then(m => ({
                default: m.ArchivedList,
              })),
            ),
          },
          {
            path: 'record',
            getTitle: () => $t('操作日志'),
            component: lazy(() =>
              import('../pages/students/List/Record').then(m => ({
                default: m.RecordList,
              })),
            ),
          },
          {
            path: 'settings',
            getTitle: () => $t('设置'),
            component: lazy(() =>
              import('../pages/students/List/Settings').then(m => ({
                default: m.StudentSettings,
              })),
            ),
          },
        ],
      },
      {
        path: 'teachers',
        name: 'Teachers',
        getTitle: () => $t('教师账号管理'),
        component: lazy(() =>
          import('../pages/teachers/List').then(m => ({
            default: m.TeacherList,
          })),
        ),
        requirePermissions: [PermissionNameEnum.CoreUserRead],
        subRoutes: [
          {
            path: 'normal',
            name: 'Normal',
            getTitle: () => $t('在校教师'),
            component: lazy(() =>
              import('../pages/teachers/List/Normal').then(m => ({
                default: m.NormalList,
              })),
            ),
          },
          {
            path: 'archived',
            name: 'Archived',
            getTitle: () => $t('已归档教师'),
            component: lazy(() =>
              import('../pages/teachers/List/Archived').then(m => ({
                default: m.ArchivedList,
              })),
            ),
          },
          {
            path: 'record',
            getTitle: () => $t('操作日志'),
            component: lazy(() =>
              import('../pages/teachers/List/Record').then(m => ({
                default: m.RecordList,
              })),
            ),
          },
          {
            path: 'settings',
            getTitle: () => $t('设置'),
            component: lazy(() =>
              import('../pages/teachers/List/Settings').then(m => ({
                default: m.TeacherSettings,
              })),
            ),
          },
        ],
      },
      {
        path: 'guardians',
        name: 'Guardians',
        getTitle: () => $t('家长账号管理'),
        module: ['users'],
        component: lazy(() =>
          import('../pages/guardians/List').then(m => ({
            default: m.GuardianList,
          })),
        ),
        requirePermissions: [PermissionNameEnum.CoreUserRead],
        subRoutes: [
          {
            path: 'normal',
            name: 'Normal',
            getTitle: () => $t('家长'),
            component: lazy(() =>
              import('../pages/guardians/List/Normal').then(m => ({
                default: m.NormalList,
              })),
            ),
          },
          {
            path: 'archived',
            getTitle: () => $t('已归档家长'),
            component: lazy(() =>
              import('../pages/guardians/List/Archived').then(m => ({
                default: m.ArchivedList,
              })),
            ),
          },
          {
            path: 'record',
            getTitle: () => $t('操作日志'),
            component: lazy(() =>
              import('../pages/guardians/List/Record').then(m => ({
                default: m.RecordList,
              })),
            ),
          },
          {
            path: 'settings',
            getTitle: () => $t('设置'),
            component: lazy(() =>
              import('../pages/guardians/List/Settings').then(m => ({
                default: m.GuardianSettings,
              })),
            ),
          },
        ],
      },
      {
        path: 'staffs',
        getTitle: () => $t('校外人员账号管理'),
        name: 'Staffs',
        module: ['users'],
        component: lazy(() =>
          import('../pages/staffs/List').then(m => ({ default: m.StaffList })),
        ),
        requirePermissions: [PermissionNameEnum.CoreUserRead],
        subRoutes: [
          {
            path: 'normal',
            name: 'Normal',
            getTitle: () => $t('校外人员'),
            component: lazy(() =>
              import('../pages/staffs/List/Normal').then(m => ({
                default: m.NormalList,
              })),
            ),
          },
          {
            path: 'archived',
            getTitle: () => $t('已归档校外人员'),
            component: lazy(() =>
              import('../pages/staffs/List/Archived').then(m => ({
                default: m.ArchivedList,
              })),
            ),
          },
          {
            path: 'record',
            getTitle: () => $t('操作日志'),
            component: lazy(() =>
              import('../pages/staffs/List/Record').then(m => ({
                default: m.RecordList,
              })),
            ),
          },
          {
            path: 'settings',
            getTitle: () => $t('设置'),
            component: lazy(() =>
              import('../pages/staffs/List/Settings').then(m => ({
                default: m.StaffSettings,
              })),
            ),
          },
        ],
      },
    ],
  },
]
