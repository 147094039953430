import { atom } from 'jotai'

import { hasEnabledPluginAtom } from 'packages/feature-utils/plugins'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

export const adminMenuProvider = atom(get => {
  if (!get(hasEnabledPluginAtom(SchoolModulesEnum.SemesterReview))) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.德育管理],
      name: SchoolModulesEnum.SemesterReview,
      label: $t('学期评语'),
      shortcutIcon: 'Notice+D45586',
      sort: 708,
      permission: [PermissionNameEnum.CoreSemesterReviewRead],
      subMenus: [
        {
          label: $t('全部学期评语'),
          path: '/admin/semester-review',
          icon: 'Notice' as const,
        },
      ],
    },
  ]
})
