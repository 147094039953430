import { InitFeatureParams } from '@/features/types'

// import {getAdminUserMenus} from "@/features/users/menus";
import { adminMenuProvider } from './menus'
import { routes } from './routes'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initUsers = ({
  addRoutes,
  addMenuProvider,
}: InitFeatureParams) => {
  addRoutes(routes)
  addMenuProvider(adminMenuProvider)
}
