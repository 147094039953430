/**
 * @file 我的档案 - menus
 */

import { useCurrentReflection } from 'packages/features/sessions/utils'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { RoleEnum } from 'packages/sdks-next/chalk'

import type { UseMenus } from '../types'

export const useTeacherProfilesMenu: UseMenus = () => {
  const { role } = useCurrentReflection()

  return role === RoleEnum.Teacher
    ? [
        {
          label: $t('档案'),
          path: '/teacher-profiles/me',
          icon: 'Archives' as const,
          height: 40,
          type: MenuType.Default,
          sort: 0,
        },
      ]
    : []
}
