import 'react-app-polyfill/stable'
import '@seiue/polyfill'

import { getPersistor } from '@rematch/persist'
import { initAxios } from '@seiue/axios'
import { env } from '@seiue/env'
import { initSentry } from '@seiue/sentry'
import { userAgent } from '@seiue/ua'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'

import { getOAuthInfoFn, getReflectionFn } from 'packages/features/sessions'

import packageJSON from '../package.json'

import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { store } from './store'

// eslint-disable-next-line
userAgent.blockIncompatibleBrowsers()

const ENV = env('ENV')

initSentry({
  dsn: 'https://c43bcacf4e664d309f952043a934f8c2@sentry.seiue.com/46',
  release: packageJSON.version,
})

initAxios({
  getOAuthInfo: getOAuthInfoFn(store),
  getReflection: getReflectionFn(store),
})

const queryClient = new QueryClient()

ReactDOM.render(
  <ReduxProvider store={store}>
    {ENV !== 'test' ? (
      <PersistGate persistor={getPersistor()}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </PersistGate>
    ) : (
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    )}
  </ReduxProvider>,

  document.getElementById('root'),
)

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister()
