import { lazy } from 'react'

import { $t } from 'packages/locale'

import { InitFeatureParams } from '../types'

/**
 * 初始化讨论
 *
 * @param initParams - 初始化参数
 */
export const initDiscussions = (initParams: InitFeatureParams) => {
  initParams.addRoutes(
    [],
    [
      {
        name: 'EditDiscussionTopicHistoryModal',
        getTitle: () => $t('编辑记录'),
        component: lazy(() =>
          import('./pages/EditHistoryModal').then(m => ({
            default: m.EditDiscussionTopicHistoryModal,
          })),
        ),
      },
      {
        name: 'ReplyDiscussionConversationModal',
        getTitle: () => $t('查看对话'),
        component: lazy(() =>
          import('./pages/ReplyConversationModal').then(m => ({
            default: m.ReplyDiscussionConversationModal,
          })),
        ),
      },
    ],
  )
}
