/**
 * @file 场地待办注册入口
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.VenueOrderApprove,
    Render: lazy(() =>
      import('./VenueOrderApproveTodo').then(m => ({
        default: m.VenueOrderApproveTodo,
      })),
    ),
  },
]
