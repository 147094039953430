/**
 * @file useInitLogoInfo 初始化 logo 相关配置数据数据
 */

import { Dictionary } from '@seiue/util'

import { ConfigItem } from 'packages/sdks-next/chalk'

export type ConfigGroup = { [key: string]: ConfigItem }
export type ConfigMap = Dictionary<ConfigGroup>

export type SystemSettingsRouteParams = {
  'AdminSystemSettingPermissions.Core': null
  'AdminSystemSettingPermissions.Profile': null
  AdminSystemSettingSchoolConfigs: null
}

export const DEFAULT_LOGO_HEIGHT = 26
