import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const routes: RouteConfig[] = [
  {
    name: 'AdminPlaces',
    path: 'admin/places',
    getTitle: () => $t('空间管理'),
    module: ['system'],
    requirePermissions: [PermissionNameEnum.CorePlaceRead],
    component: lazy(() =>
      import('./pages/List').then(m => ({ default: m.AdminPlacesIndex })),
    ),
    subRoutes: [
      {
        path: 'list',
        name: 'List',
        getTitle: () => $t('空间列表'),
        component: lazy(() =>
          import('./pages/List/PlaceList').then(m => ({
            default: m.PlacesList,
          })),
        ),
      },
      {
        path: 'iot-devices',
        name: 'IotDevices',
        getTitle: () => $t('物联设备'),
        component: lazy(() =>
          import('./pages/IotDeviceList').then(m => ({
            default: m.IotDeviceList,
          })),
        ),
      },
      {
        path: 'logs',
        getTitle: () => $t('操作日志'),
        component: lazy(() =>
          import('./pages/Logs/LogList').then(m => ({ default: m.LogList })),
        ),
      },
    ],
  },
  {
    path: 'admin/places/fields',
    name: 'AdminPlacesFields',
    getTitle: () => $t('字段管理'),
    requirePermissions: [PermissionNameEnum.CorePlaceWrite],
    component: lazy(() =>
      import('./pages/FieldManagement').then(m => ({
        default: m.FieldManagement,
      })),
    ),
  },
  {
    path: 'admin/places/new',
    name: 'AdminPlacesNew',
    getTitle: () => $t('创建空间'),
    requirePermissions: [PermissionNameEnum.CorePlaceWrite],
    component: lazy(() =>
      import('./pages/Edit').then(m => ({ default: m.Edit })),
    ),
  },
  {
    path: 'admin/places/:placeId/edit',
    name: 'AdminPlacesEdit',
    getTitle: () => $t('编辑空间'),
    requirePermissions: [PermissionNameEnum.CorePlaceWrite],
    component: lazy(() =>
      import('./pages/Edit').then(m => ({ default: m.Edit })),
    ),
  },
  {
    path: 'admin/places/batch-edit',
    name: 'AdminPlacesBatchEdit',
    getTitle: () => $t('批量编辑空间'),
    requirePermissions: [PermissionNameEnum.CorePlaceWrite],
    component: lazy(() =>
      import('./pages/Edit/BatchEdit').then(m => ({
        default: m.PlaceBatchEdit,
      })),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'NewIotDeviceModal',
    getTitle: () => $t('新增设备'),
    component: lazy(() =>
      import('./pages/NewIotDevice').then(m => ({
        default: m.NewIotDeviceModal,
      })),
    ),
  },
  {
    name: 'EditIotDeviceModal',
    getTitle: () => $t('编辑设备'),
    component: lazy(() =>
      import('./pages/NewIotDevice').then(m => ({
        default: m.EditIotDeviceModal,
      })),
    ),
  },
]
