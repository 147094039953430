import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { getAdminRoutes } from './admin'
import { getUserRoutes } from './user'

/**
 * 行政班路由配置
 *
 * @returns routes
 */
export const getRoutes = (): RouteConfig[] => [
  ...getAdminRoutes(),
  ...getUserRoutes(),
]

export const modalRoutes = [
  {
    name: 'AdminClassInputAttendanceModal',
    getTitle: () => $t('录入考勤'),
    component: lazy(() =>
      import('../pages/user/Attendance/InputAttendanceModal').then(m => ({
        default: m.InputAttendanceModal,
      })),
    ),
  },
]
