import { lazy } from 'react'

import { $t } from 'packages/locale'

export const routes = [
  {
    name: 'Home',
    path: '',
    getTitle: () => $t('首页'),
    component: lazy(() =>
      import('@/features/home/pages/Home').then(m => ({ default: m.Home })),
    ),
  },
  {
    name: 'SchoolCalendar',
    path: 'school-calendar',
    getTitle: () => $t('校历'),
    component: lazy(() =>
      import('@/features/home/pages/SchoolCalendar').then(m => ({
        default: m.SchoolCalendar,
      })),
    ),
  },
]
