/**
 * @file 【学生】强制评教弹窗
 */
import { isNaN, useOnUpdate } from '@seiue/util'
import React, { lazy, Suspense, useEffect, useState } from 'react'

import { useCounters, CounterSubjectEnum } from 'packages/features/counter'
import { useLoadEvaluation } from 'packages/features/evaluations/utils/apis'

import { RootInterrupt } from '@/uikit'

const EvaluationsEntryModal = lazy(() =>
  import('./EntryModal').then(m => ({ default: m.EvaluationsEntryModal })),
)

/**
 * 强制评教弹窗
 *
 * @returns 强制评教弹窗
 */
export const EvaluationsForceModal: React.FC = () => {
  const { counterSubjectIds, skip } = useCounters(
    CounterSubjectEnum.ForcedEvaluations,
  )

  const [ids, setIds] = useState<string[]>([])

  const isDone = !ids.length

  const goNext = () => {
    setIds(prevIds => {
      const nextIds = [...prevIds]

      nextIds.shift()

      return nextIds
    })
  }

  useOnUpdate(() => {
    setIds(counterSubjectIds)
  }, [counterSubjectIds.join(',')])

  const canShow = RootInterrupt.useCanShow('evaluationsForce', isDone)

  const evaluationId = Number(ids[0])

  const { data: evaluation, loading: loadingEvaluation } = useLoadEvaluation(
    {
      id: evaluationId,
      query: {
        expand: ['isFilled'],
      },
    },
    {
      disable: isNaN(evaluationId),
    },
  )

  useEffect(() => {
    if (evaluation?.isFilled && evaluation?.id === evaluationId) {
      skip(CounterSubjectEnum.ForcedEvaluations, `${evaluationId}`)
      goNext()
    }
  }, [evaluation?.isFilled, evaluation?.id, evaluationId, skip])

  if (!canShow || isDone || loadingEvaluation) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <EvaluationsEntryModal
        forced={true}
        evaluationId={evaluationId}
        reloadForcedEvaluations={goNext}
      />
    </Suspense>
  )
}
