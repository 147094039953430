/**
 * @file 档案 menus
 */

import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasEnabledPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { useHasProfilePermissionOfStudent } from 'packages/features/profiles/utils/apis'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
  RoleEnum,
  reflectionApi$loadGuardian,
} from 'packages/sdks-next/chalk'

import { getStoreForAtom } from '@/store'

import type { UseMenus } from '../types'

export const adminGoalMenu = atom(get => {
  const store = getStoreForAtom(get)

  const hasAcademicGoal = get(hasEnabledPluginAtom(PluginEnum.AcademicGoal))
  if (!hasAcademicGoal) {
    return []
  }

  const enabledPlugin = get(
    findEnabledPluginsAtom(PluginEnum.AcademicGoal),
  )?.[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.学生成长],
      name: SchoolModulesEnum.Goal,
      label: $t('目标管理'),
      shortcutIcon,
      sort: 701,
      subMenus: [
        {
          permission: () =>
            store.session.currentReflection?.role === RoleEnum.Shadow,
          path: '/admin/profiles/goals',
          label: $t('个人目标'),
          icon: 'Target' as const,
        },
        {
          path: '/admin/profiles/feedbacks',
          label: $t('反馈列表'),
          permission: [PermissionNameEnum.CoreUserRead],
          icon: 'Questionnaire' as const,
        },
      ],
    },
  ]
})

/**
 * 定义学生档案菜单
 *
 * @returns 菜单
 */
export const useStudentProfileMenus = () => {
  const currentReflection = useCurrentReflection()
  const { role } = currentReflection

  return role === RoleEnum.Student
    ? [
        {
          type: MenuType.Default,
          path: '/profiles/me',
          label: $t('档案'),
          icon: 'Archives' as const,
          height: 40,
        },
      ]
    : []
}

/**
 * 定义家长查看学生档案菜单
 *
 * @returns 菜单
 */
export const useStudentProfileMenusForGuardian: UseMenus = () => {
  const currentReflection = useCurrentReflection()

  const { data: guardian } = reflectionApi$loadGuardian.useApi(
    {
      id: currentReflection.id,
      query: {
        tryExpand: ['pupil'],
      },
    },
    {
      disable: currentReflection.role !== RoleEnum.Guardian,
    },
  )

  const [hasPermission] = useHasProfilePermissionOfStudent(guardian?.pupilId)

  return hasPermission
    ? [
        {
          type: MenuType.Default,
          path: `/admin/profiles/${guardian?.pupilId}`,
          label: $t('学生档案'),
          icon: 'Archives',
          height: 40,
        },
      ]
    : []
}
