/**
 * @file 档案 routes
 */
import { lazy } from 'react'

import { GroupPermissionTypeEnum } from 'packages/features/groups/types'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
  RoleEnum,
} from 'packages/sdks-next/chalk'

import { getProfileSubRoutes } from './profileSubRoutes'

export const SZGradeReportsGroupPermissions = [
  GroupPermissionTypeEnum.GradeReportExamReport,
  GroupPermissionTypeEnum.GradeReportSemesterReport,
  GroupPermissionTypeEnum.GradeReportGlobalAcademicReport,
  GroupPermissionTypeEnum.GradeReportChuguoReport,
  GroupPermissionTypeEnum.GradeReportResitReport,
]

/**
 * 获取档案路由
 *
 * @returns 路由
 */
export const getRoutes = (): RouteConfig[] => {
  const routes: RouteConfig[] = [
    {
      name: 'AdminGoalsHome',
      path: 'admin/goals',
      getTitle: () => $t('目标管理'),
      component: lazy(() =>
        import('@/features/goals/pages/admin').then(m => ({
          default: m.Home,
        })),
      ),
    },
    {
      name: 'AdminProfilesGoals',
      path: 'admin/profiles/goals',
      requireRoles: [RoleEnum.Shadow],
      module: [PluginCategoryEnum.学生成长, SchoolModulesEnum.Goal],
      getTitle: () => $t('个人管理'),
      component: lazy(() =>
        import('@/features/goals/pages/admin').then(m => ({
          default: m.Index,
        })),
      ),
      subRoutes: [
        {
          name: 'List',
          path: 'list',
          getTitle: () => $t('目标列表'),
          component: lazy(() =>
            import('@/features/goals/pages/admin/List').then(m => ({
              default: m.List,
            })),
          ),
        },
        {
          path: 'setting',
          getTitle: () => $t('设置'),
          component: lazy(() =>
            import('@/features/goals/pages/admin/Setting').then(m => ({
              default: m.Setting,
            })),
          ),
        },
      ],
    },
    {
      name: 'AdminProfileFeedbacks',
      path: 'admin/profiles/feedbacks',
      module: [PluginCategoryEnum.学生成长, SchoolModulesEnum.Goal],
      getTitle: () => $t('反馈列表'),
      requirePermissions: [PermissionNameEnum.CoreUserRead],
      component: lazy(() =>
        import('@/features/feedbacks/pages/admin/List').then(m => ({
          default: m.List,
        })),
      ),
    },
    {
      path: 'student/profiles/:rid/info',
      name: 'StudentDetailsInfo',
      requireRoles: [
        RoleEnum.Student,
        RoleEnum.Teacher,
        RoleEnum.Shadow,
        RoleEnum.Guardian,
      ],
      getTitle: () => $t('学生详情'),
      component: lazy(() =>
        import('@/features/users/pages/me/Info/Student/StudentDetails').then(
          m => ({
            default: m.StudentDetailsInfo,
          }),
        ),
      ),
    },
    {
      path: 'admin/profiles/:rid/goal/:goalId',
      requireRoles: [RoleEnum.Teacher, RoleEnum.Shadow, RoleEnum.Guardian],
      getTitle: () => $t('目标详情'),
      component: lazy(() =>
        import('@/features/goals/pages/students/GoalDetail').then(m => ({
          default: m.GoalDetail,
        })),
      ),
    },
    {
      path: 'profiles/me/goals/all',
      requireRoles: [RoleEnum.Student],
      getTitle: () => $t('全部目标'),
      component: lazy(() =>
        import('@/features/goals/pages/students/AllGoals').then(m => ({
          default: m.AllGoals,
        })),
      ),
    },
    {
      path: 'admin/profiles/:rid/goals/all',
      requireRoles: [RoleEnum.Teacher, RoleEnum.Shadow, RoleEnum.Guardian],
      getTitle: () => $t('全部目标'),
      component: lazy(() =>
        import('@/features/goals/pages/students/AllGoals').then(m => ({
          default: m.AllGoals,
        })),
      ),
    },
    {
      path: 'admin/profiles/admin-classes/:classId/:rid',
      requireRoles: [RoleEnum.Teacher, RoleEnum.Shadow],
      getTitle: () => $t('行政班学生档案'),
      name: 'AdminAdminClassStudentProfiles',
      component: lazy(() =>
        import('../pages/AdminClassEntry').then(m => ({
          default: m.AdminClassStudentProfileModal,
        })),
      ),
      subRoutes: getProfileSubRoutes(),
    },
    {
      path: 'profiles/student-status/:formId/:studentId/edit',
      requireRoles: [RoleEnum.Teacher, RoleEnum.Staff, RoleEnum.Shadow],
      getTitle: () => $t('编辑学籍表'),
      component: lazy(() =>
        import(
          '@/plugins/features/student-status-profile/pages/students/Edit'
        ).then(m => ({ default: m.StudentEdit })),
      ),
    },
    {
      path: 'admin/profiles/:rid',
      requireRoles: [RoleEnum.Teacher, RoleEnum.Shadow, RoleEnum.Guardian],
      getTitle: () => $t('学生档案'),
      name: 'AdminStudentProfiles',
      component: lazy(() =>
        import('../pages/AdminEntry').then(m => ({ default: m.Profile })),
      ),
      subRoutes: getProfileSubRoutes(),
    },

    {
      path: 'profiles/me/goal/:goalId',
      requireRoles: [RoleEnum.Student],
      getTitle: () => $t('目标详情'),
      component: lazy(() =>
        import('@/features/goals/pages/students/GoalDetail').then(m => ({
          default: m.GoalDetail,
        })),
      ),
    },
    {
      path: 'profiles/me',
      name: 'StudentProfiles',
      requireRoles: [RoleEnum.Student],
      getTitle: () => $t('我的档案'),
      component: lazy(() =>
        import('../pages/Entry').then(m => ({ default: m.Profile })),
      ),
      subRoutes: getProfileSubRoutes(),
    },
  ]

  return routes
}

export const modalRoutes = []
