import { lazy } from 'react'

import { GroupPermissionTypeEnum } from 'packages/features/groups'
import { $t } from 'packages/locale'

/**
 * 获取注入学生档案的路由
 *
 * @returns 注入学生档案的路由
 */
export const getProfileSubRoutes = () => [
  {
    path: 'comments',
    name: 'Comments',
    getTitle: () => $t('评语'),
    component: lazy(() =>
      import('../pages/user/ProfileSemesterReviews').then(m => ({
        default: m.ProfileSemesterReviews,
      })),
    ),
    customMeta: {
      // 注册在「评价」tab 后
      sort: 8,
      groupPermission: GroupPermissionTypeEnum.SemesterReview,
    },
  },
]
