/**
 * @file 场地日程
 */

import { lazy } from 'react'

import { CalendarEventType } from 'packages/features/calendars/utils/types'
import { $t } from 'packages/locale'

export const calendarEvents = [
  {
    type: CalendarEventType.Venue,
    Component: lazy(() =>
      import('./VenueEvent').then(m => ({ default: m.VenueEvent })),
    ),
    getEventTag: () => $t('场地预约'),
  },
]
