/**
 * @file 用户 - 学生 - 路由
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const studentRoutes: RouteConfig[] = [
  {
    path: 'admin/users/students/fields',
    getTitle: () => $t('字段管理'),
    component: lazy(() =>
      import('../pages/students/FieldManagement').then(m => ({
        default: m.FieldManagement,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
  {
    path: 'admin/users/students/new',
    name: 'AdminStudentsNew',
    getTitle: () => $t('新增学生'),
    component: lazy(() =>
      import('../pages/students/New').then(m => ({
        default: m.StudentNew,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
  {
    path: 'admin/users/students/batch-edit',
    name: 'AdminStudentsBatchEdit',
    getTitle: () => $t('批量编辑学生'),
    component: lazy(() =>
      import('../pages/students/BatchEdit').then(m => ({
        default: m.StudentBatchEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
  {
    path: 'admin/users/students/:studentId/view',
    name: 'AdminUserStudentView',
    getTitle: () => $t('学生详情'),
    component: lazy(() =>
      import('../pages/students/View').then(m => ({
        default: m.StudentView,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserRead],
  },
  {
    path: 'admin/users/students/:studentId/edit',
    name: 'AdminStudentsEdit',
    getTitle: () => $t('编辑学生'),
    component: lazy(() =>
      import('../pages/students/Edit').then(m => ({
        default: m.StudentEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
]
