import { useOnUpdate } from '@seiue/util'
import React, { lazy, Suspense, useState } from 'react'

import { useCounters, CounterSubjectEnum } from 'packages/features/counter'

import { AppNameEnum } from '@/features/no-code/apps/types'
import { RootInterrupt } from '@/uikit'

const CAN_SHOW_KEY = `${AppNameEnum.SzHomeworkSurvey}QuestionnairesForce`

const QuestionnairesEntryModal = lazy(() =>
  import('./EntryModal').then(m => ({ default: m.QuestionnairesEntryModal })),
)

/**
 * 学生强制填写作业调查弹窗
 *
 * @constructor
 */
export const SzHomeworkSurveyQuestionnairesForceModal: React.FC = () => {
  const { counterSubjectIds } = useCounters(
    CounterSubjectEnum.ForcedHomeworkSurveyQuestionnaires,
  )

  const [questionnairenIds, setIds] = useState<string[]>([])

  const goNext = () => {
    setIds(prevIds => {
      const nextIds = [...prevIds]

      nextIds.shift()

      return nextIds
    })
  }

  useOnUpdate(() => {
    setIds(counterSubjectIds)
  }, [counterSubjectIds.join(',')])

  const canShow = RootInterrupt.useCanShow(
    CAN_SHOW_KEY,
    !questionnairenIds.length,
  )

  if (!canShow || !questionnairenIds.length) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <QuestionnairesEntryModal
        taskId={Number(questionnairenIds[0])}
        reloadForcedQuestionnaires={goNext}
      />
    </Suspense>
  )
}
