import { useCallback } from 'react'

import { useSelectedSemester } from 'packages/feature-utils/semesters/hooks'

import { useDispatch, useSelector } from '@/store'

/**
 * 全局搜索 Hooks
 *
 * @returns 全局搜索 Hooks
 */
export const useGlobalSearch = () => {
  const dispatch = useDispatch()
  const display = useSelector(({ globalSearch }) => globalSearch.display)

  const [semester] = useSelectedSemester()

  const enabled = !!semester?.id

  return {
    display,
    enabled,
    open: useCallback(() => {
      if (!enabled) return
      dispatch.globalSearch.setDisplay(true)
    }, [dispatch.globalSearch, enabled]),
    close: useCallback(() => {
      dispatch.globalSearch.setDisplay(false)
    }, [dispatch]),
    destory: useCallback(() => {
      dispatch.globalSearch.setInitTimestamp(0)
      dispatch.globalSearch.setDisplay(false)
    }, [dispatch]),
  }
}
