import { registerTodos } from 'packages/features/todos/register'

import { InitFeatureParams } from '@/features/types'
import { registerToSlot } from '@/plugins'

import { calendarEvents } from './calendar-events'
import { adminVenueMenu, userVenueMenu } from './menus'
import { routes, modalRoutes } from './routes'
import { todos } from './todos'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initVenues = ({
  addRoutes,
  addMenuProvider,
  registerCalendarEvents,
}: InitFeatureParams) => {
  registerTodos(todos)
  addMenuProvider(adminVenueMenu)
  addMenuProvider(userVenueMenu)
  registerCalendarEvents(calendarEvents)
  addRoutes(routes, modalRoutes)
  registerToSlot('userCommonApplicationsEntry', () =>
    import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
  )
}
