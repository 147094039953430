import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const routes: RouteConfig[] = []

export const modalRoutes = [
  {
    name: 'CustomScheduleEditModal',
    getTitle: () => $t('创建日程'),
    component: lazy(() =>
      import('@/features/schedules/pages/CustomScheduleEditModal').then(m => ({
        default: m.CustomScheduleEditModal,
      })),
    ),
  },
  {
    name: 'CustomScheduleShowModal',
    getTitle: () => $t('日程详情'),
    component: lazy(() =>
      import('@/features/schedules/pages/CustomScheduleShowModal').then(m => ({
        default: m.CustomScheduleShowModal,
      })),
    ),
  },
]
