/**
 * @file 用户 - 家长 - 路由
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const guardianRoutes: RouteConfig[] = [
  {
    path: 'admin/users/guardians/fields',
    getTitle: () => $t('字段管理'),
    component: lazy(() =>
      import('../pages/guardians/FieldManagement').then(m => ({
        default: m.FieldManagement,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
  {
    path: 'admin/users/guardians/:guardianId/view',
    name: 'AdminGuardianView',
    getTitle: () => $t('家长信息'),
    component: lazy(() =>
      import('../pages/guardians/View').then(m => ({
        default: m.GuardianView,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserRead],
  },
  {
    path: 'admin/users/guardians/:guardianId/edit',
    name: 'AdminGuardianEdit',
    getTitle: () => $t('编辑家长'),
    component: lazy(() =>
      import('../pages/guardians/Edit').then(m => ({
        default: m.GuardianEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
  {
    path: 'admin/users/guardians/batch-edit',
    getTitle: () => $t('批量编辑家长'),
    component: lazy(() =>
      import('../pages/guardians/BatchEdit').then(m => ({
        default: m.GuardianBatchEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
]
