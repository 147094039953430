import { flatten } from '@seiue/util'
import { lazy } from 'react'

import { ModuleEnum } from 'packages/feature-utils/plugins'
import { ClassHiddenTabEnum } from 'packages/features/classes/hooks/settings'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'

import { getRouteSlots } from '@/plugins'

/**
 * 课程班群组子路由
 *
 * @returns 子路由
 */
export const getClassGroupSubRoutes = () => [
  {
    path: 'home',
    getTitle: () => $t('主页'),
    component: lazy(() =>
      import('./pages/Group/HomePage').then(m => ({ default: m.HomePage })),
    ),
    customMeta: {
      tab: ClassHiddenTabEnum.Home,
    },
  },
  {
    path: 'attendances',
    name: 'Attendances',
    getTitle: () => $t('考勤'),
    component: lazy(() =>
      import('@/features/attendances/pages/List').then(m => ({
        default: m.AttendanceList,
      })),
    ),
    requireModules: [ModuleEnum.Attendance],
    customMeta: {
      tab: ClassHiddenTabEnum.Attendance,
    },
    subRoutes: [
      {
        path: 'entry',
        getTitle: () => $t('每日考勤'),
        component: lazy(() =>
          import('@/features/attendances/pages/List/teacher/Entry').then(m => ({
            default: m.Entry,
          })),
        ),
      },
      {
        path: 'stats/students',
        getTitle: () => $t('学生考勤统计'),
        component: lazy(() =>
          import(
            '@/features/attendances/pages/List/teacher/StudentListStats'
          ).then(m => ({
            default: m.StudentListStats,
          })),
        ),
      },
    ],
  },
  ...flatten(getRouteSlots('profileClassImportantSubRoutes')),
  {
    path: 'tasks',
    name: 'Tasks',
    getTitle: () => $t('任务'),
    requireModules: [PluginNameEnum.ClassTasks],
    component: lazy(() =>
      import('@/features/classes/pages/Group/Tasks').then(m => ({
        default: m.Tasks,
      })),
    ),
    customMeta: {
      tab: ClassHiddenTabEnum.Task,
    },
  },
  {
    path: 'grades',
    name: 'TeacherGrades',
    getTitle: () => $t('成绩'),
    requireModules: [ModuleEnum.ClassAssessment],
    component: lazy(() =>
      import('@/features/grades/pages/teacher/Show').then(m => ({
        default: m.Show,
      })),
    ),
    subRoutes: [
      {
        path: 'overview',
        getTitle: () => $t('汇总'),
        component: lazy(() =>
          import('@/features/grades/pages/teacher/Show/Overview').then(m => ({
            default: m.Overview,
          })),
        ),
      },
      {
        path: 'entry',
        name: 'Entry',
        getTitle: () => $t('录入'),
        component: lazy(() =>
          import('@/features/grades/pages/teacher/Show/Entry').then(m => ({
            default: m.Entry,
          })),
        ),
      },
      {
        path: 'analysis',
        getTitle: () => $t('分析'),
        name: 'Analysis',
        component: lazy(() =>
          import('@/features/grades/pages/teacher/Show/Analyses').then(m => ({
            default: m.Analysis,
          })),
        ),
      },
      {
        path: 'logs',
        getTitle: () => $t('日志'),
        component: lazy(() =>
          import('@/features/grades/pages/teacher/Show/Logs').then(m => ({
            default: m.ScoreLogs,
          })),
        ),
        // 北京汇文中学隐藏日志
        hideSchools: [340],
      },
      {
        path: 'structures',
        name: 'Structures',
        getTitle: () => $t('结构'),
        component: lazy(() =>
          import('@/features/grades/pages/teacher/Show/Structures').then(m => ({
            default: m.Structures,
          })),
        ),
      },
    ],
  },
  {
    path: 'student-grades',
    name: 'StudentGrades',
    requireModules: [ModuleEnum.ClassAssessment],
    getTitle: () => $t('成绩'),
    component: lazy(() =>
      import('@/features/grades/pages/student/Show').then(m => ({
        default: m.Show,
      })),
    ),
    customMeta: {
      tab: ClassHiddenTabEnum.Grade,
    },
  },
  {
    path: 'exams',
    name: 'ExamList',
    getTitle: () => $t('考试'),
    component: lazy(() =>
      import('./pages/ClassExamList').then(m => ({
        default: m.ClassExamList,
      })),
    ),
  },
  ...flatten(getRouteSlots('profileClassGroupSubRoutes')),
  {
    path: 'schedule',
    getTitle: () => $t('日程'),
    component: lazy(() =>
      import('@/features/classes/pages/Group/Schedule').then(m => ({
        default: m.Schedule,
      })),
    ),
    customMeta: {
      tab: ClassHiddenTabEnum.Schedule,
    },
  },
  {
    path: 'members',
    name: 'Members',
    getTitle: () => $t('成员'),
    component: lazy(() =>
      import('@/features/classes/pages/Group/Members').then(m => ({
        default: m.Members,
      })),
    ),
    customMeta: {
      tab: ClassHiddenTabEnum.Member,
    },
  },
  {
    path: 'discussions',
    getTitle: () => $t('讨论'),
    component: lazy(() =>
      import('@/features/discussions/pages').then(m => ({
        default: m.Discussion,
      })),
    ),
    customMeta: {
      tab: ClassHiddenTabEnum.Discussion,
    },
  },
  ...flatten(getRouteSlots('classSubRoutes')),
]
