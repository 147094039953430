/**
 * @file chalk classes store
 */

import { ModelReducers } from '@seiue/rematch-core'

import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import type { MemberSortType } from 'packages/features/tasks'

import { useSelector } from '@/store'

interface IsTaskExpanded {
  [key: string]: boolean | undefined | null
}

interface TaskMemberSortKeyType {
  [key: string]: string | undefined | null
}

interface ClassesState {
  taskExpandedMap: IsTaskExpanded
  taskMemberSortKeyMap: TaskMemberSortKeyType
}

const state: ClassesState = {
  // 记忆课程班任务列表每个章节收起/展开的状态
  taskExpandedMap: {},
  // 记忆任务详情的排序方式
  taskMemberSortKeyMap: {},
}

const getExpandedKey = (
  groupId: number | string,
  outlineId: number,
  rid: number,
) => `group_${groupId}_tasks_outline_${outlineId}_reflection_${rid}`

const getMemberSortKey = (rid: number) =>
  `group_task_reflection_${rid}_sort_key`

const effects = (dispatch: any) => ({
  toggleIsTaskExpanded: (
    param: {
      bizId: number | string
      outlineId: number
      expanded: boolean
    },
    rootState: any,
  ) => {
    const rid = rootState.session?.currentReflection?.id
    if (rid) {
      // 记忆每个章节收起/展开的状态，每个用户的折起操作仅对自己的账号生效
      const expandedKey = getExpandedKey(param.bizId, param.outlineId, rid)
      dispatch.chalkClasses.setIsTaskExpanded({
        [expandedKey]: param.expanded,
      })
    }
  },
  saveTaskMembersSortKey: (
    param: {
      sortKey: MemberSortType
    },
    rootState: any,
  ) => {
    const rid = rootState.session?.currentReflection?.id
    if (rid) {
      // 记忆每个任务的人员的排列方式
      const key = getMemberSortKey(rid)
      dispatch.chalkClasses.setTaskMembersSortKey({
        [key]: param.sortKey,
      })
    }
  },
})

const reducers: ModelReducers<ClassesState> = {
  setIsTaskExpanded(prevState: ClassesState, payload: IsTaskExpanded) {
    const { taskExpandedMap, ...otherState } = prevState
    return {
      ...otherState,
      taskExpandedMap: {
        ...(taskExpandedMap || {}),
        ...payload,
      },
    }
  },
  setTaskMembersSortKey(
    prevState: ClassesState,
    payload: TaskMemberSortKeyType,
  ) {
    const { taskMemberSortKeyMap, ...otherState } = prevState
    return {
      ...otherState,
      taskMemberSortKeyMap: {
        ...(taskMemberSortKeyMap || {}),
        ...payload,
      },
    }
  },
}

/**
 * 获取缓存的任务章节的收起/展开状态
 *
 * @param bizId - 课程班 id
 * @param outlineId - 章节 id
 * @returns 是否展开
 */
export const useIsTaskExpanded = (
  bizId: string | number,
  outlineId: number,
) => {
  const currentReflection = useCurrentReflection()
  const expandedKey = getExpandedKey(bizId, outlineId, currentReflection.id)
  return useSelector(
    rootState => rootState.chalkClasses.taskExpandedMap?.[expandedKey],
  )
}

/**
 * 获取缓存的任务人员的排序方式
 *
 * @returns 排序方式
 */
export const useGetTaskMembersSortKey = () => {
  const currentReflection = useCurrentReflection()
  const key = getMemberSortKey(currentReflection.id)
  return useSelector(
    rootState => rootState.chalkClasses.taskMemberSortKeyMap?.[key],
  )
}

export const chalkClasses = {
  state,
  effects,
  reducers,
}
