import { flatten } from '@seiue/util'
import { lazy } from 'react'

import { ModuleEnum, PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import { getRouteSlots } from '@/plugins'

const getCourseSubRoutes = (): RouteConfig[] => [
  ...flatten(getRouteSlots('profileCourseHeadSubRoutes')),
  {
    path: 'tasks',
    getTitle: () => $t('任务'),
    requireModules: [PluginEnum.ClassTasks],
    component: lazy(() =>
      import('@/features/courses/pages/Show/Tasks').then(m => ({
        default: m.Tasks,
      })),
    ),
  },
  {
    path: 'assessment',
    getTitle: () => $t('成绩'),
    requireModules: [ModuleEnum.ClassAssessment],
    component: lazy(() =>
      import('@/features/courses/pages/Show/Assessment').then(m => ({
        default: m.Assessment,
      })),
    ),
  },
  ...flatten(getRouteSlots('profileCourseGroupSubRoutes')),
]

/**
 * 课程库路由文件
 *
 * @returns 路由
 */
export const getRoutes: () => RouteConfig[] = () => [
  {
    path: 'courses',
    getTitle: () => $t('课程库'),
    component: lazy(() =>
      import('@/features/courses/pages/App/List').then(m => ({
        default: m.CourseList,
      })),
    ),
    requireModules: [ModuleEnum.Course],
  },
  {
    path: 'courses/:courseId',
    name: 'CourseDetails',
    getTitle: () => $t('课程详情'),
    component: lazy(() =>
      import('@/features/courses/pages/Show').then(m => ({
        default: m.Show,
      })),
    ),
    requireModules: [ModuleEnum.Course],
    subRoutes: getCourseSubRoutes(),
  },
  {
    path: 'admin/courses',
    getTitle: () => $t('课程库管理'),
    module: ['courses'],
    component: lazy(() =>
      import('@/features/courses/pages/List').then(m => ({
        default: m.CourseList,
      })),
    ),
    requireModules: [ModuleEnum.Course],
    requirePermissions: [PermissionNameEnum.CoreCourseRead],
    subRoutes: [
      {
        path: 'normal',
        getTitle: () => $t('课程信息'),
        component: lazy(() =>
          import('@/features/courses/pages/List/Normal').then(m => ({
            default: m.NormalList,
          })),
        ),
      },
      {
        path: 'archived',
        getTitle: () => $t('已归档课程'),
        component: lazy(() =>
          import('@/features/courses/pages/List/Archived').then(m => ({
            default: m.ArchivedList,
          })),
        ),
      },
      {
        path: 'record',
        getTitle: () => $t('操作日志'),
        component: lazy(() =>
          import('@/features/courses/pages/List/Record').then(m => ({
            default: m.RecordList,
          })),
        ),
      },
      {
        path: 'settings',
        getTitle: () => $t('设置'),
        component: lazy(() =>
          import('@/features/courses/pages/Settings').then(m => ({
            default: m.Settings,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/courses/new',
    getTitle: () => $t('新增课程'),
    requireModules: [ModuleEnum.Course],
    component: lazy(() =>
      import('@/features/courses/pages/New').then(m => ({
        default: m.NewCourse,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreCourseWrite],
  },
  {
    path: 'admin/courses/:courseId/edit',
    getTitle: () => $t('编辑课程'),
    requireModules: [ModuleEnum.Course],
    component: lazy(() =>
      import('@/features/courses/pages/Edit').then(m => ({
        default: m.EditCourse,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreCourseWrite],
  },
  {
    path: 'admin/courses/batch-edit',
    getTitle: () => $t('批量编辑课程'),
    requireModules: [ModuleEnum.Course],
    component: lazy(() =>
      import('@/features/courses/pages/BatchEdit').then(m => ({
        default: m.CourseBatchEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreCourseWrite],
  },
  {
    path: 'admin/courses/:courseId/show',
    getTitle: () => $t('课程详情'),
    requireModules: [ModuleEnum.Course],
    component: lazy(() =>
      import('@/features/courses/pages/ShowFields').then(m => ({
        default: m.CourseShow,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreCourseRead],
  },
  {
    name: 'AdminCourseFields',
    path: 'admin/courses/fields',
    getTitle: () => $t('课程字段管理'),
    requireModules: [ModuleEnum.Course],
    component: lazy(() =>
      import('@/features/courses/pages/FieldManagement').then(m => ({
        default: m.FieldManagement,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreCourseWrite],
  },
  {
    path: 'admin/courses/:courseId/board',
    name: 'AdminCourseDetails',
    getTitle: () => $t('课程详情'),
    requireModules: [ModuleEnum.Course],
    component: lazy(() =>
      import('@/features/courses/pages/Show').then(m => ({
        default: m.Show,
      })),
    ),
    subRoutes: getCourseSubRoutes(),
  },
]
