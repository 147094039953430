import { atom } from 'jotai'

import { hasEnabledPluginAtom } from 'packages/feature-utils/plugins'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const adminClassAttendanceMenu = atom(get => {
  if (!get(hasEnabledPluginAtom(SchoolModulesEnum.Attendance))) {
    return []
  }

  return [
    {
      prefix: ['courses'],
      label: $t('课程班考勤管理'),
      name: 'class-attendances',
      sort: 205,
      icon: 'CheckWorkAttendance' as const,
      shortcutIcon: 'CheckWorkAttendanceSolid+829B9E',
      permission: [PermissionNameEnum.CoreClassRead],
      path: '/admin/attendances/classes',
    },
  ]
})
