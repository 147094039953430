/**
 * @file 系统管理 menus
 */

import { atom } from 'jotai'

import {
  hasEnabledPluginAtom,
  ModuleEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const adminSystemSettingMenu = atom(get => {
  const customSettingPluginEnabled = get(
    hasEnabledPluginAtom(ModuleEnum.CustomSetting),
  )

  const menus = []

  if (customSettingPluginEnabled) {
    menus.push({
      path: '/admin/system-settings/school-configs',
      label: $t('个性化设置'),
      name: 'school-configs',
      permission: [PermissionNameEnum.CoreAdminSettingWrite],
      icon: 'Individualization' as const,
      shortcutIcon: 'IndividualizationSolid+8E6BF1',
      sort: 703,
    })
  }

  return [
    {
      name: 'system',
      module: true,
      label: $t('系统管理'),
      icon: 'System' as const,
      subMenus: menus,
      type: MenuType.Admin,
      sort: 100,
    },
    {
      prefix: ['users'],
      path: '/admin/system-settings/permissions',
      label: $t('权限管理'),
      name: 'permissions',
      sort: 202,
      icon: 'Jurisdiction' as const,
      shortcutIcon: 'JurisdictionSolid+15B8B1',
      permission: [
        PermissionNameEnum.CoreModuleAuthRead,
        PermissionNameEnum.CoreAdminAuthRead,
        PermissionNameEnum.CoreGrowthSettingWrite,
      ],
    },
  ]
})
