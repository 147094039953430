/**
 * @file 通知待办
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

const NoticeDiscussionRepliedCard = lazy(() =>
  import('./NoticeDiscussionRepliedCard').then(m => ({
    default: m.NoticeDiscussionRepliedCard,
  })),
)

export const todos: TodoExecution[] = [
  // 新留言
  {
    type: TodoTypeEnum.NotificationDiscussionReplied,
    Render: NoticeDiscussionRepliedCard,
  },
]
