import { atom } from 'jotai'

import { hasEnabledPluginAtom } from 'packages/feature-utils/plugins'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

export const adminDirectionMenus = atom(get => {
  if (!get(hasEnabledPluginAtom(SchoolModulesEnum.Direction))) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.教务教学],
      name: SchoolModulesEnum.Direction,
      label: $t('选科管理'),
      path: '/admin/subject-selection',
      sort: 706,
      permission: [PermissionNameEnum.CoreDirectionRead],
      icon: 'Form' as const,
      shortcutIcon: 'CoursesSelectionSolid+D45586',
      subMenus: [
        {
          label: $t('选科结果'),
          path: '/admin/subject-selection/result',
          icon: 'Form' as const,
        },
        {
          label: $t('选科填报'),
          path: '/admin/subject-selection/records',
          icon: 'Examination' as const,
        },
        {
          label: $t('设置'),
          path: '/admin/subject-selection/setting',
          icon: 'Setting' as const,
        },
      ],
    },
  ]
})
