/**
 * @file 课程日历弹窗
 */

import { lazy } from 'react'

import { CalendarEventType } from 'packages/features/calendars/utils/types'
import { $t } from 'packages/locale'

export const calendarEvents = [
  {
    type: CalendarEventType.Lesson,
    Component: lazy(() =>
      import('./SchedulePopupLesson').then(m => ({
        default: m.SchedulePopupLesson,
      })),
    ),
    getEventTag: () => $t('课程'),
  },
  {
    type: CalendarEventType.LessonFold,
    Component: lazy(() =>
      import('./ScheduleFoldPopLesson').then(m => ({
        default: m.ScheduleFoldPopLesson,
      })),
    ),
    getEventTag: () => $t('课程'),
  },
]
