import { lazy } from 'react'

import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

import { getRouteSlots } from '@/plugins'
import { RouteMenus } from '@/router'

/**
 * 选科路由配置
 *
 * @returns 路由
 */
export const getRoutes = (): RouteConfig[] => [
  {
    path: 'admin/subject-selection',
    name: 'SubjectSelection',
    module: [PluginCategoryEnum.教务教学, SchoolModulesEnum.Direction],
    getTitle: () => $t('选科管理'),
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    requirePermissions: [PermissionNameEnum.CoreDirectionRead],
    subRoutes: [
      {
        path: 'result',
        name: 'Results',
        getTitle: () => $t('选科结果'),
        component: lazy(() =>
          import('@/features/subject-selection/pages/admin/Results').then(
            m => ({
              default: m.Index,
            }),
          ),
        ),
        subRoutes: [
          {
            path: 'list',
            name: 'List',
            getTitle: () => $t('全部结果'),
            component: lazy(() =>
              import(
                '@/features/subject-selection/pages/admin/Results/Results'
              ).then(m => ({
                default: m.Results,
              })),
            ),
          },
          ...getRouteSlots('subjectSelectionResultStatSubRoutes'),
        ],
      },
      {
        path: 'records',
        name: 'Records',
        getTitle: () => $t('选科填报'),
        component: lazy(() =>
          import('@/features/subject-selection/pages/admin/Records').then(
            m => ({
              default: m.Records,
            }),
          ),
        ),
      },
      {
        path: 'setting',
        name: 'Setting',
        getTitle: () => $t('设置'),
        component: lazy(() =>
          import('@/features/subject-selection/pages/admin/Setting').then(
            m => ({
              default: m.Setting,
            }),
          ),
        ),
        requirePermissions: [PermissionNameEnum.CoreDirectionWrite],
      },
    ],
  },
  {
    path: 'admin/subject-selection/for-reflection/:rid',
    name: 'SubjectSelectionResultDetailForAdmin',
    getTitle: () => $t('选科详情'),
    requirePermissions: [PermissionNameEnum.CoreDirectionRead],
    component: lazy(() =>
      import(
        '@/features/subject-selection/pages/user/SubjectSelectionDetailForAdmin'
      ).then(m => ({
        default: m.SubjectSelectionDetailForAdmin,
      })),
    ),
  },

  {
    path: 'admin/subject-selection/records/:id',
    name: 'SubjectSelectionQuestionnaireDetail',
    getTitle: () => $t('填报详情'),
    requirePermissions: [PermissionNameEnum.CoreDirectionRead],
    component: lazy(() =>
      import(
        '@/features/subject-selection/pages/admin/QuestionnaireDetail'
      ).then(m => ({
        default: m.Index,
      })),
    ),
    subRoutes: [
      {
        path: 'detail',
        name: 'Detail',
        getTitle: () => $t('填报详情'),
        component: lazy(() =>
          import(
            '@/features/subject-selection/pages/admin/QuestionnaireDetail/Detail'
          ).then(m => ({
            default: m.Detail,
          })),
        ),
      },
      {
        path: 'statistics',
        name: 'Statistic',
        getTitle: () => $t('分题统计'),
        component: lazy(() =>
          import(
            '@/features/subject-selection/pages/admin/QuestionnaireDetail/Statistics'
          ).then(m => ({
            default: m.Statistics,
          })),
        ),
      },
      ...getRouteSlots('subjectSelectionFormStatSubRoutes'),
      {
        path: 'setting',
        name: 'Setting',
        getTitle: () => $t('填报设置'),
        component: lazy(() =>
          import(
            '@/features/subject-selection/pages/admin/QuestionnaireDetail/Setting'
          ).then(m => ({
            default: m.Setting,
          })),
        ),
      },
    ],
  },
]

export const modalRoutes = [
  {
    name: 'SubjectSelection.SchoolAndMajorEditModal',
    getTitle: () => $t('编辑目标学校和专业'),
    component: lazy(() =>
      import(
        '@/features/subject-selection/pages/user/SchoolAndMajorEditModal'
      ).then(m => ({
        default: m.SchoolAndMajorEditModal,
      })),
    ),
  },
  {
    name: 'SubjectSelection.SubjectSelectionEditModal',
    getTitle: () => $t('编辑选科方向'),
    component: lazy(() =>
      import(
        '@/features/subject-selection/pages/user/SubjectSelectionEditModal'
      ).then(m => ({
        default: m.SubjectSelectionEditModal,
      })),
    ),
  },
  {
    name: 'SubjectSelection.ConfirmResultModal',
    getTitle: () => $t('确认选科结果'),
    component: lazy(() =>
      import('@/features/subject-selection/pages/user/ConfirmResultModal').then(
        m => ({
          default: m.ConfirmResultModal,
        }),
      ),
    ),
  },
  {
    name: 'SubjectSelection.SubjectSelectionEditLogModal',
    getTitle: () => $t('修改记录'),
    component: lazy(() =>
      import(
        '@/features/subject-selection/pages/user/SubjectSelectionEditLogModal'
      ).then(m => ({
        default: m.SubjectSelectionEditLogModal,
      })),
    ),
  },
  {
    name: 'SubjectSelection.EditModal',
    getTitle: () => $t('新增选科填报'),
    component: lazy(() =>
      import('@/features/subject-selection/pages/admin/Edit/index').then(m => ({
        default: m.EditModal,
      })),
    ),
  },
  {
    name: 'SubjectSelection.AnswerModal',
    getTitle: () => $t('选科填报'),
    component: lazy(() =>
      import('@/features/subject-selection/pages/user/AnswerModal').then(m => ({
        default: m.AnswerModal,
      })),
    ),
  },
  {
    name: 'SubjectSelection.UnfilledStudentsModal',
    getTitle: () => $t('未填报学生列表'),
    component: lazy(() =>
      import(
        '@/features/subject-selection/pages/user/UnfilledStudentsModal'
      ).then(m => ({
        default: m.UnfilledStudentsModal,
      })),
    ),
  },
  {
    name: 'SubjectSelection.HistoryAnswerModal',
    getTitle: () => $t('历史填报答案'),
    component: lazy(() =>
      import('@/features/subject-selection/pages/user/HistoryAnswerModal').then(
        m => ({
          default: m.HistoryAnswerModal,
        }),
      ),
    ),
  },
]
