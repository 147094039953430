/**
 * @file chalk 小组件注册
 */

import React from 'react'

import { RoleEnum } from 'packages/sdks-next/chalk'

import { RegisterWidget } from './types'

const registeredWidgets: RegisterWidget[] = []

/**
 * 注册小组件
 *
 * @param widgets - 小组件列表
 */
export const registerWidgets = (widgets: RegisterWidget[]) => {
  const registeredWidgetKeys = registeredWidgets.map(widget => widget.key)

  const duplicated = widgets.filter(widget =>
    registeredWidgetKeys.includes(widget.key),
  )

  if (duplicated.length) {
    console.error(
      `小组件注册失败，重复的 key 为：${duplicated.map(widget => widget.key)}`,
    )
  }

  registeredWidgets.push(
    ...widgets.filter(widget => !registeredWidgetKeys.includes(widget.key)),
  )
}

/**
 * 获取已注册的小组件列表
 *
 * @returns 小组件列表
 */
export const getRegisteredWidgets = () => registeredWidgets

/**
 * 根据角色类型获取小组件
 *
 * @param role - 角色
 * @returns 角色对应的小组件列表
 */
export const useWidgetsByRole = (role: RoleEnum) => {
  return React.useMemo(
    () =>
      role === RoleEnum.Shadow
        ? registeredWidgets
        : registeredWidgets.filter(widget => widget.enableRoles.includes(role)),
    [role],
  )
}
