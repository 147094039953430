import { InitFeatureParams } from '@/features/types'

import { adminSemesterMenu } from './menus'
import { routes } from './routes'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initSemesters = ({
  addRoutes,
  addMenuProvider,
}: InitFeatureParams) => {
  addRoutes(routes)
  addMenuProvider(adminSemesterMenu)
}
