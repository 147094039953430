/**
 * @file exam menus
 */

import { atom } from 'jotai'

import {
  hasEnabledPluginAtom,
  ModuleEnum,
} from 'packages/feature-utils/plugins'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

export const adminExamMenu = atom(get => {
  if (!get(hasEnabledPluginAtom(ModuleEnum.Exam))) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.教务教学],
      name: SchoolModulesEnum.Exam,
      path: '/admin/exams',
      label: $t('考试管理'),
      type: MenuType.AdminApps,
      icon: 'Examination' as const,
      shortcutIcon: 'ExamSolid+EA7B25',
      sort: 705,
      permission: [PermissionNameEnum.CoreExamWrite],
      subMenus: [
        {
          label: $t('全部考试'),
          path: '/admin/exams',
          icon: 'Examination' as const,
        },
        {
          label: $t('全局设置'),
          path: '/admin/exams/settings',
          icon: 'Setting' as const,
        },
      ],
    },
  ]
})
