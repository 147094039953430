import React from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

const userRoutes: RouteConfig[] = [
  {
    path: 'workflows/center',
    name: 'WorkflowsCenter',
    module: [SchoolPluginsEnum.WorkflowCenter],
    requireModules: [PluginEnum.WorkflowCenter],
    getTitle: () => $t('审批中心'),
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'all',
        getTitle: () => $t('全部审批'),
        component: React.lazy(() =>
          import('./pages/user/Center/All').then(m => ({
            default: m.WorkflowList,
          })),
        ),
      },
      {
        path: 'initiated',
        name: 'Initiated',
        getTitle: () => $t('我发起的'),
        component: React.lazy(() =>
          import('./pages/user/Center/Initiated').then(m => ({
            default: m.WorkflowsInitiated,
          })),
        ),
      },
      {
        path: 'to-approve',
        name: 'ToApprove',
        getTitle: () => $t('我处理的'),
        component: React.lazy(() =>
          import('./pages/user/Center/ToApprove').then(m => ({
            default: m.WorkflowsToApprove,
          })),
        ),
      },
      {
        path: 'copied',
        name: 'Copied',
        getTitle: () => $t('抄送我的'),
        component: React.lazy(() =>
          import('./pages/user/Center/Copied').then(m => ({
            default: m.WorkflowsCopied,
          })),
        ),
      },
    ],
  },
]

const adminRoutes: RouteConfig[] = [
  {
    name: 'AdminWorkflow',
    path: 'admin/workflows/:schoolPluginId',
    module: [PluginCategoryEnum.通用工具, SchoolPluginsEnum.Workflow],
    getTitle: () => $t('审批管理'),
    component: React.lazy(() =>
      import('./pages/admin').then(m => ({
        default: m.AdminWorkflow,
      })),
    ),
    subRoutes: [
      {
        path: 'list',
        getTitle: () => $t('全部审批'),
        component: React.lazy(() =>
          import('./pages/admin/WorkflowList').then(m => ({
            default: m.AdminWorkflowList,
          })),
        ),
      },
      {
        path: 'settings',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('./pages/admin/Settings').then(m => ({
            default: m.AdminWorkflowSettings,
          })),
        ),
      },
    ],
  },
]

export const routes = [...userRoutes, ...adminRoutes]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'NewFlowModal',
    getTitle: () => $t('发起审批'),
    component: React.lazy(() =>
      import('./pages/user/New').then(m => ({
        default: m.NewFlowRouteModal,
      })),
    ),
  },
  {
    name: 'ShowFlowModal',
    getTitle: () => $t('查看审批单'),
    component: React.lazy(() =>
      import('./pages/user/View').then(m => ({
        default: m.ShowFlowRouteModal,
      })),
    ),
  },
  {
    name: 'EditFlowModal',
    getTitle: () => $t('编辑审批'),
    component: React.lazy(() =>
      import('./pages/user/Edit').then(m => ({
        default: m.EditFlowRouteModal,
      })),
    ),
  },
]
