/**
 * @file 群组待办
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.AdminClassAttendanceDailyNotice,
    Render: lazy(() =>
      import('./InputAttendance').then(m => ({
        default: m.InputAttendanceTodoCard,
      })),
    ),
  },
]
