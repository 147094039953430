import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const routes: RouteConfig[] = []

export const modalRoutes = [
  {
    name: 'GoalEditModal',
    getTitle: () => $t('创建目标'),
    component: lazy(() =>
      import('@/features/goals/pages/students/GoalEditModal').then(m => ({
        default: m.GoalEditModal,
      })),
    ),
  },
  {
    name: 'GoalDirectionEditModal',
    getTitle: () => $t('添加学业方向'),
    component: lazy(() =>
      import('@/features/goals/pages/students/GoalDirectionEditModal').then(
        m => ({
          default: m.GoalDirectionEditModal,
        }),
      ),
    ),
  },
]
