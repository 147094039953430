/**
 * @file 通知 routes
 */

import { lazy } from 'react'

import { RouteMenus } from '@/router'

export const routes = [
  // 管理端路由
  {
    path: 'admin/nocode/:pluginName',
    name: 'NoCode.AdminHome',
    module: ['nocode'],
    getTitle: () => 'NoCode Home',
    component: lazy(() =>
      import('./pages/Entry').then(m => ({ default: m.Entry })),
    ),
  },
  {
    path: 'admin/nocode/:pluginName/pages/:menuName',
    name: 'NoCode.AdminPage',
    module: ['nocode'],
    getTitle: () => 'AdminPage',
    component: lazy(() =>
      import('./pages/Page').then(m => ({ default: m.Page })),
    ),
  },
  {
    path: 'admin/nocode/:pluginName/pages/:menuName/roles/:roleId/edit',
    name: 'NoCode.EditRole',
    getTitle: () => 'EditRole',
    component: lazy(() =>
      import('./auth/AuthEdit').then(m => ({
        default: m.AuthEdit,
      })),
    ),
  },
  {
    path: 'admin/nocode/:pluginName/pages/:menuName/roles/new',
    name: 'NoCode.AddRole',
    getTitle: () => 'AddRole',
    component: lazy(() =>
      import('./auth/AuthEdit').then(m => ({
        default: m.AuthEdit,
      })),
    ),
  },
  {
    path: 'admin/nocode/:pluginName/views/:menuName',
    module: ['nocode'],
    getTitle: () => 'AdminTabPageEntry',
    component: lazy(() =>
      import('./pages/TabPage').then(m => ({ default: m.TabPage })),
    ),
    subRoutes: [
      {
        path: ':tabName',
        name: 'NoCode.AdminTabPage',
        getTitle: () => 'TabPage',
        component: lazy(() =>
          Promise.resolve({
            default: RouteMenus,
          }),
        ),
      },
    ],
  },
  {
    path: 'admin/nocode/:pluginName/sep-pages/:pageName',
    name: 'NoCode.AdminSeperatedPage',
    module: ['nocode'],
    getTitle: () => 'AdminSeperatedPage',
    component: lazy(() =>
      import('./pages/SeperatedPage').then(m => ({ default: m.SeperatedPage })),
    ),
  },

  // 用户端路由
  {
    path: 'nocode/:pluginName',
    name: 'NoCode.AppHome',
    module: ['nocode'],
    getTitle: () => 'NoCode Home',
    component: lazy(() =>
      import('./pages/Entry').then(m => ({ default: m.Entry })),
    ),
  },
  {
    path: 'nocode/:pluginName/pages/:menuName',
    name: 'NoCode.AppPage',
    module: ['nocode'],
    getTitle: () => 'NoCode',
    component: lazy(() =>
      import('./pages/Page').then(m => ({ default: m.Page })),
    ),
  },
  {
    path: 'nocode/:pluginName/views/:menuName',
    module: ['nocode'],
    getTitle: () => 'AppTabPageEntry',
    component: lazy(() =>
      import('./pages/TabPage').then(m => ({ default: m.TabPage })),
    ),
    subRoutes: [
      {
        path: ':tabName',
        name: 'NoCode.AppTabPage',
        getTitle: () => 'AppTabPage',
        component: lazy(() =>
          Promise.resolve({
            default: RouteMenus,
          }),
        ),
      },
    ],
  },
  {
    path: 'nocode/:pluginName/sep-pages/:pageName',
    name: 'NoCode.SeperatedPage',
    module: ['nocode'],
    getTitle: () => 'SeperatedPage',
    component: lazy(() =>
      import('./pages/SeperatedPage').then(m => ({ default: m.SeperatedPage })),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'NoCode.AdminModalPage',
    getTitle: () => 'AdminModalPage',
    component: lazy(() =>
      import('./pages/FullModalPage').then(m => ({ default: m.FullModalPage })),
    ),
  },
  {
    name: 'NoCode.AppModalPage',
    getTitle: () => 'AppModalPage',
    component: lazy(() =>
      import('./pages/FullModalPage').then(m => ({ default: m.FullModalPage })),
    ),
  },
]
