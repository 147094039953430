/**
 * @file 用户 - 校外人员 - 路由
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const staffRoutes: RouteConfig[] = [
  {
    path: 'admin/users/staffs/fields',
    getTitle: () => $t('字段管理'),
    component: lazy(() =>
      import('../pages/staffs/FieldManagement').then(m => ({
        default: m.FieldManagement,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
  {
    path: 'admin/users/staffs/new',
    getTitle: () => $t('新增校外人员'),
    component: lazy(() =>
      import('../pages/staffs/New').then(m => ({
        default: m.StaffNew,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
  {
    path: 'admin/users/staffs/batch-edit',
    getTitle: () => $t('批量编辑校外人员'),
    component: lazy(() =>
      import('../pages/staffs/BatchEdit').then(m => ({
        default: m.StaffBatchEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
  {
    path: 'admin/users/staffs/:staffId/view',
    getTitle: () => $t('校外人员信息'),
    component: lazy(() =>
      import('../pages/staffs/View').then(m => ({
        default: m.StaffView,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserRead],
  },
  {
    path: 'admin/users/staffs/:staffId/edit',
    getTitle: () => $t('编辑校外人员'),
    component: lazy(() =>
      import('../pages/staffs/Edit').then(m => ({
        default: m.StaffEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
]
