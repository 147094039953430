/**
 * @file 快捷键相关
 */

import { userAgent } from '@seiue/ua'
import { upperFirst, useKeyPress as useKeyPressOrigin } from '@seiue/util'

/**
 * 根据不同的系统环境，格式化快捷键
 * 现在主要在在 Win/MacOS 区分 ctrl/command
 *
 * @param shortcut 以 . 分割快捷键
 */
export const formatShortcut = (originShortcut: string) => {
  const isWindows = userAgent.os.name === 'Windows'

  const shortcuts = originShortcut.split('.')

  return shortcuts
    .map(cut => {
      if (cut === 'meta' && isWindows) {
        return 'ctrl'
      }

      return cut
    })
    .join('.')
}

/**
 * 获取快捷键对应的文案，以 . 分割快捷键
 * @param shortcut
 */
export const getShortcutLabel = (shortcut: string) => {
  const shortcuts = shortcut.split('.')

  return shortcuts
    .map(cut => {
      switch (cut) {
        case 'meta':
          return '⌘'
        case 'enter':
          return '↵'
        case 'arrowup|arrowdown':
          return '⇅'
        case 'arrowleft|arrowright':
          return '⇆'
        default:
          return upperFirst(cut)
      }
    })
    .join(' + ')
}

export const useKeyPress = (keyFilter: string, eventHandler: () => void) => {
  const formatKey = formatShortcut(keyFilter)

  return useKeyPressOrigin(formatKey, eventHandler)
}
