import { InitFeatureParams } from '@/features/types'

import { adminClassAttendanceMenu } from './menus'
import { adminClassAttendancesRoutes, attendanceModalRoutes } from './routes'
import { todos } from './todos'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initAttendances = ({
  addRoutes,
  addMenuProvider,
  registerTodos,
}: InitFeatureParams) => {
  addRoutes(adminClassAttendancesRoutes, attendanceModalRoutes)

  addMenuProvider(adminClassAttendanceMenu)
  registerTodos(todos)
}
