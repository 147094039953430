import { RouteConfig } from 'packages/route'

import { getAdminRoutes } from './admin'
import { userModalRoutes } from './user'

/**
 * 获取成绩管理的页面路由
 *
 * @returns RouteConfig[]
 */
export const getRoutes = (): RouteConfig[] => getAdminRoutes()
export const modalRoutes = [...userModalRoutes]
