import { InitFeatureParams } from '@/features/types'

import { adminExamMenu } from './menus'
import { getRoutes, modalRoutes } from './routes'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initExams = ({
  addRoutes,
  addMenuProvider,
}: InitFeatureParams) => {
  addRoutes(getRoutes(), modalRoutes)
  addMenuProvider(adminExamMenu)
}
