/**
 * @file 教师审核代办
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos'

export const gzAcademicCreditTodos: TodoExecution[] = [
  {
    type: TodoTypeEnum.GzAcademicCreditSubmission,
    Render: lazy(() =>
      import('./SubmitTodo').then(m => ({
        default: m.SubmitTodo,
      })),
    ),
  },
  {
    type: TodoTypeEnum.GzAcademicCreditVerification,
    Render: lazy(() =>
      import('./ReviewTodo').then(m => ({
        default: m.ReviewTodo,
      })),
    ),
  },
  {
    type: TodoTypeEnum.GzAcademicCreditAppeal,
    Render: lazy(() =>
      import('./AppealTodo').then(m => ({
        default: m.AppealTodo,
      })),
    ),
  },
]
