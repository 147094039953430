/**
 * @file 全屏搜索全屏弹窗组件
 */

import { FC, Suspense, lazy, useEffect, useState } from 'react'

import { useSelector } from '@/store'

const GlobalSearchModal = lazy(() =>
  import('./Modal').then(m => {
    return {
      default: m.GlobalSearchModal,
    }
  }),
)

/**
 * 全屏搜索全屏弹窗组件
 *
 * @returns react element
 */
export const GlobalSearch: FC = () => {
  const display = useSelector(({ globalSearch }) => globalSearch.display)

  const [canRender, setCanRender] = useState(false)

  useEffect(() => {
    setCanRender(v => v || display)
  }, [display])

  if (!canRender) return null

  return (
    <Suspense fallback={null}>
      <GlobalSearchModal />
    </Suspense>
  )
}
