/**
 * @file teacher profiles routes
 */
import { flatten } from '@seiue/util'
import { lazy } from 'react'

import { TeacherProfileSubRoute } from 'packages/feature-utils/profiles'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { RoleEnum } from 'packages/sdks-next/chalk'

import { getRouteSlots } from '@/plugins'

const getProfileSubRoutes = (): RouteConfig[] => [
  {
    path: 'recent',
    name: TeacherProfileSubRoute.Recent,
    getTitle: () => $t('近况'),
    component: lazy(() =>
      import('@/features/profiles/components/LevelRouteTabs').then(m => ({
        default: m.Level3RouteTabs,
      })),
    ),
    subRoutes: [
      {
        path: 'schedules',
        getTitle: () => $t('日程'),
        component: lazy(() =>
          import('@/features/teacher-profiles/pages/Recent/Schedules').then(
            m => ({
              default: m.RecentSchedules,
            }),
          ),
        ),
      },
      ...flatten(getRouteSlots('teacherProfileRecentSubRoutes')),
    ],
  },
  {
    path: 'customized',
    name: TeacherProfileSubRoute.Customized,
    getTitle: () => $t('群组'),
    component: lazy(() =>
      import('@/features/teacher-profiles/pages/customized').then(m => ({
        default: m.TeacherProfileCustomized,
      })),
    ),
    subRoutes: [
      {
        path: 'class',
        getTitle: () => $t('课程班'),
        component: lazy(() =>
          import('@/features/teacher-profiles/pages/customized/Class').then(
            m => ({
              default: m.TeacherProfileClass,
            }),
          ),
        ),
      },
      {
        path: 'adminClass',
        getTitle: () => $t('行政班'),
        component: lazy(() =>
          import(
            '@/features/teacher-profiles/pages/customized/AdminClass'
          ).then(m => ({
            default: m.TeacherProfileAdminClass,
          })),
        ),
      },
    ],
  },
  {
    path: 'exams',
    getTitle: () => $t('考试'),
    name: TeacherProfileSubRoute.Exams,
    component: lazy(() =>
      import('./pages/ProfileExamList').then(m => ({
        default: m.ProfileExamList,
      })),
    ),
  },
  {
    path: 'evaluation',
    name: TeacherProfileSubRoute.Evaluation,
    getTitle: () => $t('评价'),
    component: lazy(() =>
      import('@/features/teacher-profiles/pages/evaluations').then(m => ({
        default: m.TeacherProfileEvaluation,
      })),
    ),
    subRoutes: [
      {
        path: 'teaching',
        getTitle: () => $t('评教'),
        component: lazy(() =>
          import(
            '@/features/teacher-profiles/pages/evaluations/TeachingEvaluation'
          ).then(m => ({
            default: m.TeachingEvaluation,
          })),
        ),
      },
      ...flatten(getRouteSlots('teacherProfilesEvaluationSubRoutes')),
    ],
  },
  ...flatten(getRouteSlots('teacherProfileSubRoutes')),
]

/**
 * 获取教师档案路由
 *
 * @returns 路由
 */
export const getRoutes: () => RouteConfig[] = () => [
  {
    path: 'admin/teacher-profiles/:rid',
    requireRoles: [RoleEnum.Teacher, RoleEnum.Shadow],
    getTitle: () => $t('教师档案'),
    name: 'AdminTeacherProfiles',
    component: lazy(() =>
      import('./pages/AdminEntry').then(m => ({ default: m.Profile })),
    ),
    subRoutes: getProfileSubRoutes(),
  },
  {
    path: 'admin/teacher-profiles/:rid/exams/:examId/analysis/:subjectId',
    name: 'AdminTeacherProfileExamsAnalysis',
    getTitle: () => $t('科目详情'),
    component: lazy(() =>
      import('./pages/SubjectAnalysis').then(m => ({
        default: m.SubjectAnalysis,
      })),
    ),
  },
  {
    path: 'teacher-profiles/me',
    name: 'TeacherProfiles',
    requireRoles: [RoleEnum.Teacher],
    getTitle: () => $t('我的档案'),
    component: lazy(() =>
      import('./pages/Entry').then(m => ({ default: m.Profile })),
    ),
    subRoutes: getProfileSubRoutes(),
  },
  {
    path: 'teacher-profiles/me/exams/:examId/analysis/:subjectId',
    name: 'TeacherProfileExamsAnalysis',
    getTitle: () => $t('科目详情'),
    component: lazy(() =>
      import('./pages/SubjectAnalysis').then(m => ({
        default: m.SubjectAnalysis,
      })),
    ),
  },
  ...flatten(getRouteSlots('teacherProfilesRoutes')),
]

export const modalRoutes = []
