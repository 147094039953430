import { InitFeatureParams } from '../types'

import { calendarEvents } from './calendar-events'
import { adminClassMenuProvider } from './menus'
import { getRoutes, modalRoutes } from './routes'
import { workflowRenders } from './workflow-renders'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initClasses = ({
  addRoutes,
  addMenuProvider,
  registerCalendarEvents,
  registerWorkflowRenders,
}: InitFeatureParams) => {
  addRoutes(getRoutes(), modalRoutes)
  addMenuProvider(adminClassMenuProvider)
  registerCalendarEvents(calendarEvents)
  registerWorkflowRenders(workflowRenders)
}
