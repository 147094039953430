/**
 * @file 作业调查问卷待办
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos'

export const szHomeworkSurveyTodos: TodoExecution[] = [
  {
    type: TodoTypeEnum.SzHomeworkSurveySubmission,
    Render: lazy(() =>
      import('./WriteQuestionnaire').then(m => ({
        default: m.HomeworkSurveySubmissionTodo,
      })),
    ),
  },
]
