import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const routes: RouteConfig[] = []

export const modalRoutes = [
  {
    name: 'SingleFeedbackModal',
    getTitle: () => $t('反馈详情'),
    component: lazy(() =>
      import('./pages/SingleFeedbackModal').then(m => ({
        default: m.SingleFeedbackModal,
      })),
    ),
  },
  {
    name: 'FeedbackEditHistoryModal',
    getTitle: () => $t('编辑记录'),
    component: lazy(() =>
      import('./pages/FeedbackEditHistoryModal').then(m => ({
        default: m.FeedbackEditHistoryModal,
      })),
    ),
  },
]
