import { lazy } from 'react'

import { $t } from 'packages/locale'

export * from './classes'

export const attendanceModalRoutes = [
  {
    name: 'AttendancesSubmitModal',
    getTitle: () => $t('录入考勤'),
    component: lazy(() =>
      import('../pages/List/teacher/AttendancesSubmitModal').then(m => ({
        default: m.AttendancesSubmitModal,
      })),
    ),
  },
  {
    name: 'AttendMessageModal',
    getTitle: () => $t('通知详情'),
    component: lazy(() =>
      import('../components/AttendanceMessageModal').then(m => ({
        default: m.AttendanceMessageModal,
      })),
    ),
  },
]
