import { InitFeatureParams } from '@/features/types'

import { routes, modalRoutes } from './routes'
import { todos } from './todos'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initNotice = ({ addRoutes, registerTodos }: InitFeatureParams) => {
  addRoutes(routes, modalRoutes)
  registerTodos(todos)
}
