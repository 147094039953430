/**
 * 审批中心，汇集了所有审批流业务，包括像标准审批流、请假、出入校等
 * ⚠️ 接入审批服务前请阅读 README
 */

import { InitFeatureParams } from '@/features/types'
import { registerToSlot } from '@/plugins'

import { adminWorkflowMenu, userWorkflowMenu } from './menus'
import { routes, modalRoutes } from './routes'
import { todos } from './todos'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initWorkflows = ({
  addRoutes,
  addMenuProvider,
  registerTodos,
}: InitFeatureParams) => {
  addRoutes(routes, modalRoutes)
  addMenuProvider(adminWorkflowMenu)
  addMenuProvider(userWorkflowMenu)
  registerTodos(todos)
  registerToSlot('userCommonApplicationsEntry', () =>
    import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
  )
}
