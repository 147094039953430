import { InitFeatureParams } from '@/features/types'

import { adminGradeMenu } from './menus'
import { getRoutes, modalRoutes } from './routes'
import { todos } from './todos'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initGrades = ({
  addRoutes,
  registerTodos,
  addMenuProvider,
}: InitFeatureParams) => {
  const routes = getRoutes()
  registerTodos(todos)
  addMenuProvider(adminGradeMenu)
  addRoutes(routes, modalRoutes)
}
