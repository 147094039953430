/**
 * @file 注册审批待办
 */

import React from 'react'

import { WorkflowBizType } from 'packages/features/workflows/types'

import { ChalkRegisterWorkflowRenderType } from '@/features/workflows/register'

export const workflowRenders: ChalkRegisterWorkflowRenderType[] = [
  {
    bizType: 'no-code' as WorkflowBizType,
    render: {
      TodoCard: React.lazy(() =>
        import('./FlowTodoCardForNoCode').then(m => ({
          default: m.FlowTodoCardForNoCode,
        })),
      ),
    },
  },
]
