import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const routes: RouteConfig[] = [
  {
    path: 'messages',
    getTitle: () => $t('系统消息列表'),
    name: 'Messages',
    component: lazy(() =>
      import('./pages').then(m => ({ default: m.Messages })),
    ),
    subRoutes: [
      {
        path: 'unread',
        name: 'Unread',
        getTitle: () => $t('未读消息'),
        component: lazy(() =>
          import('./pages').then(m => ({ default: m.UnreadMessages })),
        ),
      },
      {
        path: 'readed',
        name: 'Readed',
        getTitle: () => $t('已读消息'),
        component: lazy(() =>
          import('./pages').then(m => ({ default: m.ReadedMessages })),
        ),
      },
    ],
  },
]

export const modalRoutes = [
  {
    name: 'MessagesShowModal',
    getTitle: () => $t('通知详情'),
    component: lazy(() =>
      import('./pages/MessageModal').then(m => ({ default: m.MessageModal })),
    ),
  },
]
