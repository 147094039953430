/**
 * @file 日程注册待办入口
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos'

const SchedulesTodo = lazy(() =>
  import('./SchedulesTodo').then(m => ({ default: m.SchedulesTodo })),
)

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.ScheduleInvited,
    Render: SchedulesTodo,
  },
  {
    type: TodoTypeEnum.ScheduleReminded,
    Render: SchedulesTodo,
  },
]
