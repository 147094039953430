import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const routes: RouteConfig[] = [
  {
    path: 'share/:schoolId/demo',
    name: 'ShareDemo',
    getTitle: () => $t('分享页面示例'),
    component: lazy(() =>
      import('./pages/user/Demo').then(m => ({
        default: m.Demo,
      })),
    ),
  },
  {
    path: 'share/:schoolId/events/:pid/info/:eid',
    name: 'ShareEventInfo',
    getTitle: () => $t('活动分享页'),
    component: lazy(() =>
      import('./pages/user/Events/EventInfo').then(m => ({
        default: m.ShareEventInfo,
      })),
    ),
  },
  {
    path: 'share/:schoolId/event-invited/:code',
    name: 'EventInvitation',
    getTitle: () => $t('活动邀请'),
    component: lazy(() =>
      import('./pages/user/Events/EventInvitation').then(m => ({
        default: m.EventInvitation,
      })),
    ),
  },
  {
    // 问卷分享 For 公开问卷
    path: 'share/questionnaire/:qid',
    name: 'PublicQuestionnaireShare',
    getTitle: () => $t('问卷填写'),
    component: lazy(() =>
      import('./pages/user/Questionnaire').then(m => ({
        default: m.PublicQuestionnaireShare,
      })),
    ),
  },
  {
    // 问卷分享 For 系统问卷
    path: 'share/ra/:schoolId/questionnaire/:qid',
    name: 'SystemQuestionnaireShare',
    getTitle: () => $t('问卷填写'),
    component: lazy(() =>
      import('./pages/user/Questionnaire').then(m => ({
        default: m.SystemQuestionnaireShare,
      })),
    ),
  },
  {
    path: 'share/ra/:schoolId/ntfs/:notificationId',
    name: 'ShareNotification',
    getTitle: () => $t('通知详情'),
    component: lazy(() =>
      import('./pages/user/Notification').then(m => ({
        default: m.Notification,
      })),
    ),
  },
  {
    // 无需登录的匿名评教分享
    path: 'share/:schoolId/evaluation/:evaluationId',
    name: 'AnonymousEvaluationShare',
    getTitle: () => $t('评教填写'),
    shareConfig: {
      anonymous: true,
    },
    component: lazy(() =>
      import('./pages/user/Evaluation').then(m => ({
        default: m.AnonymousEvaluationShare,
      })),
    ),
  },
]

export const netDiskShareRoutes: RouteConfig[] = [
  {
    path: 'share/:schoolId/net-disk/:shareId',
    name: 'NetDiskShare',
    getTitle: () => $t('分享详情'),
    component: lazy(() =>
      import('@/features/shares/pages/user/Netdisk').then(m => ({
        default: m.NetDiskShareFileContent,
      })),
    ),
  },
  {
    path: 'share/net-disk/navigator/:schoolId/:shareId',
    name: 'NetDiskShareNavigator',
    getTitle: () => $t('分享详情'),
    component: lazy(() =>
      import('./pages/user/Netdisk/Navigator').then(m => ({
        default: m.NetdiskShareNavigator,
      })),
    ),
  },
]
