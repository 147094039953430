import { useCallback } from 'react'

import {
  Message,
  receivedMessageApi$updateById,
  receivedMessageApi$findAll,
} from 'packages/sdks-next/chalk'

/**
 * 更改消息状态为已读
 *
 * @returns (message: Message) => AxiosResponsePromise<null>
 */
export const useReadMessage = () => {
  const { api } = receivedMessageApi$updateById.useApi({
    reload: [receivedMessageApi$findAll.api],
  })

  return useCallback(
    (message: Message) => {
      if (!message.readed) {
        return api({
          id: message.id,
          payload: {
            readed: true,
          },
        })
      }

      return Promise.resolve()
    },
    [api],
  )
}
