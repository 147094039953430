/**
 * @file 用户 - 教师 - 路由
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const teacherRoutes: RouteConfig[] = [
  {
    path: 'admin/users/teachers/fields',
    getTitle: () => $t('字段管理'),
    component: lazy(() =>
      import('../pages/teachers/FieldManagement').then(m => ({
        default: m.FieldManagement,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
  {
    path: 'admin/users/teachers/new',
    getTitle: () => $t('新增教师'),
    component: lazy(() =>
      import('../pages/teachers/New').then(m => ({
        default: m.TeacherNew,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
  {
    path: 'admin/users/teachers/batch-edit',
    getTitle: () => $t('批量编辑教师'),
    component: lazy(() =>
      import('../pages/teachers/BatchEdit').then(m => ({
        default: m.TeacherBatchEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
  {
    path: 'admin/users/teachers/:teacherId/view',
    getTitle: () => $t('教师信息'),
    name: 'AdminUsersForTeacherView',
    component: lazy(() =>
      import('../pages/teachers/View').then(m => ({
        default: m.TeacherView,
      })),
    ),
    requirePermissions: [
      PermissionNameEnum.CoreUserRead,
      PermissionNameEnum.CoreTeacherArchiveRead,
    ],
  },
  {
    path: 'admin/users/teachers/:teacherId/edit',
    getTitle: () => $t('编辑教师'),
    name: 'AdminTeacherEdit',
    component: lazy(() =>
      import('../pages/teachers/Edit').then(m => ({
        default: m.TeacherEdit,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreUserWrite],
  },
]
