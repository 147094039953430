import { InitFeatureParams } from '@/features/types'

import { pluginAppRootMenu, adminPluginMenu } from './menus'
import { routes, modalRoutes } from './routes'

// eslint-disable-next-line jsdoc/require-jsdoc
export const initPlugins = ({
  addRoutes,
  addMenuProvider,
}: InitFeatureParams) => {
  addRoutes(routes, modalRoutes)
  addMenuProvider(pluginAppRootMenu)
  addMenuProvider(adminPluginMenu)
}
